import { Button, Table, Tag, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IFilter, BatchProcessFileService } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { DateTH } from "../../../utils/time-local";

const appConfig = loadAppConfig();
interface Props {
  fillterData: IFilter;
  userId: string;
  reloadTable: number;
  loading: boolean;
  handleLoadingTable: (load: boolean) => void;
}

export const InprogressStatusProcessFile: React.FC<Props> = ({ fillterData, loading, handleLoadingTable }): React.ReactElement => {
  const { id24Axios } = useId24();
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);
  const { confirm } = Modal;
  const [getdataAll, setdataAll] = useState<any>([]);
  const [getdataJrId, setdataJrId] = useState<any>([]);
  const [getdataProcessId, setdataJrProcessId] = useState<any>([]);
  const [getdataJrStatus, setdataJrStatus] = useState<any>([]);
  const [getdataJrCreateAt, setdataJrCreateAt] = useState<any>([]);
  const [getdataUpdateAt, setdataUpdateAt] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const resJobAll = await BatchProcessFileService(apiBaseUrlDatalink).getAllRunjob();
    // const data = (await res1.map((x: any, row: any, index: number) => {
    //   const mapData = {
    //     jrId: String(x.jrId),
    //     jrProcessId: x.jrProcessId,
    //     jrStatus: x.jrStatus,
    //     jupdateAt: x.jupdateAt,
    //     jrMessage: x.jrMessage,
    //     jrCreateAt: x.jrCreateAt,
    //   } as any;
    //   return mapData;
    // })) as any;
    setdataAll(resJobAll);

    let getdataJrIds: any = [];
    let getdataJrId = resJobAll.filter((ele: any, ind: any) => ind === resJobAll.findIndex((elem: any) => elem.jrId === ele.jrId));
    getdataJrId.map((data: any) => {
      getdataJrIds.push({
        text: data.jrId,
        value: data.jrId,
      });
    });
    setdataJrId(getdataJrIds);

    let getdataProcessIds: any = [];
    let getdataProcessId = resJobAll.filter((ele: any, ind: any) => ind === resJobAll.findIndex((elem: any) => elem.jrProcessId === ele.jrProcessId));
    getdataProcessId.map((data: any) => {
      getdataProcessIds.push({
        text: data.jrProcessId,
        value: data.jrProcessId,
      });
    });
    setdataJrProcessId(getdataProcessIds);

    let getdataJrStatuss: any = [];
    let getdataJrStatus = resJobAll.filter((ele: any, ind: any) => ind === resJobAll.findIndex((elem: any) => elem.jrStatus === ele.jrStatus));
    getdataJrStatus.map((data: any) => {
      getdataJrStatuss.push({
        text: data.jrStatus === "Inprogress" ? "In Progress" : data.jrStatus,
        value: data.jrStatus,
      });
    });
    setdataJrStatus(getdataJrStatuss);

    let getdataJrCreateAts: any = [];
    let getdataJrCreateAt = resJobAll.filter((ele: any, ind: any) => ind === resJobAll.findIndex((elem: any) => elem.jrCreateAt === ele.jrCreateAt));
    getdataJrCreateAt.map((data: any) => {
      getdataJrCreateAts.push({
        text: DateTH(data.jrCreateAt, "DD-MM-YYYY HH:mm"),
        value: data.jrCreateAt,
      });
    });
    setdataJrCreateAt(getdataJrCreateAts);

    let getdataUpdateAts: any = [];
    let getdataUpdateAt = resJobAll.filter((ele: any, ind: any) => ind === resJobAll.findIndex((elem: any) => elem.jupdateAt === ele.jupdateAt));
    getdataUpdateAt.map((data: any) => {
      getdataUpdateAts.push({
        text: DateTH(data.jupdateAt, "DD-MM-YYYY HH:mm"),
        value: data.jupdateAt,
      });
    });
    setdataUpdateAt(getdataUpdateAts);
    handleLoadingTable(false);
  }

  const handleDelete = async (e: any) => {
    if (e) {
      confirm({
        title: "คุณแน่ใจแล้วใช่ไหมที่ต้องการลบข้อมูลชุดนี้ ?",
        icon: <ExclamationCircleOutlined />,
        content: "ยืนยันการลบข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk() {
          const res: any = BatchProcessFileService(apiBaseUrlDatalink).deleteJobRun(e.jrId);
          if (res) {
            message.success("ลบข้อมูลสำเร็จ");
            fetchData();
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const Columns: any = [
    {
      key: "jrId",
      title: "Job ID",
      dataIndex: "jrId",
      sorter: (a: any, b: any) => a.jrId - b.jrId,
    },
    {
      key: "jrId",
      title: "Bath File",
      dataIndex: "jrProcessId",
      filters: getdataProcessId,
      onFilter: (value: any, record: any, key: number) => record.jrProcessId.startsWith(value),
      filterSearch: true,
    },
    {
      key: "jrStatus",
      title: "Status",
      dataIndex: "jrStatus",
      filters: getdataJrStatus,
      onFilter: (value: any, record: any, key: number) => record.jrStatus.startsWith(value),
      defaultFilteredValue: ["New", "Inprogress"],
      filterSearch: true,
      render: (e: any, row: number) => {
        if (e === "Inprogress") {
          return (
            <Tag key={"row"} color="orange">
              In Progress
            </Tag>
          );
        } else if (e === "New") {
          return (
            <Tag key={"row"} color="blue">
              New
            </Tag>
          );
        } else {
          return (
            <Tag key={"row"} color="green">
              Complete
            </Tag>
          );
        }
      },
    },
    { key: "jrId", title: "Description", dataIndex: "jrMessage" },
    {
      key: "jrCreateAt",
      title: "Created Date",
      dataIndex: "jrCreateAt",
      filters: getdataJrCreateAt,
      onFilter: (value: any, record: any, key: number) => record.jrCreateAt.startsWith(value),
      filterSearch: true,
      render: (e: any) => {
        if (e) {
          return DateTH(e, "DD:MM:YYYY HH:mm");
        }
      },
    },
    {
      key: "jupdateAt",
      title: "Updated Date",
      dataIndex: "jupdateAt",
      filters: getdataUpdateAt,
      onFilter: (value: any, record: any, key: number) => record.jupdateAt.startsWith(value),
      filterSearch: true,
      render: (e: any) => {
        if (e) {
          return DateTH(e, "DD:MM:YYYY HH:mm");
        }
      },
    },

    {
      key: "jrId",
      title: "Action",
      dataIndex: "jrId",
      render: (e: any, row: any, index: number) => {
        if (e) {
          return (
            <Button onClick={() => handleDelete(row)} type="primary" danger>
              Delete
            </Button>
          );
        }
      },
    },
  ];

  return (
    <>
      <Table size="small" loading={loading} rowKey={`psId`} dataSource={getdataAll} columns={Columns} />
    </>
  );
};
