import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Table, Modal, message, Button, Form, Space, Typography, Input, Select, InputNumber, Radio, Popconfirm, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { TeamService } from "../services/team-debt-service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { DateTH } from "../../../utils/time-local";
import { TTListUserOa, TTeamlist } from "../TeamDebtManagement";
import { IUser } from "../../oaProfile/common";
const appConfig = loadAppConfig();

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

interface AccountType {
  key: React.Key;
  UserId: number;
  name: string;
  share: number;
  userBank: number;
}
interface DataType {
  key: number;
  no: number;
  user: string;
  description: string;
  name: string;
  updateBy: string;
  updateDate: any;
}
interface Props {
  dataTeamList: TTeamlist[];
  countTeam: number;
  userProfile: IUser;
  fillterData: any;
  userId: string;
  reloadTable: number;
  accessRole: any;
  setLoading: (status: boolean) => void;
  userList: TTListUserOa[];
  loadingTable: boolean;
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

export const TableTeamManagement: React.FC<Props> = ({
  dataTeamList,
  countTeam,
  userProfile,
  fillterData,
  userId,
  reloadTable,
  accessRole,
  setLoading,
  userList,
  loadingTable,
}) => {
  const { id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataCreateBy, setDataCreateBy] = useState("");
  const [form] = Form.useForm();
  const [hidden, setHidden] = useState(true);
  const [checkOa, setCheckOa] = useState("none");
  const [checkSum, setCheckSum] = useState("none");
  const [checkStatus, setCheckStatus] = useState("none");
  const [oaOptRadio, setOaOptRadio] = useState<any[]>([
    { label: "Prefer New", value: "NEW_USER" },
    { label: "Prefer Previous", value: "PREVIOUS_USER" },
    { label: "Random", value: "RANDOM_USER" },
  ]);
  const [count, setCount] = useState<number>(0);
  const [dataCollector, setCollector] = useState<AccountType[]>([
    {
      key: 1,
      UserId: -1,
      name: "",
      share: 0,
      userBank: 0,
    },
  ]);

  useEffect(() => {}, [dataTeamList]);

  // const featchFilterData = async (filter: any) => {
  //   if (filter.text === "") return;
  //   if (filter.text !== "") {
  //     if (filter.type === "TeamName") {
  //       setDataTeamList(dataTeamList.filter((item: TTeamlist) => item.name.match(fillterData.text)));
  //     } else if (filter.type === "TeamDescription") {
  //       setDataTeamList(dataTeamList.filter((item: TTeamlist) => item.description.match(fillterData.text)));
  //     } else if (filter.type === "UpdateBy") {
  //       setDataTeamList(dataTeamList.filter((item: TTeamlist) => item.updateBy.match(fillterData.text)));
  //     }
  //   }
  // };

  const showModal = (row: TTeamlist) => {
    let oaShow = [];
    for (let i = 1; i <= row.detail.length; i++) {
      oaShow.push({
        key: i,
        UserId: row.detail[i - 1].UserId,
        name: "",
        share: row.detail[i - 1].share,
        userBank: row.detail[i - 1].userBank,
      });
    }
    setCollector(oaShow);
    setDataCreateBy(row.createBy);
    form.setFieldValue("id", row.id);
    form.setFieldValue("name", row.name);
    form.setFieldValue("description", row.description);
    form.setFieldValue("user", row.user);
    form.setFieldValue("cifGrouping", row.cifGrouping);
    form.setFieldValue("assignBy", row.assignBy);
    form.setFieldValue("assignMode", row.assignMode);
    form.setFieldValue("detail", oaShow);
    setIsModalVisible(true);
  };

  const columns: any = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (value: number, row: TTeamlist, index: number) => {
        return index + 1;
      },
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Team Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Update By",
      dataIndex: "updateBy",
      key: "updateBy",
    },
    {
      title: "Update Date",
      dataIndex: "updateDate",
      key: "updateDate",
      render: (e: string) => DateTH(new Date(e), "DD-MM-BBBB HH:mm"),
      sorter: (a: any, b: any) => a.updateDate.localeCompare(b.updateDate),
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      defaultSortOrder: "ascend" as const,
      render: (e: string) => DateTH(new Date(e), "DD-MM-BBBB HH:mm"),
      sorter: (a: any, b: any) => a.createDate.localeCompare(b.createDate),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (e: string, record: TTeamlist) => {
        if (checkAccessRole("ddebtTeamManagementEditteam")) {
          return <a onClick={() => showModal(record)}>Edit</a>;
        } else {
          return <></>;
        }
      },
    },
  ];

  const onFinish = (values: any) => {
    let validate = true;
    dataCollector.map((opt) => {
      if (!opt.UserId || opt.UserId === undefined || !opt.share || opt.UserId === -1) {
        validate = false;
        return;
      } else {
        setCheckOa("none");
        setHidden(true);
      }
    });
    if (validate) {
      setCheckOa("none");
      let userStatus = "true";
      dataCollector.forEach((data1) => {
        dataCollector.forEach((data2) => {
          if (data1.UserId === data2.UserId && data1.key != data2.key) {
            userStatus = "false";
          }
        });
      });
      if (userStatus === "false") {
        setCheckStatus("block");
        setCheckSum("none");
        setHidden(false);
      } else {
        setCheckStatus("none");

        let newValue;
        if (values.description === undefined || values.description === "") {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: "",
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
        } else {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: values.description,
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
        }

        const result = dataCollector.reduce((sum, number) => {
          return sum + number.share;
        }, 0);
        if (result === 100) {
          let detail: any[] = [];
          dataCollector.map((row: any) => {
            detail.push({
              UserId: Number(row.UserId) as number,
              share: row.share as number,
              userBank: Number(row.userBank) as number,
            });
          });
          const teamsCreate = {
            ...newValue,
            detail: detail,
            user: String(userProfile.oaCode),
            updateBy: userProfile.name,
            createBy: String(dataCreateBy),
          };
          TeamService(apiBaseUrl)
            .updateTeamById(teamsCreate)
            .then((result) => {
              form.resetFields();
              message.success("This is a success message");
              setCollector([
                {
                  key: 1,
                  UserId: -1,
                  name: "",
                  share: 0,
                  userBank: 0,
                },
              ]);
              setIsModalVisible(false);
              setCheckOa("none");
              setCheckStatus("none");
              setCheckSum("none");
              setLoading(true);
            })
            .catch((error) => {
              message.error(error);
            });
        } else {
          setCheckSum("block");
          setHidden(false);
        }
      }
    } else {
      setCheckOa("block");
      setCheckStatus("none");
      setCheckSum("none");
      setHidden(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const handleCancel = () => {
    setCheckOa("none");
    setCheckStatus("none");
    setCheckSum("none");
    setIsModalVisible(false);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      key: uuidv4(),
      title: "Collector",
      dataIndex: "UserId",
      width: "50%",
      render: (data: string, row: any, index: number) => {
        return (
          <Select
            allowClear
            placeholder="Please Select"
            size="small"
            style={{ width: "100%" }}
            defaultValue={data}
            key={uuidv4()}
            showSearch
            optionFilterProp="children"
            filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
            onChange={(e: any, option: any) => {
              if (e) {
                selectChange(option, row, index);
              }
            }}
          >
            <Option key={uuidv4()} value={-1} disabled>
              <div style={{ color: "grey" }}> Plese Select</div>
            </Option>
            {userList?.map((opt: TTListUserOa, index: number) => (
              <Option key={uuidv4()} value={opt.id}>
                {opt.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "%",
      dataIndex: "share",
      key: uuidv4(),
      render: (data: number, row: any, index: number) => {
        return (
          <InputNumber
            onChange={(e: any) => {
              accountChange(e, row, index);
            }}
            size="small"
            style={{ width: 80 }}
            min={0}
            max={100}
            placeholder="0-100"
            value={data}
          />
        );
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (_, record: any) => {
        return (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const handleAddModal = () => {
    const newData: AccountType = {
      key: dataCollector.length + 1,
      UserId: -1,
      name: "",
      share: 0,
      userBank: 0,
    };
    setCollector([...dataCollector, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: AccountType) => {
    const newData = [...dataCollector];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setCollector(newData);
  };

  const columnsModal = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AccountType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleDelete = (key: React.Key) => {
    if (dataCollector.length > 1) {
      const newData = dataCollector.filter((item) => item.key !== key);
      setCollector(newData);
    }
  };

  const accountChange = (share: any, row: any, index: number) => {
    let newArr = [...dataCollector];
    newArr[index].share = share;
    setTimeout(() => {
      setCollector(newArr);
    }, 1000);
  };

  const selectChange = (oa: any, row: any, index: number) => {
    let UserId = oa.value;
    let name = oa.children;
    let newArr = [...dataCollector];
    newArr[index].UserId = UserId;
    newArr[index].name = name;
    setCollector(newArr);
  };

  const bankChange = (e: any, row: any, index: number) => {
    let userBank = e.value;
    let newArr = [...dataCollector];
    newArr[index].userBank = userBank;
    setCollector(newArr);
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Table
        loading={loadingTable}
        columns={columns}
        dataSource={dataTeamList}
        style={{ marginTop: 30, width: "100%" }}
        rowKey={(record: any) => record.id}
        pagination={{
          defaultPageSize: 10,
          total: countTeam,
          showSizeChanger: true,
        }}
      />

      <Modal
        width={1200}
        title="Edit Team"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item hidden={true} label="id" name="id">
            <Input hidden={true} id="id" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input id="name" placeholder="Team 1" style={{ width: "90%" }} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea rows={4} placeholder="Text" style={{ width: "90%" }} />
          </Form.Item>

          <Form.Item label="Choose Collector" name="detail">
            <Table
              size="small"
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataCollector}
              columns={columnsModal as ColumnTypes}
              pagination={false}
              style={{ width: "90%" }}
              rowKey={(record: any) => {
                return record.key;
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 7 }} hidden={hidden}>
            <Text type="danger" style={{ display: checkOa }}>
              Please fill out the information completely.
            </Text>
            <Text type="danger" style={{ display: checkSum }}>
              Not 100 %.
            </Text>
            <Text type="danger" style={{ display: checkStatus }}>
              Collector ซ้ำกัน
            </Text>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 11,
            }}
          >
            <Button onClick={handleAddModal} type="primary">
              Add
            </Button>
          </Form.Item>
          <Form.Item
            label="CIF Grouping"
            name="cifGrouping"
            rules={[
              {
                required: true,
                message: "Please input CIF Grouping!",
              },
            ]}
          >
            <Radio.Group name="cifGroupingRadio">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Assign By"
            name="assignBy"
            rules={[
              {
                required: true,
                message: "Please input Assign By!",
              },
            ]}
          >
            <Radio.Group name="assignByRadio">
              <Radio value={"OS_BAL"}>Outstanding Balance</Radio>
              <Radio value={"ACCOUNT"}>Account</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="OA"
            name="assignMode"
            rules={[
              {
                required: true,
                message: "Please input OA!",
              },
            ]}
          >
            <Radio.Group name="assignModeRadio" options={oaOptRadio} />
          </Form.Item>
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
              Save
            </Button>
            <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
