import { Button, Card, Col, Row, Table, Modal, Input, Space, Form, message, Typography, Spin, TablePaginationConfig } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import type { ColumnsType } from "antd/es/table";
import { useHistory } from "react-router-dom";
import { EmailTemplateServiece } from "../serviece/EmailTemplateServiece";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { DateTH } from "../../../utils/time-local";
const appConfig = loadAppConfig();

interface DataType {
  mtId: React.Key;
  no: number;
  mtName: string;
  createAt: string;
  updateAt: string;
  mailTo: string;
  bodyContent: string;
  subject: string;
}
interface Props {
  baseUrl: string;
}
interface TPagination {
  current: number;
  pageSize: number;
}

const OverviewEmailTemplate: React.FC<Props> = ({ baseUrl }): React.ReactElement => {
  const [getAlias, setAlias] = React.useState<any>({
    name: "unknown",
    empId: "unknown",
    email: "unknown",
  });
  const EmailTemplateServieces = EmailTemplateServiece;
  const { push } = useHistory();
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlDebtlink = id24Axios(appConfig.apiBaseUrlDatalink);
  const [formAction] = Form.useForm();
  const [editform] = Form.useForm();
  const [getName, setNameValue] = useState<string>("");
  const [getUserId, setUserIdValue] = useState<number>(0);
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });
  const [getModal, setModal] = useState(false);
  const [getmodalEdit, setModalEdit] = useState(false);
  const [getdataAll, setgetdataAll] = useState([]);
  const [getfilterName, setFilterNmae] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchDataForSearch();
  }, [loading]);

  const fetchDataForSearch = async () => {
    const userProfile = id24Axios(appConfig.apiBaseUrlDebt);
    const profile = await userProfile.get("/debtor/userDetails");
    const resSearch = await EmailTemplateServieces(apiBaseUrlDebtlink).gettemplateAllNew();
    let filterSearchName: any = [];
    let NameFilter = resSearch.filter((ele: any, ind: any) => ind === resSearch.findIndex((elem: any) => elem.mtName === ele.mtName));
    NameFilter.map((event: any) => {
      filterSearchName.push({
        text: event.mtName,
        value: event.mtName,
      });
    });
    setgetdataAll(resSearch);
    setAlias({
      name: profile.data.result.name,
      email: profile.data.result.email,
      empId: profile.data.result.id,
    });
    setFilterNmae(filterSearchName);
    setLoading(false);
  };

  const toTemplate = (path: any, id: any) => {
    let all = {
      alltem: getdataAll,
      idtem: id,
    };
    push(path, all);
  };

  const modalAdd = () => {
    setModal(true);
  };

  const handleOk = async (value: any) => {
    if (value) {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <PlusOutlined />,
        content: "ยืนยัน sequence Condition List นี้ใช่หรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const AddData = {
            data: {
              name: value.name,
              description: value.description,
              createdBy: getAlias.name,
              updatedBy: "",
            },
          };
          (await EmailTemplateServieces(apiBaseUrlDebt)
            .createtemplate(AddData)
            .then((response: any) => {
              message.success("เพิ่ม template เรียบร้อย");
            })) as any;
          setModal(false);
          setLoading(true);
        },
        onCancel: () => {},
      });
    }
  };

  const handleCancel = () => {
    setModal(false);
    formAction.resetFields();
  };

  const handleOkEdit = async (form: any) => {
    Modal.confirm({
      title: "คุณแน่ใจแล้วใช่ไหม",
      icon: <PlusOutlined />,
      content: "ยืนยัน sequence Condition List นี้ใช่หรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        let id = getUserId;
        let data = {
          mtName: form.name != undefined ? form.name : getName,
        };

        (await EmailTemplateServieces(apiBaseUrlDebtlink)
          .updatetemplateNew(data, id)
          .then(() => {
            message.success("เพิ่ม template เรียบร้อย");
          })) as any;
        setModalEdit(false);
        setLoading(true);
      },
      onCancel: () => {},
    });
  };

  const handleCancelEdit = () => {
    setModalEdit(false);
    editform.resetFields();
  };

  const onChange = (pagination: TablePaginationConfig) => {
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "No.",
      dataIndex: "no",
      width: "5%",
      fixed: "left",
      render: (event: string, row: any, index: number) => {
        let skip = (pagination.current - 1) * pagination.pageSize;
        return index + skip + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "mtName",
      width: "30%",
      filters: getfilterName,
      onFilter: (value: any, record) => {
        return String(record.mtName)?.startsWith(String(value));
      },
      filterSearch: true,
      render: (text: string, record: any, index: number) => {
        return text ? (
          <a key={record.mtId} onClick={() => toTemplate(`${baseUrl}/editemailtemplate/${record.mtId}`, record.mtId)}>
            {text}
          </a>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createAt",
      width: "20%",
      render: (event: Date, row: any, index: number) => {
        return DateTH(event, "DD-MM-BBBB HH:mm:ss");
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updateAt",
      width: "20%",
      render: (event: Date, row: any, index: number) => {
        return DateTH(event, "DD-MM-BBBB HH:mm:ss");
      },
    },
  ];

  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem", width: "100%" }}
      >
        {loading ? (
          <Spin />
        ) : (
          <Col span={24}>
            <Card
              style={{ width: "100%", textAlign: "left" }}
              title={
                <>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Template
                  </Typography>
                </>
              }
            >
              <Table
                size="small"
                rowKey={(row) => row.mtId}
                columns={columns}
                dataSource={getdataAll}
                scroll={{ x: "calc(700px + 50%)" }}
                onChange={onChange}
              />
            </Card>
          </Col>
        )}
      </Row>
      <Modal
        title="Add Template"
        open={getModal}
        onOk={modalAdd}
        onCancel={handleCancel}
        footer={[
          <Button style={{ backgroundColor: "red", color: "white" }} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button form="formfield" type="primary" htmlType="submit" onClick={modalAdd}>
            Save
          </Button>,
        ]}
      >
        <Form initialValues={{ remember: true }} form={formAction} name="formfield" id="formfield" layout="vertical" onFinish={handleOk}>
          <Form.Item label={"Name"} id="name" name={"name"} required>
            <Input placeholder="Text" name="name" />
          </Form.Item>
          <Form.Item label={"Description"} id="description" name={"description"} required>
            <TextArea placeholder="Text" maxLength={100} name="description" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="Edit Template" visible={getmodalEdit} onCancel={handleCancelEdit} footer={false}>
        <Form initialValues={{ remember: true }} form={editform} autoComplete="off" layout="vertical" name="editform" onFinish={handleOkEdit}>
          <Form.Item label="Name" name="name">
            <Input key={getUserId} placeholder="Text" name="name" value={getName} defaultValue={getName} />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Space>
              <Button style={{ backgroundColor: "red", color: "white" }} onClick={handleCancelEdit}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OverviewEmailTemplate;
