import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
// import dayjs from "dayjs";
import { Card, Form, Row, Input, Radio, Table, Select, Col, Button, InputNumber, Typography, Modal, message, TreeSelect } from "antd";
import { useAuth24, User } from "../../../../common-auth/utils/use-auth24";
import { AxiosInstance } from "axios";
import { DatamanagementService } from "../../services/datamanagement-service";
import {
  Consditions,
  DatamanageMent,
  DataType,
  Fields,
  ICenter,
  IuserProfile,
  ProductType,
  typeTeam,
  ValueInDetail,
} from "../../models/models-datamanagement";
import NumberFormat from "react-number-format";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { loadAppConfig } from "../../../../config/app-config";
import { useId24 } from "../../../../drivers/id24/Id24-provider";
const appConfig = loadAppConfig();
dayjs.extend(localizedFormat);

import moment from "moment";

import dayjs from "dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { IMasterDataOfBranch, Role } from "../../common";
import { UserAccess } from "../../../../drivers/id24/models/user-access";
import { config, datePickerTh } from "../../../../utils";
const DatePicker = generatePicker(config);

const { Option } = Select;
interface Props {
  baseUrl: string;
  accessRole: any;
}
export const EditPageDraft: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });
  const refValuePrincipalAmount = useRef<any>(null);
  const refValueConditionAmount = useRef<any>(null);
  const refValueConditionOutstanding = useRef<any>(null);
  const refValueConditionStmtAmt = useRef<any>(null);
  const refValueConditionCurBal = useRef<any>(null);
  const refBillingCycle = useRef<any>(null);
  const refValueLoanType = useRef<any>(null);
  const location: any = useLocation();
  const { push } = useHistory();
  const [getDataAll, setDataAll] = useState<Consditions>();

  const [getconditionPrincipalAmount, setconditionPrincipalAmount] = useState<string>("select");
  const [getValueConditionListName, setValueConditionListName] = useState<string>("");
  const [getValueDescription, setValueDescription] = useState<string>("");
  const [getRadiocheckManual, setRadiocheckManual] = useState<boolean>(false);
  const [getRadiocheckAuto, setRadiocheckAuto] = useState<boolean>(false);
  const [getRadioExpridedStatement, setRadioExpridedStatement] = useState<boolean>(false);
  const [getRadioExpridedAssignTime, setRadioExpridedAssignTime] = useState<boolean>(false);
  //
  const [getDetailProductLoan, setDetailProductLoan] = useState<ValueInDetail[]>([]);

  const [getDetailProductCard, setDetailProductCard] = useState<ValueInDetail[]>([]);

  const [getDetailProductOD, setDetailProductOD] = useState<ValueInDetail[]>([]);

  //Conditions setState StartPage
  const [getConditionLoanType, setConditionLoanType] = useState<string>("");
  const [getConditionLoanTypeNew, setConditionLoanTypeNew] = useState<string[]>([]);
  const [getConditionAccountStatus, setConditionAccountStatus] = useState<string>("");
  const [getConditionAccountStatusNew, setConditionAccountStatusNew] = useState<string[]>([]);

  const [getConditionProductType, setConditionProductType] = useState<string>("");
  const [getConditionProductTypeNew, setConditionProductTypeNew] = useState<string[]>([]);

  const [getConditionAccountLevel, setConditionAccountLevel] = useState<string[]>([]);
  const [getConditionAccountLevelNew, setConditionAccountLevelNew] = useState<string[]>([]);

  const [getConditionLocation, setConditionLocation] = useState<string[]>([]);
  const [getConditionLocationNew, setConditionLocationNew] = useState<string[]>([]);
  const [getConditionOutstandingdebt, setConditionOutstandingdebt] = useState<string>("select");
  const [getConditionStmtAmt, setConditionStmtAmt] = useState<string>("select");
  const [getConditionStmtAmtNew, setConditionStmtAmtNew] = useState<string[]>([]);
  const [getConditionCurBal, setConditionCurBal] = useState<string>("select");
  const [getConditionCurBalNew, setConditionCurBalNew] = useState<string[]>([]);
  const [getConditionOutstandingdebtNew, setConditionOutstandingdebtNew] = useState<string[]>([]);

  const [getmaxTagCount, setmaxTagCount] = useState<any>("responsive");

  //select setState StartPage
  const [getSelectLoanType, setSelectLoanType] = useState<string[]>([]);
  const [getSelectLoanTypeNew, setSelectLoanTypeNew] = useState<string[]>([]);
  const [getSelectAccountStatus, setSelectAccountStatus] = useState<string[]>([]);
  const [getSelectAccountStatusNew, setSelectAccountStatusNew] = useState<string[]>([]);
  const [getSelectProductType, setSelectProductType] = useState<ValueInDetail[]>([]);
  const [getSelectProductTypeNew, setSelectProductTypeNew] = useState<ValueInDetail[]>([]);

  const [getSelectAccountLevel, setSelectAccountLevel] = useState<ValueInDetail[]>([]);
  const [getSelectAccountLevelNew, setSelectAccountLevelNew] = useState<ValueInDetail[]>([]);

  const [getDataBillingCycle, setDataBillingCycle] = useState<any>([]);
  const [getDataBillingCycleNew, setDataBillingCycleNew] = useState<any>([]);

  const [getSelectLocation, setSelectLocation] = useState<any[]>([]);
  const [getSelectLocationNew, setSelectLocationNew] = useState<any[]>([]);

  const [getValueOutstandingdebt, setValueOutstandingdebt] = useState<any>();
  const [getValueStmtAmt, setValueStmtAmt] = useState<any>();
  const [getValueCurBal, setValueCurBal] = useState<any>();
  const [getValueExpireday, setValueExpireday] = useState<any>();
  const refgetValueExpireday = useRef<any>(null);
  const refgetValueConditions = useRef<any>(null);
  const refgetValueTeam = useRef<any>(null);

  const [getValueProductType, setValueProductType] = useState<ProductType[]>([]);
  const [getDataPrincipalAmount, setDataPrincipalAmount] = useState<string>("");

  const [getFristBetweenPrincipalAmount, setFristBetweenPrincipalAmount] = useState<string>("");
  const [getEndBetweenPrincipalAmount, setEndBetweenPrincipalAmount] = useState<string>("");
  const [getFristBetweenOverduleAmount, setFristBetweenOverduleAmount] = useState<string>("");
  const [getEndBetweenOverduleAmount, setEndBetweenOverduleAmount] = useState<string>("");
  const [getFristBetweenStmtAmt, setFristBetweenStmtAmt] = useState<string>("");
  const [getEndBetweenStmtAmt, setEndBetweenStmtAmt] = useState<string>("");
  const [getFristBetweenCurBal, setFristBetweenCurBal] = useState<string>("");
  const [getEndBetweenCurBal, setEndBetweenCurBal] = useState<string>("");

  const [getValueTeam, setValueTeam] = useState<string>("");
  const [getTeam, setTeam] = useState<typeTeam[]>([]);
  const [getAssignType, setAssignType] = useState<string>("");
  const [getExpridedType, setExpridedType] = useState<string>("");
  const [getDataSeq, setDataSeq] = useState<number>();
  const [getResualDatamanagement, setResualDatamanagement] = useState<DatamanageMent>();
  const [getResualDatamanagementDetail, setResualDatamanagementDetail] = useState<any>();
  const createDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const [permissionId24, setPermissionId24] = useState<any>([]);
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [CleardistNew, setCleardistNew] = useState<string | number>();
  const [getDataMasterDataCenterOfBranch, setDataMasterDataCenterOfBranch] = useState<IMasterDataOfBranch[]>([]);

  function multipleInArray(arr: string[], values: Role[]) {
    return values.some((value) => {
      return arr.includes(value);
    });
  }

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };

  useEffect(() => {
    try {
      mapRoleAccess().then(async (resultmap) => {
        setPermissionId24(resultmap);

        const userAssign = () => {
          let AssignUserNew;
          multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
            ? (AssignUserNew = "AssignUser")
            : (AssignUserNew = `User:${userProfile.id}:AssignUser`);
          setAssignUserNew(AssignUserNew);
          return AssignUserNew;
        };
        const userCleardist = () => {
          let AssignUserNew;
          multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
            ? (AssignUserNew = 0)
            : (AssignUserNew = `User:${userProfile.id}:AssignUser`);
          setCleardistNew(AssignUserNew);
          return AssignUserNew;
        };
        userCleardist();
        userAssign();
      });
    } catch {}
    getCenter().then((e: any) => {
      getDataAll(e);
    });
    async function getDataAll(dataLocation: any) {
      try {
        getUserProfile().then((data) => {
          if (data) {
            fetchDataManagementByid();
          }
        });
        const getData = await location.state;
        setDataAll(getData);
        {
          getData?.assignType === "Manual" ? setRadiocheckManual(true) : setRadiocheckAuto(true);
        }
        {
          getData?.expridedType === "StatementDate" ? setRadioExpridedStatement(true) : setRadioExpridedAssignTime(false);
        }
        setDataSeq(getData.seq);

        setValueConditionListName(getData?.listname);
        setValueDescription(getData?.description);

        setConditionOutstandingdebt(getData?.fields?.overdueAmt?.op ? getData?.fields?.overdueAmt?.op : "select");

        setconditionPrincipalAmount(getData?.fields?.principleAmt?.op ? getData?.fields?.principleAmt?.op : "select");

        setConditionStmtAmt(getData?.fields?.stmtAmt?.op ? getData?.fields?.stmtAmt?.op : "select");

        setConditionCurBal(getData?.fields?.curBal?.op ? getData?.fields?.curBal?.op : "select");

        if (getData?.fields?.principleAmt?.op === "between") {
          setFristBetweenPrincipalAmount(getData?.fields?.principleAmt?.value === undefined ? "" : getData?.fields?.principleAmt?.value[0]);
          setEndBetweenPrincipalAmount(getData?.fields?.principleAmt?.value === undefined ? "" : getData?.fields?.principleAmt?.value[1]);
        } else {
          setDataPrincipalAmount(getData?.fields?.principleAmt?.value === undefined ? "" : getData?.fields?.principleAmt?.value);
        }

        if (getData?.fields?.overdueAmt?.op === "between") {
          setFristBetweenOverduleAmount(getData?.fields?.overdueAmt?.value === undefined ? "" : getData?.fields?.overdueAmt?.value[0]);
          setEndBetweenOverduleAmount(getData?.fields?.overdueAmt?.value === undefined ? "" : getData?.fields?.overdueAmt?.value[1]);
        } else {
          setValueOutstandingdebt(getData?.fields?.overdueAmt?.value === undefined ? "" : getData?.fields?.overdueAmt?.value);
        }

        if (getData?.fields?.stmtAmt?.op === "between") {
          setFristBetweenStmtAmt(getData?.fields?.stmtAmt?.value === undefined ? "" : getData?.fields?.stmtAmt?.value[0]);
          setEndBetweenStmtAmt(getData?.fields?.stmtAmt?.value === undefined ? "" : getData?.fields?.stmtAmt?.value[1]);
        } else {
          setValueStmtAmt(getData?.fields?.stmtAmt?.value === undefined ? "" : getData?.fields?.stmtAmt?.value);
        }

        if (getData?.fields?.curBal?.op === "between") {
          setFristBetweenCurBal(getData?.fields?.curBal?.value === undefined ? "" : getData?.fields?.curBal?.value[0]);
          setEndBetweenCurBal(getData?.fields?.curBal?.value === undefined ? "" : getData?.fields?.curBal?.value[1]);
        } else {
          setValueCurBal(getData?.fields?.curBal?.value === undefined ? "" : getData?.fields?.curBal?.value);
        }

        setValueExpireday(getData?.days);

        setConditionLoanType(getData?.fields?.channel?.op);
        setConditionAccountStatus(getData?.fields?.accountStatus?.op ? getData?.fields?.accountStatus?.op : "select");
        setConditionProductType(getData?.fields?.productCode?.op ? getData?.fields?.productCode?.op : "select");
        setConditionAccountLevel(getData?.fields?.accountLevel?.op ? getData?.fields?.accountLevel?.op : "select");
        setConditionLocation(getData?.fields?.centerBranch?.op ? getData?.fields?.centerBranch?.op : "select");

        //selectvalue
        setSelectLoanType(getData?.fields?.channel?.value === undefined ? [] : getData?.fields?.channel?.value);
        setSelectAccountStatus(getData?.fields?.accountStatus?.value === undefined ? [] : getData?.fields?.accountStatus?.value);
        setSelectProductType(getData?.fields?.productCode?.value === undefined ? [] : getData?.fields?.productCode?.value);
        setSelectAccountLevel(getData?.fields?.accountLevel?.value === undefined ? [] : getData?.fields?.accountLevel?.value);
        setSelectLocation(getData?.fields?.centerBranch?.value === undefined ? [] : getData?.fields?.centerBranch?.value);

        setDataBillingCycle(getData?.fields?.billCycle?.value === undefined ? [] : getData?.fields?.billCycle?.value);

        await getProductCode();
        await getTeamAllInPageBasic();
        setConditionLoanTypeNew(data[0].detail[0].condition);
        setConditionAccountStatusNew(data[1].detail[0].condition);
        setConditionProductTypeNew(data[2].detail[0].condition);
        setConditionAccountLevelNew(data[3].detail[0].condition);
        setConditionLocationNew(data[4].detail[0].condition);
        setConditionOutstandingdebtNew(data[5].detail[0].condition);
        setConditionStmtAmtNew(data[5].detail[0].condition);
        setConditionCurBalNew(data[5].detail[0].condition);
        // value
        setSelectLoanTypeNew(data[0].detail[0].value);
        setSelectAccountStatusNew(data[1].detail[0].value);
        setSelectLocationNew(dataLocation);
        checkLoanTypeInput(getData?.fields?.channel?.value[0], getData?.fields?.channel?.value[1], getData?.fields?.channel?.value[2]);
        checkProductTypeInput(
          getData?.fields?.accountStatus?.value[0],
          getData?.fields?.accountStatus?.value[1],
          getData?.fields?.accountStatus?.value[2],
        );
        setValueTeam(getData?.distribution);
        setAssignType(getData?.assignType);
        setExpridedType(getData?.expridedType);
        // console.log(getData?.fields.loanType.value);
      } catch {}
    }
    MasterDataCenterOfBranch();
  }, []);

  const MasterDataCenterOfBranch = async () => {
    const dataMasterdataResult = await DatamanagementService(apiBaseUrlDebt).masterDataCenterBranch();
    setDataMasterDataCenterOfBranch(dataMasterdataResult);
  };

  async function getCenter() {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resual: any = await DatamanagementService(apiBaseUrlDebt).getCenterList();
      resolve(resual);
    });
  }

  const getUserProfile = () => {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resualProfile = await DatamanagementService(apiBaseUrlDebt).getUserDetail();
      setUserprofile(resualProfile);
      resolve(resualProfile);
    });
  };

  const data: DataType[] = [
    {
      id: 1,
      name: "Loan Type",
      detail: [
        {
          name: "",
          type: "select",
          value: ["LOAN", "Card Link", "OD"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 2,
      name: "Account Status",
      detail: [
        {
          name: "",
          type: "select",
          value: ["Non-Npls", "Npls", "W/O"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 3,
      name: "Product Type",
      detail: [
        {
          name: "LOAN",
          type: "select",
          value: getDetailProductLoan,
          condition: ["in"],
        },
        {
          name: "Card Link",
          type: "select",
          value: getDetailProductCard,
          condition: ["in"],
        },
        {
          name: "OD",
          type: "select",
          value: getDetailProductOD,
          condition: ["in"],
        },
      ],
    },
    {
      id: 4,
      name: "Account Level",
      detail: [
        {
          name: "Non-Npls",
          type: "select",
          value: ["P0", "P1", "SM2", "SM3"],
          condition: ["in"],
        },
        {
          name: "Npls",
          type: "select",
          value: [],
          condition: ["in"],
        },
        {
          name: "W/O",
          type: "select",
          value: [],
          condition: ["in"],
        },
      ],
    },
    {
      id: 5,
      name: "Location",
      detail: [
        {
          name: "",
          type: "select",
          value: [],
          condition: ["in"],
        },
      ],
    },
    {
      id: 6,
      name: "เงินงวดค้างชำระ",
      detail: [
        {
          name: "",
          type: "input",
          value: [""],
          condition: [">", "<", "=", "!=", ">=", "<=", "between"],
        },
      ],
    },
    {
      id: 7,
      name: "Billing Cycle",
      detail: [
        {
          name: "",
          type: "select",
          value: ["10", "16", "17", "18", "20", "25", "31"],
          condition: ["in"],
        },
      ],
    },
  ];

  const getTeamAllInPageBasic = async () => {
    const resultTeam = await DatamanagementService(apiBaseUrl).getTeamListBank("BANK");
    const sortTeam = resultTeam.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
    setTeam(sortTeam);
  };

  const editConditionListName = (e: string) => {
    setValueConditionListName(e);
  };

  const editDescription = (e: string) => {
    setValueDescription(e);
  };

  const editOutstandingdebt = (e: any) => {
    setValueOutstandingdebt(e);
  };

  const editStmtAmt = (e: any) => {
    setValueStmtAmt(e);
  };

  const editCurBal = (e: any) => {
    setValueCurBal(e);
  };
  const editExpireday = (e: any) => {
    setValueExpireday(e);
  };

  //Conditions function
  const editConditionLoanType = (e: any) => {
    setConditionLoanType(e);
  };

  const editConditionAccountStatus = (e: any) => {
    setConditionAccountStatus(e);
  };

  const editConditionProductType = (e: any) => {
    setConditionProductType(e);
  };

  const editConditionAccountLevel = (e: any) => {
    setConditionAccountLevel(e);
  };

  const editConditionLocation = (e: any) => {
    setConditionProductType(e);
  };

  const editConditionOutstandingdebt = (e: any) => {
    setConditionOutstandingdebt(e);
  };

  // Select Value
  const editSelectLoanType = (e: any) => {
    // console.log(e);

    setSelectLoanType(e);
    checkLoanTypeInput(e[0], e[1], e[2]);
  };

  const editSelectAccountStatus = (e: any) => {
    setSelectAccountStatus(e);
    checkProductTypeInput(e[0], e[1], e[2]);
  };

  const editSelectProductType = (e: any) => {
    setSelectProductType(e);
  };

  const editSelectAccountLevel = (e: any) => {
    setSelectAccountLevel(e);
  };

  const editSelectLocation = (e: any) => {
    setSelectLocation(e);
  };

  const assignManualRadioCheckBox = (e: string) => {
    setAssignType(e);
    setRadiocheckAuto(false);
    setRadiocheckManual(true);
  };

  const assignAutoRadioCheckBox = (e: string) => {
    setAssignType(e);
    setRadiocheckAuto(true);
    setRadiocheckManual(false);
  };

  const expridedStatementRadioCheckBox = (e: string) => {
    setExpridedType(e);
    setRadioExpridedStatement(true);
    setRadioExpridedAssignTime(false);
  };
  const expridedAssignTimeRadioCheckBox = (e: string) => {
    setExpridedType(e);
    setRadioExpridedStatement(false);
    setRadioExpridedAssignTime(true);
  };

  const onChangeTeam = (e: string) => {
    setValueTeam(e);
  };

  const checkLoanTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          setSelectProductTypeNew(data[2].detail[0].value);
          break;
        case "Card Link":
          setSelectProductTypeNew(data[2].detail[1].value);
          setDataBillingCycleNew(data[6].detail[0].value);
          break;
        case "OD":
          setSelectProductTypeNew(data[2].detail[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link": {
              let valueListin: ValueInDetail[] = data[2].detail[0].value;
              data[2].detail[1].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setSelectProductTypeNew(valueListin);
              setDataBillingCycleNew(data[6].detail[0].value);
              break;
            }
            case "OD": {
              let valueListin: ValueInDetail[] = data[2].detail[0].value;
              data[2].detail[2].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setSelectProductTypeNew(valueListin);
              break;
            }
          }
          break;
        case "Card Link":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin: any = data[2].detail[1].value;
                data[2].detail[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setSelectProductTypeNew(valueListin);
                setDataBillingCycleNew(data[6].detail[0].value);
                break;
              }
              case "OD": {
                let valueListin = data[2].detail[1].value;
                data[2].detail[2].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setSelectProductTypeNew(valueListin);
                setDataBillingCycleNew(data[6].detail[0].value);
                break;
              }
            }
          }
          break;
        case "OD":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin = data[2].detail[2].value;
                data[2].detail[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setSelectProductTypeNew(valueListin);
                break;
              }
              case "Card Link": {
                let valueListin = data[2].detail[2].value;
                data[2].detail[1].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setSelectProductTypeNew(valueListin);
                setDataBillingCycleNew(data[6].detail[0].value);
                break;
              }
            }
          }
          break;
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = data[2].detail[0].value;
                    data[2].detail[1].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                    setDataBillingCycleNew(data[6].detail[0].value);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = data[2].detail[0].value;
                    data[2].detail[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                    setDataBillingCycleNew(data[6].detail[0].value);
                  }
                  break;
              }
              break;
          }
          break;
        case "Card Link":
          switch (valueTwo) {
            case "LOAN":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = data[2].detail[1].value;
                    data[2].detail[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                    setDataBillingCycleNew(data[6].detail[0].value);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = data[2].detail[1].value;
                    data[2].detail[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[0].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                    setDataBillingCycleNew(data[6].detail[0].value);
                  }
                  break;
              }
              break;
          }
          break;
        case "OD":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = data[2].detail[2].value;
                    data[2].detail[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                    setDataBillingCycleNew(data[6].detail[0].value);
                  }
                  break;
              }
              break;
            case "LOAN":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = data[2].detail[2].value;
                    data[2].detail[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                    setDataBillingCycleNew(data[6].detail[0].value);
                  }
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setDataBillingCycleNew([]);
      setDataBillingCycle([]);
      setSelectProductTypeNew([]);
      setSelectProductType([]);
    }
  };

  const checkProductTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          setSelectAccountLevelNew(data[3].detail[0].value);
          break;
        case "Npls":
          setSelectAccountLevelNew(data[3].detail[1].value);
          break;
        case "W/O":
          setSelectAccountLevelNew(data[3].detail[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls": {
              let valueDataAccountLevel: any[] = data[3].detail[0].value;
              data[3].detail[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
            case "W/O": {
              let valueDataAccountLevel: any[] = data[3].detail[0].value;
              data[3].detail[2].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
          }
          break;
        case "Npls":
          {
            switch (valueTwo) {
              case "Non-Npls": {
                let valueDataAccountLevel: any[] = data[3].detail[1].value;
                data[3].detail[0].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setSelectAccountLevelNew(valueDataAccountLevel);
                break;
              }
              case "W/O": {
                let valueDataAccountLevel: any[] = data[3].detail[1].value;
                data[3].detail[2].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setSelectAccountLevelNew(valueDataAccountLevel);
                break;
              }
            }
          }
          break;
        case "W/O": {
          switch (valueTwo) {
            case "Non-Npls": {
              let valueDataAccountLevel: any[] = data[3].detail[2].value;
              data[3].detail[0].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
            case "Npls": {
              let valueDataAccountLevel: any[] = data[3].detail[2].value;
              data[3].detail[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
          }
        }
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = data[3].detail[0].value;
                  data[3].detail[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[0].value;
                  data[3].detail[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "Npls":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = data[3].detail[1].value;
                  data[3].detail[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[1].value;
                  data[3].detail[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "W/O":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[2].value;
                  data[3].detail[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
            case "Npls":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[2].value;
                  data[3].detail[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setSelectAccountLevelNew([]);
      setSelectAccountLevel([]);
      // setcheckValueAccountLevel([]);
    }
  };

  const fetchDataManagementByid = async () => {
    // const resual = await DatamanagementService(apiBaseUrl).getDraftDataManagementByid(AssignUserNew);
    // const resualRulebaseAll = await DatamanagementService(apiBaseUrl).getDataRulebase();
    // const datafilterRule = await resualRulebaseAll.filter((x: any) => x.name === "AssignUser");
    // console.log(datafilterRule[0]?.detail);
    // setResualDatamanagement(resual);
    // setResualDatamanagementDetail(datafilterRule[0]?.detail);
  };
  const checkConditionPrincipal = () => {
    if (getconditionPrincipalAmount === "select") {
      refValuePrincipalAmount.current.focus();
      message.error("โปรดเลือก Condition ของ เงินต้นคงเหลือ");
      return;
    }
  };

  const onClickProductType = (e: any) => {
    if (getSelectLoanType.length === 0) {
      refValueLoanType.current.focus();
      return false;
    }
  };
  const onClickUpdate = async (status: string) => {
    if (getValueExpireday === "" || getValueExpireday === null || getValueExpireday === undefined) {
      refgetValueExpireday.current.focus();
      message.error("กรุณากรอก Expire Day");
      return;
    }

    if (getValueConditionListName === "" || getValueConditionListName === null || getValueConditionListName === undefined) {
      refgetValueConditions.current.focus();
      message.error("กรุณากรอก Condition List Name");
      return;
    }

    if (getValueTeam === "" || getValueTeam === null || getValueTeam === undefined) {
      refgetValueTeam.current.focus();
      message.error("กรุณาเลือก Team");
      return;
    }

    if (getSelectLoanType.length === 0) {
      message.error("โปรดเลือกเงื่อนใข Loan Type");
      return true;
    }

    if (getconditionPrincipalAmount === "between") {
      if (!getFristBetweenPrincipalAmount || !getEndBetweenPrincipalAmount) {
        message.error("โปรดกรอกข้อมูลเงินต้นคงเหลือให้ครบถ้วน");
        return true;
      } else if (Number(getFristBetweenPrincipalAmount) > Number(getEndBetweenPrincipalAmount)) {
        message.error("เงินต้นคงเหลือ ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionOutstandingdebt === "between") {
      if (!getFristBetweenOverduleAmount || !getEndBetweenOverduleAmount) {
        message.error("โปรดกรอกข้อมูลเงินงวดค้างชำระเหลือให้ครบถ้วน");
        return true;
      } else if (Number(getFristBetweenOverduleAmount) > Number(getEndBetweenOverduleAmount)) {
        message.error("เงินงวดค้างชำระ ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionStmtAmt === "between") {
      if (!getFristBetweenStmtAmt || !getEndBetweenStmtAmt) {
        message.error("โปรดกรอกข้อมูลยอดเรียกเก็บตาม statement");
        return true;
      } else if (Number(getFristBetweenStmtAmt) > Number(getEndBetweenStmtAmt)) {
        message.error("ยอดเรียกเก็บตาม statement ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionCurBal === "between") {
      if (!getFristBetweenCurBal || !getEndBetweenCurBal) {
        message.error("โปรดกรอกข้อมูลจำนวนการใช้เงินของบัตร ณ ปัจจุบัน");
        return true;
      } else if (Number(getFristBetweenCurBal) > Number(getEndBetweenCurBal)) {
        message.error("จำนวนการใช้เงินของบัตร ณ ปัจจุบัน ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    let fieldsInstan: any = {};
    let channel: any;
    getSelectLoanType.length === 0
      ? (channel = null)
      : ((channel = {
          op: "in",
          type: "STRING",
          value: getSelectLoanType,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          channel,
        }));

    let accountStatus: any;
    getSelectAccountStatus.length === 0
      ? (accountStatus = null)
      : ((accountStatus = {
          op: "in",
          type: "STRING",
          value: getSelectAccountStatus,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          accountStatus,
        }));

    let productCode: any;
    getSelectProductType.length === 0
      ? (productCode = null)
      : ((productCode = {
          op: "in",
          type: "STRING",
          value: getSelectProductType,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          productCode,
        }));
    let accountLevel: any;
    getSelectAccountLevel.length === 0
      ? (accountLevel = null)
      : ((accountLevel = {
          op: "in",
          type: "STRING",
          value: getSelectAccountLevel,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          accountLevel,
        }));
    let billCycle: any;
    getDataBillingCycle.length === 0
      ? (billCycle = null)
      : ((billCycle = {
          op: "in",
          type: "STRING",
          value: getDataBillingCycle,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          billCycle,
        }));
    let centerBranch: any;
    getSelectLocation.length === 0
      ? (centerBranch = null)
      : ((centerBranch = {
          op: "in",
          type: "STRING",
          value: getSelectLocation,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          centerBranch,
        }));
    const regex = /,/i;
    let principleAmt: any;
    getconditionPrincipalAmount === "between"
      ? ((principleAmt = {
          op: getconditionPrincipalAmount,
          type: "STRING",
          value: [getFristBetweenPrincipalAmount.replace(regex, ""), getEndBetweenPrincipalAmount.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          principleAmt,
        }))
      : getDataPrincipalAmount !== "" && getDataPrincipalAmount !== undefined
      ? ((principleAmt = {
          op: getconditionPrincipalAmount,
          type: "NUMBER",
          value: getDataPrincipalAmount.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          principleAmt,
        }))
      : (principleAmt = null);

    let overdueAmt: any;
    getConditionOutstandingdebt === "between"
      ? ((overdueAmt = {
          op: getConditionOutstandingdebt,
          type: "STRING",
          value: [getFristBetweenOverduleAmount.replace(regex, ""), getEndBetweenOverduleAmount.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          overdueAmt,
        }))
      : getValueOutstandingdebt !== "" && getValueOutstandingdebt !== undefined
      ? ((overdueAmt = {
          op: getConditionOutstandingdebt,
          type: "NUMBER",
          value: getValueOutstandingdebt.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          overdueAmt,
        }))
      : (overdueAmt = null);

    let stmtAmt: any;
    getConditionStmtAmt === "between"
      ? ((stmtAmt = {
          op: getConditionStmtAmt,
          type: "STRING",
          value: [getFristBetweenStmtAmt.replace(regex, ""), getEndBetweenStmtAmt.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          stmtAmt,
        }))
      : getValueStmtAmt !== "" && getValueStmtAmt != undefined
      ? ((stmtAmt = {
          op: getConditionStmtAmt,
          type: "NUMBER",
          value: getValueStmtAmt.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          stmtAmt,
        }))
      : (stmtAmt = null);

    let curBal: any;
    getConditionCurBal === "between"
      ? ((curBal = {
          op: getConditionCurBal,
          type: "STRING",
          value: [getFristBetweenCurBal.replace(regex, ""), getEndBetweenCurBal.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          curBal,
        }))
      : getValueCurBal !== "" && getValueCurBal != undefined
      ? ((curBal = {
          op: getConditionCurBal,
          type: "NUMBER",
          value: getValueCurBal.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          curBal,
        }))
      : (curBal = null);

    Modal.confirm({
      title: "คุณแน่ใจแล้วใช่ไหม",
      icon: <ExclamationCircleOutlined />,
      content: "กดยืนยันเพื่อบันทึกข้อมูล",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        let dataStringBasicUpdate: any = [];
        dataStringBasicUpdate = [
          {
            seq: getDataSeq,
            days: getValueExpireday,
            type: "BASIC",
            fields: fieldsInstan,
            status: status,
            listname: getValueConditionListName,
            updateBy: userProfile.name,
            createBy: userProfile.id,
            assignType: getAssignType,
            createDate: createDate,
            description: getValueDescription,
            distribution: getValueTeam,
          },
        ];

        const resualUpdate = await DatamanagementService(apiBaseUrl).updateConditionByid(AssignUserNew, {
          name: AssignUserNew,
          detail: dataStringBasicUpdate,
        });
        if (resualUpdate === 200) {
          message.success("Success");
          push(`/datamanagement`);
        }
      },
      onCancel: () => {},
    });
  };
  const getProductCode = async () => {
    const resual: any = await DatamanagementService(apiBaseUrlDebt).getProductCode();
    await resual.map((x: any, index: number) => {
      if (x.loanType === "LOAN") {
        getDetailProductLoan.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      } else if (x.loanType === "Card Link") {
        getDetailProductCard.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      } else if (x.loanType === "OD") {
        getDetailProductOD.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      }
    });
  };

  const OPTIONS = data[0].detail[0].value;
  const filteredOptions = OPTIONS.filter((o: any) => !getSelectLoanType.includes(o));
  const checkCondition = (text: string) => {
    if (text === "8") {
      if (getConditionOutstandingdebt === "select") {
        refValueConditionAmount.current.focus();
        message.error("โปรดเลือก Condition ของ เงินงวดค้างชำระ");
        return;
      }
    } else if (text === "9") {
      if (getConditionStmtAmt === "select") {
        refValueConditionStmtAmt.current.focus();
        message.error("โปรดเลือก Condition ของ ยอดเรียกเก็บตาม statement");
        return;
      }
    } else if (text === "10") {
      if (getConditionCurBal === "select") {
        refValueConditionCurBal.current.focus();
        message.error("โปรดเลือก Condition ของ จำนวนการใช้เงินของบัตร ณ ปัจจุบัน");
        return;
      }
    }
  };
  const fnCheckconditions = (e: any) => {
    setConditionOutstandingdebt(e);
    if (String(e) === "select") {
      editOutstandingdebt("");
    }
  };
  const onChangTreevalue = (e: any) => {
    setSelectProductType(e);
    // setDataProductTypeSubmit(e);
  };
  const treeData = [
    {
      title: "เลือกทั้งหมด",
      value: "001",
      key: "001",
      children: getSelectProductTypeNew,
    },
  ];
  const tProps = {
    treeData,
    value: getSelectProductType,
    onChange: onChangTreevalue,
    allowClear: true,
    treeCheckable: true,
    // treeNodeFilterProp: "",
  };
  const [getcheckButtonShowProduct, setcheckButtonShowProduct] = useState<boolean>(true);
  const fnSetmaxTagCount = (e: boolean) => {
    if (e == true) {
      const countDetailProductLoan = getDetailProductLoan.length;
      setcheckButtonShowProduct(false);
      setmaxTagCount(countDetailProductLoan);
    } else {
      setcheckButtonShowProduct(true);
      setmaxTagCount("responsive");
    }
  };
  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Card style={{ width: "100%", textAlign: "left" }} title="Edit Condition List">
          <Form labelCol={{ span: 4 }}>
            <Form.Item label="Condition List Name :">
              <Input
                maxLength={100}
                ref={refgetValueConditions}
                value={getValueConditionListName}
                onChange={(e) => editConditionListName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Description :">
              <Input value={getValueDescription} onChange={(e) => editDescription(e.target.value)} />
            </Form.Item>
          </Form>
          <Row gutter={10}>
            <Col span={2} style={{ textAlign: "left" }}>
              No.
            </Col>
            <Col span={10} style={{ textAlign: "left" }}>
              Field
            </Col>
            {/* <Col span={6} style={{ textAlign: "left" }}>
              Condition
            </Col> */}
            <Col span={12} style={{ textAlign: "left" }}>
              Value
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>1</Col>
            <Col span={10}>
              <Input value="Loan Type" />
            </Col>
            <Col span={12}>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={getSelectLoanType}
                onChange={(e) => editSelectLoanType(e)}
                ref={refValueLoanType}
                allowClear
                removeIcon
                showArrow={false}
              >
                {filteredOptions.map((value: any, index: any) => {
                  return (
                    <Option key={index} value={String(value)}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>2</Col>
            <Col span={10}>
              <Input value="Account Status" />
            </Col>

            <Col span={12}>
              <Select mode="multiple" style={{ width: "100%" }} value={getSelectAccountStatus} onChange={(e) => editSelectAccountStatus(e)}>
                {getSelectAccountStatusNew.map((value: any, index: any) => {
                  return (
                    <Option key={index} value={String(value)}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>3</Col>
            <Col span={10}>
              <Input value="Product Type" />
            </Col>
            <Col span={9}>
              <TreeSelect
                treeNodeFilterProp="title"
                showArrow
                treeDefaultExpandAll
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                disabled={getSelectLoanType?.length === 1 && getSelectLoanType[0] === "OD" ? true : false}
                onClick={onClickProductType}
                style={{ width: " 100%" }}
                {...tProps}
                maxTagCount={getmaxTagCount}
              />
            </Col>
            {getDataAll?.fields?.productCode?.value ? (
              <Button type="link" onClick={() => fnSetmaxTagCount(getcheckButtonShowProduct)}>
                แสดงทั้งหมด
              </Button>
            ) : (
              <></>
            )}
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>4</Col>
            <Col span={10}>
              <Input value="Billing Cycle" />
            </Col>

            <Col span={12}>
              <Select
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                ref={refBillingCycle}
                onChange={(e) => {
                  setDataBillingCycle(e);
                }}
                value={getDataBillingCycle}
              >
                {getDataBillingCycleNew.map((bilcycle: any, index: any) => {
                  return (
                    <Option key={index} value={bilcycle}>
                      {bilcycle}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>5</Col>
            <Col span={10}>
              <Input value="Account Level" />
            </Col>

            <Col span={12}>
              <Select mode="multiple" style={{ width: "100%" }} value={getSelectAccountLevel} onChange={(e) => editSelectAccountLevel(e)}>
                {getSelectAccountLevelNew.map((value: any, index: any) => {
                  return (
                    <Option key={index} value={String(value)}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>6</Col>
            <Col span={10}>
              <Input value="Location" />
            </Col>

            <Col span={12}>
              <Select mode="multiple" style={{ width: "100%" }} onChange={(e) => editSelectLocation(e)} value={getSelectLocation}>
                {getDataMasterDataCenterOfBranch?.map((event: IMasterDataOfBranch, index: number) => {
                  return <Option key={event.team_group_id} value={String(event.team_group_id)}>{`${event.desc_local}`}</Option>;
                })}
              </Select>
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>7</Typography>
            </Col>
            <Col span={5}>
              <Input value={"เงินต้นคงเหลือ"} readOnly />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: " 100%" }}
                onChange={(e) => setconditionPrincipalAmount(e)}
                value={getconditionPrincipalAmount}
                ref={refValuePrincipalAmount}
              >
                <Option value={"select"}>select</Option>
                {data[5].detail[0].condition.map((condition: any, index: any) => {
                  return (
                    <Option key={index} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getconditionPrincipalAmount === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenPrincipalAmount(value);
                    }}
                    value={getFristBetweenPrincipalAmount}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenPrincipalAmount(value);
                    }}
                    value={getEndBetweenPrincipalAmount}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getDataPrincipalAmount}
                  min={0}
                  onClick={checkConditionPrincipal}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    setDataPrincipalAmount(value);
                  }}
                />
              </Col>
            )}
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>8</Col>
            <Col span={5}>
              <Input value="เงินงวดค้างชำระ" />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: " 100%", textAlign: "left" }}
                onChange={(e) => setConditionOutstandingdebt(e)}
                value={getConditionOutstandingdebt}
                ref={refValueConditionOutstanding}
              >
                <Option key={0} value={"select"}>
                  select
                </Option>
                {getConditionOutstandingdebtNew.map((condition: any, index: any) => {
                  return (
                    <Option key={index + 1} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getConditionOutstandingdebt === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenOverduleAmount(value);
                    }}
                    value={getFristBetweenOverduleAmount}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenOverduleAmount(value);
                    }}
                    value={getEndBetweenOverduleAmount}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getValueOutstandingdebt}
                  min={0}
                  onClick={() => checkCondition("8")}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    editOutstandingdebt(value);
                  }}
                />
              </Col>
            )}
          </Row>

          <br></br>
          <Row gutter={10}>
            <Col span={2}>9</Col>
            <Col span={5}>
              <Input value="ยอดเรียกเก็บตาม statement" />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: " 100%", textAlign: "left" }}
                onChange={(e) => setConditionStmtAmt(e)}
                value={getConditionStmtAmt}
                ref={refValueConditionStmtAmt}
              >
                <Option key={0} value={"select"}>
                  select
                </Option>
                {getConditionStmtAmtNew.map((condition: any, index: any) => {
                  return (
                    <Option key={index + 1} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getConditionStmtAmt === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenStmtAmt(value);
                    }}
                    value={getFristBetweenStmtAmt}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenStmtAmt(value);
                    }}
                    value={getEndBetweenStmtAmt}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getValueStmtAmt}
                  min={0}
                  onClick={() => checkCondition("9")}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    editStmtAmt(value);
                  }}
                />
              </Col>
            )}
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>10</Col>
            <Col span={5}>
              <Input value="จำนวนการใช้เงินของบัตร ณ ปัจจุบัน" />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: " 100%", textAlign: "left" }}
                onChange={(e) => setConditionCurBal(e)}
                value={getConditionCurBal}
                ref={refValueConditionCurBal}
              >
                <Option key={0} value={"select"}>
                  select
                </Option>
                {getConditionCurBalNew.map((condition: any, index: any) => {
                  return (
                    <Option key={index + 1} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getConditionCurBal === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenCurBal(value);
                    }}
                    value={getFristBetweenCurBal}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenCurBal(value);
                    }}
                    value={getEndBetweenCurBal}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getValueCurBal}
                  min={0}
                  onClick={() => checkCondition("10")}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    editCurBal(value);
                  }}
                />
              </Col>
            )}
          </Row>
          <br></br>
          <Row>
            <Col span={4} style={{ textAlign: "right" }}>
              Expire Day :
            </Col>
            {getSelectLoanType?.length === 1 ? (
              getSelectLoanType[0] === "Card Link" && getSelectAccountStatus?.length === 1 ? (
                getSelectAccountStatus[0] === "Non-Npls" ? (
                  <Col offset={1} span={19} style={{ textAlign: "left" }}>
                    <DatePicker
                      locale={datePickerTh}
                      defaultValue={dayjs(getValueExpireday)}
                      ref={refgetValueExpireday}
                      disabledDate={(current: any) => {
                        return moment().add(-1, "days") >= current;
                      }}
                      style={{ width: "20%" }}
                      format={"DD-MM-BBBB"}
                      onChange={(e: any) => {
                        if (e) {
                          let dataFormatDate: any = dayjs(e).format("YYYY-MM-DD");
                          return editExpireday(dataFormatDate);
                        } else {
                          return editExpireday("");
                        }
                      }}
                    />
                  </Col>
                ) : (
                  <Col offset={1} span={19} style={{ textAlign: "left" }}>
                    <InputNumber
                      type="number"
                      min={1}
                      placeholder="1"
                      value={getValueExpireday}
                      ref={refgetValueExpireday}
                      onChange={(e) => editExpireday(e)}
                    />
                  </Col>
                )
              ) : (
                <Col offset={1} span={19} style={{ textAlign: "left" }}>
                  <InputNumber
                    type="number"
                    min={1}
                    placeholder="1"
                    value={getValueExpireday}
                    ref={refgetValueExpireday}
                    onChange={(e) => editExpireday(e)}
                  />
                </Col>
              )
            ) : (
              <Col offset={1} span={19} style={{ textAlign: "left" }}>
                <InputNumber
                  type="number"
                  min={1}
                  placeholder="1"
                  value={getValueExpireday}
                  ref={refgetValueExpireday}
                  onChange={(e) => editExpireday(e)}
                />
              </Col>
            )}
          </Row>
          <br></br>
          <Row>
            <Col span={4} style={{ textAlign: "right" }}>
              Assign Type
            </Col>
            <Col offset={1} span={19} style={{ textAlign: "left" }}>
              <Radio value={"Manual"} onChange={(e: any) => assignManualRadioCheckBox(e.target.value)} checked={getRadiocheckManual}>
                Manual
              </Radio>
              <Radio value={"Auto"} onChange={(e: any) => assignAutoRadioCheckBox(e.target.value)} checked={getRadiocheckAuto}>
                Auto
              </Radio>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={4} style={{ textAlign: "right" }}>
              Team
            </Col>
            <Col offset={1} span={19} style={{ textAlign: "left" }}>
              <Select
                allowClear
                ref={refgetValueTeam}
                style={{ width: "20%" }}
                placeholder="Select Team"
                onChange={onChangeTeam}
                value={getValueTeam}
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
              >
                {getTeam?.map((x: typeTeam, index: number) => {
                  return (
                    <Option value={x.id} key={index + 1}>
                      {x.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={4} style={{ textAlign: "left" }}></Col>
            <Col span={20} style={{ textAlign: "left" }}>
              <Button type="primary" onClick={() => onClickUpdate("DRAFT")}>
                Save Draft
              </Button>
              {"   "}
              <Button style={{ backgroundColor: "#04C696", color: "#FFFFFF" }} onClick={() => onClickUpdate("WAITING_APPROVAL")}>
                Request Approval
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};
