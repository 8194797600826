import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Card, Form, Row, Input, Radio, Table, Select, Col, Button, InputNumber, Typography, Modal, message, Spin } from "antd";
import { DatamanagementService } from "../../services/datamanagement-service";
import { Consditions, DataType, ICenter, IuserProfile, typeTeam, User, ValueInDetail } from "../../models/models-datamanagement";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { loadAppConfig } from "../../../../config/app-config";
import { useId24 } from "../../../../drivers/id24/Id24-provider";
import NumberFormat from "react-number-format";
import moment from "moment";
import dayjs from "dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { IMasterDataOfBranch, Role } from "../../common";
import { UserAccess } from "../../../../drivers/id24/models/user-access";
import { config, datePickerTh } from "../../../../utils";

const DatePicker = generatePicker(config);
const appConfig = loadAppConfig();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;
interface Props {
  baseUrl: string;
  accessRole: any;
}
export const EditPageWattingApprove: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const location: any = useLocation();
  const { push } = useHistory();
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });
  const [getDataAll, setDataAll] = useState<Consditions>();
  const [getRadiocheckBasic, setRadiocheckBasic] = useState<boolean>(false);
  const [getRadiocheckAdvance, setRadiocheckAdvance] = useState<boolean>(false);
  const [getRadiocheckManual, setRadiocheckManual] = useState<boolean>(false);
  const [getRadiocheckAuto, setRadiocheckAuto] = useState<boolean>(false);
  const [getTeam, setTeam] = useState<typeTeam[]>([]);
  const [getSpinningState, setSpinningState] = useState<boolean>(true);
  const [getmaxTagCount, setmaxTagCount] = useState<any>("responsive");
  const [permissionId24, setPermissionId24] = useState<any>([]);
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [CleardistNew, setCleardistNew] = useState<string | number>();

  function multipleInArray(arr: string[], values: Role[]) {
    return values.some((value) => {
      return arr.includes(value);
    });
  }

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };

  useEffect(() => {
    try {
      mapRoleAccess().then(async (resultmap) => {
        setPermissionId24(resultmap);
        const userAssign = () => {
          let AssignUserNew;
          multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
            ? (AssignUserNew = "AssignUser")
            : (AssignUserNew = `User:${userProfile.id}:AssignUser`);
          setAssignUserNew(AssignUserNew);
          return AssignUserNew;
        };
        const userCleardist = () => {
          let AssignUserNew;
          multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
            ? (AssignUserNew = 0)
            : (AssignUserNew = `User:${userProfile.id}:AssignUser`);
          setCleardistNew(AssignUserNew);
          return AssignUserNew;
        };
        userCleardist();
        userAssign();
      });
    } catch {}

    getTeamAllInPageBasic();
    getProductCodefn();
    async function getData() {
      try {
        const getData = await location.state;
        console.log(getData);
        setDataAll(getData);
        getUserProfile();
        {
          getData?.type === "BASIC" ? setRadiocheckBasic(true) : setRadiocheckAdvance(true);
        }
        {
          getData?.assignType === "Manual" ? setRadiocheckManual(true) : setRadiocheckAuto(true);
        }
      } catch {}
      getCenter();
    }
    getData();
    MasterDataCenterOfBranch();
  }, []);

  const getUserProfile = async () => {
    const resual = await DatamanagementService(apiBaseUrlDebt).getUserDetail();
    setUserprofile(resual);
  };

  const getTeamAllInPageBasic = async () => {
    const resultTeam = await DatamanagementService(apiBaseUrl).getTeamListBank("BANK");
    const sortTeam = resultTeam.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
    setTeam(sortTeam);
  };

  const basicRadioCheckBox = (e: string) => {
    setRadiocheckBasic(true);
    setRadiocheckAdvance(false);
  };

  const advanceRadioCheckBox = (e: string) => {
    setRadiocheckAdvance(true);
    setRadiocheckBasic(false);
  };
  const assignManualRadioCheckBox = (e: string) => {
    setRadiocheckAuto(true);
    setRadiocheckManual(false);
  };

  const assignAutoRadioCheckBox = (e: string) => {
    setRadiocheckAuto(true);
    setRadiocheckManual(false);
  };
  const onClickUpdate = async (status: string) => {
    if (status === "APPROVED") {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อบันทึกข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          // console.log(getDataAll?.seq, AssignUserNew, status);
          const resual = await DatamanagementService(apiBaseUrl).updateConditionByseq(getDataAll?.seq, AssignUserNew, status);
          // console.log(resual);
          if (resual) {
            message.success("Success");
            push(`/datamanagement`);
          }
        },
      });
    } else if (status === "DRAFT") {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อบันทึกข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const resual = await DatamanagementService(apiBaseUrl).updateConditionByseq(getDataAll?.seq, AssignUserNew, status);
          if (resual) {
            message.success("Success");
            push(`/datamanagement`);
          }
        },
        onCancel: () => {},
      });
    }
  };
  const [getDetailProductLoan, setDetailProductLoan] = useState<ValueInDetail[]>([]);
  const [getLocation, setLocation] = useState<ICenter[]>([]);

  const [getDataMasterDataCenterOfBranch, setDataMasterDataCenterOfBranch] = useState<IMasterDataOfBranch[]>([]);
  const MasterDataCenterOfBranch = async () => {
    const dataMasterdataResult = await DatamanagementService(apiBaseUrlDebt).masterDataCenterBranch();
    setDataMasterDataCenterOfBranch(dataMasterdataResult);
  };
  const getCenter = async () => {
    const resual: any = await DatamanagementService(apiBaseUrlDebt).getCenterList();
    setLocation(resual);
  };
  async function getProductCodefn() {
    const resual: any = await DatamanagementService(apiBaseUrlDebt).getProductCode();
    const setResult = await resual.map((x: any, index: number) => {
      getDetailProductLoan.push({
        title: x.productDesc,
        value: x.productCode,
        key: x.productCode,
      });
    });
    if (setResult) {
      setSpinningState(false);
    }
  }
  const [getcheckButtonShowProduct, setcheckButtonShowProduct] = useState<boolean>(true);
  const fnSetmaxTagCount = (e: boolean) => {
    if (e == true) {
      const countDetailProductLoan = getDetailProductLoan.length;
      setcheckButtonShowProduct(false);
      setmaxTagCount(countDetailProductLoan);
    } else {
      setcheckButtonShowProduct(true);
      setmaxTagCount("responsive");
    }
  };

  function isDecimal(n: any) {
    var result = n - Math.floor(n) !== 0;
    if (result) return true;
    else return false;
  }
  // dataBody
  return (
    <Spin indicator={antIcon} spinning={getSpinningState}>
      <>
        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem" }}
        >
          <Card style={{ width: "100%", textAlign: "left" }} title="Edit Condition List">
            <Form labelCol={{ span: 4 }}>
              <Form.Item label="Condition List Name :">
                <Input maxLength={100} value={getDataAll?.listname} readOnly />
              </Form.Item>
              <Form.Item label="Description :">
                <Input value={getDataAll?.description} readOnly />
              </Form.Item>
            </Form>
            {getDataAll?.type === "BASIC" ? (
              <>
                <Row gutter={10}>
                  <Col span={2} style={{ textAlign: "left" }}>
                    No.
                  </Col>
                  <Col span={10} style={{ textAlign: "left" }}>
                    Field
                  </Col>
                  <Col span={12} style={{ textAlign: "left" }}>
                    Value
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>1</Col>
                  <Col span={10}>
                    <Input value="Loan Type" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.channel?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>2</Col>
                  <Col span={10}>
                    <Input value="Account Status" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.accountStatus?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>3</Col>
                  <Col span={10}>
                    <Input value="Product Type" readOnly />
                  </Col>
                  {/* <Col span={6}>
                  <Input value={getDataAll?.fields?.productCode?.op} disabled />
                </Col> */}
                  <Col span={9}>
                    <Select
                      mode="multiple"
                      disabled
                      style={{ width: "100%" }}
                      value={getDataAll?.fields?.productCode?.value}
                      maxTagCount={getmaxTagCount}
                    >
                      {getDetailProductLoan.map((value: any, index: number) => {
                        return (
                          <Option key={value.key} value={value.key}>
                            {value.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  {getDataAll?.fields?.productCode?.value ? (
                    <Button type="link" onClick={() => fnSetmaxTagCount(getcheckButtonShowProduct)}>
                      แสดงทั้งหมด
                    </Button>
                  ) : (
                    <></>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>4</Col>
                  <Col span={10}>
                    <Input value="Billing Cycle" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.billCycle?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>5</Col>
                  <Col span={10}>
                    <Input value="Account Level" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.accountLevel?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>6</Col>
                  <Col span={10}>
                    <Input value="Location" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.centerBranch?.value}>
                      {getDataMasterDataCenterOfBranch?.map((event: IMasterDataOfBranch, index: number) => {
                        return <Option key={event.team_group_id} value={String(event.team_group_id)}>{`${event.desc_local}`}</Option>;
                      })}
                    </Select>
                  </Col>
                </Row>
                {/* {6} */}
                <br></br>
                <Row gutter={10}>
                  <Col span={2}>
                    <Typography>7</Typography>
                  </Col>
                  <Col span={5}>
                    <Input value={"เงินต้นคงเหลือ"} readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.principleAmt?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.principleAmt?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.principleAmt?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.principleAmt?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.principleAmt?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.principleAmt?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        decimalScale={2}
                        className="ant-input"
                        thousandSeparator={true}
                        disabled
                        value={getDataAll?.fields?.principleAmt?.value}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.principleAmt?.value) == true ? "" : ".00"}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>8</Col>
                  <Col span={5}>
                    <Input value="เงินงวดค้างชำระ" readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.overdueAmt?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.overdueAmt?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.overdueAmt?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.overdueAmt?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        value={getDataAll?.fields?.overdueAmt?.value}
                        min={0}
                        decimalScale={2}
                        suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value) == true ? "" : ".00"}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>9</Col>
                  <Col span={5}>
                    <Input value="ยอดเรียกเก็บตาม statement" readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.stmtAmt?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.stmtAmt?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.stmtAmt?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.stmtAmt?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        value={getDataAll?.fields?.stmtAmt?.value}
                        min={0}
                        decimalScale={2}
                        suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value) == true ? "" : ".00"}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>10</Col>
                  <Col span={5}>
                    <Input value="จำนวนการใช้เงินของบัตร ณ ปัจจุบัน" readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.curBal?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.curBal?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.curBal?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.curBal?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.curBal?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.curBal?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        value={getDataAll?.fields?.curBal?.value}
                        min={0}
                        decimalScale={2}
                        suffix={isDecimal(getDataAll?.fields?.curBal?.value) == true ? "" : ".00"}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <>
                <Row gutter={10}>
                  <Col span={3} style={{ textAlign: "right" }}>
                    No.
                  </Col>
                  <Col offset={1} span={20} style={{ textAlign: "left" }}>
                    Condition
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px", textAlign: "right" }} gutter={10}>
                  <Col span={3}>1</Col>
                  <Col offset={1} span={20}>
                    <Input value={getDataAll?.expression} readOnly />
                  </Col>
                </Row>
                <br></br>
              </>
            )}
            <br></br>
            <Row gutter={10}>
              <Col span={3} style={{ textAlign: "right" }}>
                Expire Day :
              </Col>
              <Col offset={1} span={20} style={{ textAlign: "left" }}>
                {getDataAll ? (
                  getDataAll?.fields?.channel?.value?.length === 1 ? (
                    getDataAll?.fields.channel.value[0] === "Card Link" && getDataAll?.fields?.accountStatus?.value?.length === 1 ? (
                      getDataAll?.fields.accountStatus.value[0] === "Non-Npls" ? (
                        <DatePicker
                          disabled
                          locale={datePickerTh}
                          defaultValue={dayjs(getDataAll?.days)}
                          disabledDate={(current: any) => {
                            return moment().add(-1, "days") >= current;
                          }}
                          style={{ width: "20%" }}
                          format={"DD-MM-BBBB"}
                        />
                      ) : (
                        <InputNumber type="number" value={getDataAll?.days} readOnly />
                      )
                    ) : (
                      <InputNumber type="number" value={getDataAll?.days} readOnly />
                    )
                  ) : (
                    <InputNumber type="number" value={getDataAll?.days} readOnly />
                  )
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col span={3} style={{ textAlign: "right" }}>
                Assign Type
              </Col>
              <Col offset={1} span={20} style={{ textAlign: "left" }}>
                <Radio value={"Manual"} onChange={(e: any) => assignManualRadioCheckBox(e.target.value)} checked={getRadiocheckManual} disabled>
                  Manual
                </Radio>
                <Radio value={"Manual"} onChange={(e: any) => assignAutoRadioCheckBox(e.target.value)} checked={getRadiocheckAuto} disabled>
                  Auto
                </Radio>
              </Col>
            </Row>
            <br></br>
            <Row gutter={10}>
              <Col span={3} style={{ textAlign: "right" }}>
                Team
              </Col>
              <Col offset={1} span={20} style={{ textAlign: "left" }}>
                <Select
                  style={{ width: "20%" }}
                  placeholder="Please select"
                  // onChange={onChangeTeam}
                  // value={getTeam}
                  value={getDataAll?.distribution}
                  disabled
                >
                  {getTeam?.map((x: typeTeam, index: number) => {
                    return (
                      <Option value={x.id} key={index + 1}>
                        {x.name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <br></br>
            <Row gutter={10}>
              <Col span={4} style={{ textAlign: "left" }}></Col>
              <Col span={20} style={{ textAlign: "left" }}>
                <Button type="primary" onClick={() => onClickUpdate("APPROVED")}>
                  Approve
                </Button>
                {"   "}
                <Button type="primary" danger onClick={() => onClickUpdate("DRAFT")}>
                  Reject
                </Button>
              </Col>
            </Row>
          </Card>
        </Row>
      </>
    </Spin>
  );
};
