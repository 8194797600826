import React, { useEffect, useState } from "react";
import { Card, Row, Col, Collapse, Tag, Avatar, Spin } from "antd";
import { DownCircleOutlined, LoadingOutlined, RightCircleOutlined, UserOutlined } from "@ant-design/icons";
import { DashboardService } from "./services/dashboard-service";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();

interface Props {
  baseUrl: string;
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Panel } = Collapse;

export const DashboardManager: React.FC<Props> = ({ baseUrl }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const frontStyleCard = {
    textAlign: "left",
    fontSize: "12px",
  } as any;

  const fontStyleHerder = {
    fontSize: "18px",
    color: "#FFFFFF",
  } as any;

  const fontStyleTap = {
    fontSize: "14px",
    borderRadius: "10px",
  } as any;

  const frontStyleInCard = {
    textAlign: "left",
    fontSize: "10px",
  } as any;

  const history = useHistory();

  // function getCookie(key: string) {
  //   var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  //   return b ? b.pop() : "";
  // }

  const { id24Axios } = useId24();

  // const decodedCookie = getCookie("id24Token");
  const [dataSourceNew, setDataSourceNew] = useState([]);
  const [dataSourceAction, setDataSourceAction] = useState([]);
  const [dataSourceNoAction, setDataSourceNoAction] = useState([]);

  function integrateDashboardNew(data: any) {
    let listNew: any = [];
    let collList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionstatus === "new") {
        let list = {
          listname: x1.listname,
          collid: x1.collid,
          collname: x1.collname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
        };
        listNew.push(list);

        let team = {
          collid: x1.collid,
          collname: x1.collname,
          listname: x1.listname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
        };
        collList.push(team);
      }
    });
    var holderCnt: any = {};
    listNew.forEach(function (d: any) {
      if (holderCnt.hasOwnProperty(d.collid)) {
        holderCnt[d.collid] = holderCnt[d.collid] + d.cnt;
      } else {
        holderCnt[d.collid] = d.cnt;
      }
    });
    var objCnt: any = [];
    for (var prop in holderCnt) {
      objCnt.push({ collid: prop, cnt: holderCnt[prop] });
    }

    const checkCnt = (collid: string) => {
      const found = objCnt.find((a: any) => a.collid === collid);

      if (found) {
        return found.cnt;
      } else {
        return "0";
      }
    };

    var holderSumamt: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumamt.hasOwnProperty(d.collid)) {
        holderSumamt[d.collid] = holderSumamt[d.collid] + d.sumamt;
      } else {
        holderSumamt[d.collid] = d.sumamt;
      }
    });
    var objSumamt: any = [];
    for (var prop in holderSumamt) {
      objSumamt.push({ collid: prop, sumamt: holderSumamt[prop] });
    }

    const checkSumamt = (collid: string) => {
      const found = objSumamt.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumamt;
      } else {
        return "0";
      }
    };

    var holderSumos: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumos.hasOwnProperty(d.collid)) {
        holderSumos[d.collid] = holderSumos[d.collid] + d.sumos;
      } else {
        holderSumos[d.collid] = d.sumos;
      }
    });
    var objSumos: any = [];
    for (var prop in holderSumos) {
      objSumos.push({ collid: prop, sumos: holderSumos[prop] });
    }

    const checkSumos = (collid: string) => {
      const found = objSumos.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumos;
      } else {
        return "0";
      }
    };

    var holderSumcurbal: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumcurbal.hasOwnProperty(d.collid)) {
        holderSumcurbal[d.collid] = holderSumcurbal[d.collid] + d.sumcurbal;
      } else {
        holderSumcurbal[d.collid] = d.sumcurbal;
      }
    });
    var objSumcurbal: any = [];
    for (var prop in holderSumamt) {
      objSumcurbal.push({ collid: prop, sumcurbal: holderSumcurbal[prop] });
    }

    const checkSumcurbal = (collid: string) => {
      const found = objSumcurbal.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumcurbal;
      } else {
        return "0";
      }
    };

    var holderSumstmtamt: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumstmtamt.hasOwnProperty(d.collid)) {
        holderSumstmtamt[d.collid] = holderSumstmtamt[d.collid] + d.sumstmtamt;
      } else {
        holderSumstmtamt[d.collid] = d.sumstmtamt;
      }
    });
    var objSumstmtamt: any = [];
    for (var prop in holderSumamt) {
      objSumstmtamt.push({ collid: prop, sumstmtamt: holderSumstmtamt[prop] });
    }

    const checkSumstmtamt = (collid: string) => {
      const found = objSumstmtamt.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumstmtamt;
      } else {
        return "0";
      }
    };

    const groupByList = (listNew: any) =>
      Object.values(
        listNew.reduce((data: any, { listname, collid, collname, cnt, sumamt, sumcurbal, sumstmtamt, sumos }: any) => {
          let ac: any;
          let os: any;
          let bal: any;
          let cur: any;
          let stmt: any;
          if (data[collid]) {
            if (
              !data[collid].listList.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let oaDetails = {
                listname: listname,
                collname: collname,
                cnt: cnt,
                sumamt: sumamt,
                sumcurbal: sumcurbal,
                sumstmtamt: sumstmtamt,
                sumos: sumos,
              };
              data[collid].listList.push(oaDetails);
            } else {
              data[collid].listList.find((listcheck: any, index: any) => {
                if (listcheck.listname == listname) {
                  listcheck.cnt = listcheck.cnt + cnt;
                  listcheck.sumamt = listcheck.sumamt + sumamt;
                  listcheck.sumcurbal = listcheck.sumcurbal + sumcurbal;
                  listcheck.sumstmtamt = listcheck.sumstmtamt + sumstmtamt;
                  listcheck.sumos = listcheck.sumos + sumos;
                }
              });
            }
          } else {
            let oaDetails = {
              listname: listname,
              collname: collname,
              cnt: cnt,
              sumamt: sumamt,
              sumcurbal: sumcurbal,
              sumstmtamt: sumstmtamt,
              sumos: sumos,
            };
            ac = checkCnt(String(collid));
            os = checkSumos(String(collid));
            bal = checkSumamt(String(collid));
            cur = checkSumcurbal(String(collid));
            stmt = checkSumstmtamt(String(collid));

            data[collid] = {
              collid,
              collname,
              ac,
              os,
              bal,
              cur,
              stmt,
              listList: [oaDetails],
            };
          }
          return data;
        }, {}),
      );

    const newObject = {
      statusName: "new",
      collList: groupByList(listNew),
    };
    dataSource.push(newObject);
    return dataSource;
  }

  function integrateDashboardAction(data: any) {
    let listNew: any = [];
    let collList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionstatus === "action") {
        let list = {
          listname: x1.listname,
          collid: x1.collid,
          collname: x1.collname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
        };
        listNew.push(list);

        let team = {
          collid: x1.collid,
          collname: x1.collname,
          listname: x1.listname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
        };
        collList.push(team);
      }
    });
    var holderCnt: any = {};
    listNew.forEach(function (d: any) {
      if (holderCnt.hasOwnProperty(d.collid)) {
        holderCnt[d.collid] = holderCnt[d.collid] + d.cnt;
      } else {
        holderCnt[d.collid] = d.cnt;
      }
    });
    var objCnt: any = [];
    for (var prop in holderCnt) {
      objCnt.push({ collid: prop, cnt: holderCnt[prop] });
    }

    const checkCnt = (collid: string) => {
      const found = objCnt.find((a: any) => a.collid === collid);

      if (found) {
        return found.cnt;
      } else {
        return "0";
      }
    };

    var holderSumamt: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumamt.hasOwnProperty(d.collid)) {
        holderSumamt[d.collid] = holderSumamt[d.collid] + d.sumamt;
      } else {
        holderSumamt[d.collid] = d.sumamt;
      }
    });
    var objSumamt: any = [];
    for (var prop in holderSumamt) {
      objSumamt.push({ collid: prop, sumamt: holderSumamt[prop] });
    }

    const checkSumamt = (collid: string) => {
      const found = objSumamt.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumamt;
      } else {
        return "0";
      }
    };

    var holderSumos: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumos.hasOwnProperty(d.collid)) {
        holderSumos[d.collid] = holderSumos[d.collid] + d.sumos;
      } else {
        holderSumos[d.collid] = d.sumos;
      }
    });
    var objSumos: any = [];
    for (var prop in holderSumos) {
      objSumos.push({ collid: prop, sumos: holderSumos[prop] });
    }

    const checkSumos = (collid: string) => {
      const found = objSumos.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumos;
      } else {
        return "0";
      }
    };

    var holderSumcurbal: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumcurbal.hasOwnProperty(d.collid)) {
        holderSumcurbal[d.collid] = holderSumcurbal[d.collid] + d.sumcurbal;
      } else {
        holderSumcurbal[d.collid] = d.sumcurbal;
      }
    });
    var objSumcurbal: any = [];
    for (var prop in holderSumamt) {
      objSumcurbal.push({ collid: prop, sumcurbal: holderSumcurbal[prop] });
    }

    const checkSumcurbal = (collid: string) => {
      const found = objSumcurbal.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumcurbal;
      } else {
        return "0";
      }
    };

    var holderSumstmtamt: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumstmtamt.hasOwnProperty(d.collid)) {
        holderSumstmtamt[d.collid] = holderSumstmtamt[d.collid] + d.sumstmtamt;
      } else {
        holderSumstmtamt[d.collid] = d.sumstmtamt;
      }
    });
    var objSumstmtamt: any = [];
    for (var prop in holderSumamt) {
      objSumstmtamt.push({ collid: prop, sumstmtamt: holderSumstmtamt[prop] });
    }

    const checkSumstmtamt = (collid: string) => {
      const found = objSumstmtamt.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumstmtamt;
      } else {
        return "0";
      }
    };

    const groupByList = (listNew: any) =>
      Object.values(
        listNew.reduce((data: any, { listname, collid, collname, cnt, sumamt, sumcurbal, sumstmtamt, sumos }: any) => {
          let ac: any;
          let os: any;
          let bal: any;
          let cur: any;
          let stmt: any;
          if (data[collid]) {
            if (
              !data[collid].listList.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let oaDetails = {
                listname: listname,
                collname: collname,
                cnt: cnt,
                sumamt: sumamt,
                sumcurbal: sumcurbal,
                sumstmtamt: sumstmtamt,
                sumos: sumos,
              };
              data[collid].listList.push(oaDetails);
            } else {
              data[collid].listList.find((listcheck: any, index: any) => {
                if (listcheck.listname == listname) {
                  listcheck.cnt = listcheck.cnt + cnt;
                  listcheck.sumamt = listcheck.sumamt + sumamt;
                  listcheck.sumcurbal = listcheck.sumcurbal + sumcurbal;
                  listcheck.sumstmtamt = listcheck.sumstmtamt + sumstmtamt;
                  listcheck.sumos = listcheck.sumos + sumos;
                }
              });
            }
          } else {
            let oaDetails = {
              listname: listname,
              collname: collname,
              cnt: cnt,
              sumamt: sumamt,
              sumcurbal: sumcurbal,
              sumstmtamt: sumstmtamt,
              sumos: sumos,
            };
            ac = checkCnt(String(collid));
            os = checkSumos(String(collid));
            bal = checkSumamt(String(collid));
            cur = checkSumcurbal(String(collid));
            stmt = checkSumstmtamt(String(collid));
            data[collid] = {
              collid,
              collname,
              ac,
              os,
              bal,
              cur,
              stmt,
              listList: [oaDetails],
            };
          }
          return data;
        }, {}),
      );

    const newObject = {
      statusName: "action",
      collList: groupByList(listNew),
    };
    dataSource.push(newObject);
    return dataSource;
  }

  function integrateDashboardNoAction(data: any) {
    let listNew: any = [];
    let collList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionstatus === "noAction") {
        let list = {
          listname: x1.listname,
          collid: x1.collid,
          collname: x1.collname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
        };
        listNew.push(list);

        let team = {
          collid: x1.collid,
          collname: x1.collname,
          listname: x1.listname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
        };
        collList.push(team);
      }
    });
    var holderCnt: any = {};
    listNew.forEach(function (d: any) {
      if (holderCnt.hasOwnProperty(d.collid)) {
        holderCnt[d.collid] = holderCnt[d.collid] + d.cnt;
      } else {
        holderCnt[d.collid] = d.cnt;
      }
    });
    var objCnt: any = [];
    for (var prop in holderCnt) {
      objCnt.push({ collid: prop, cnt: holderCnt[prop] });
    }

    const checkCnt = (collid: string) => {
      const found = objCnt.find((a: any) => a.collid === collid);

      if (found) {
        return found.cnt;
      } else {
        return "0";
      }
    };

    var holderSumamt: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumamt.hasOwnProperty(d.collid)) {
        holderSumamt[d.collid] = holderSumamt[d.collid] + d.sumamt;
      } else {
        holderSumamt[d.collid] = d.sumamt;
      }
    });
    var objSumamt: any = [];
    for (var prop in holderSumamt) {
      objSumamt.push({ collid: prop, sumamt: holderSumamt[prop] });
    }

    const checkSumamt = (collid: string) => {
      const found = objSumamt.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumamt;
      } else {
        return "0";
      }
    };
    var holderSumos: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumos.hasOwnProperty(d.collid)) {
        holderSumos[d.collid] = holderSumos[d.collid] + d.sumos;
      } else {
        holderSumos[d.collid] = d.sumos;
      }
    });
    var objSumos: any = [];
    for (var prop in holderSumos) {
      objSumos.push({ collid: prop, sumos: holderSumos[prop] });
    }

    const checkSumos = (collid: string) => {
      const found = objSumos.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumos;
      } else {
        return "0";
      }
    };

    var holderSumcurbal: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumcurbal.hasOwnProperty(d.collid)) {
        holderSumcurbal[d.collid] = holderSumcurbal[d.collid] + d.sumcurbal;
      } else {
        holderSumcurbal[d.collid] = d.sumcurbal;
      }
    });
    var objSumcurbal: any = [];
    for (var prop in holderSumamt) {
      objSumcurbal.push({ collid: prop, sumcurbal: holderSumcurbal[prop] });
    }

    const checkSumcurbal = (collid: string) => {
      const found = objSumcurbal.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumcurbal;
      } else {
        return "0";
      }
    };

    var holderSumstmtamt: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumstmtamt.hasOwnProperty(d.collid)) {
        holderSumstmtamt[d.collid] = holderSumstmtamt[d.collid] + d.sumstmtamt;
      } else {
        holderSumstmtamt[d.collid] = d.sumstmtamt;
      }
    });
    var objSumstmtamt: any = [];
    for (var prop in holderSumamt) {
      objSumstmtamt.push({ collid: prop, sumstmtamt: holderSumstmtamt[prop] });
    }

    const checkSumstmtamt = (collid: string) => {
      const found = objSumstmtamt.find((a: any) => a.collid === collid);

      if (found) {
        return found.sumstmtamt;
      } else {
        return "0";
      }
    };

    const groupByList = (listNew: any) =>
      Object.values(
        listNew.reduce((data: any, { listname, collid, collname, cnt, sumamt, sumcurbal, sumstmtamt, sumos }: any) => {
          let ac: any;
          let os: any;
          let bal: any;
          let cur: any;
          let stmt: any;
          if (data[collid]) {
            if (
              !data[collid].listList.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let oaDetails = {
                listname: listname,
                collname: collname,
                cnt: cnt,
                sumamt: sumamt,
                sumcurbal: sumcurbal,
                sumstmtamt: sumstmtamt,
                sumos: sumos,
              };
              data[collid].listList.push(oaDetails);
            } else {
              data[collid].listList.find((listcheck: any, index: any) => {
                if (listcheck.listname == listname) {
                  listcheck.cnt = listcheck.cnt + cnt;
                  listcheck.sumamt = listcheck.sumamt + sumamt;
                  listcheck.sumcurbal = listcheck.sumcurbal + sumcurbal;
                  listcheck.sumstmtamt = listcheck.sumstmtamt + sumstmtamt;
                  listcheck.sumos = listcheck.sumos + sumos;
                }
              });
            }
          } else {
            let oaDetails = {
              listname: listname,
              collname: collname,
              cnt: cnt,
              sumamt: sumamt,
              sumcurbal: sumcurbal,
              sumstmtamt: sumstmtamt,
              sumos: sumos,
            };
            ac = checkCnt(String(collid));
            os = checkSumos(String(collid));
            bal = checkSumamt(String(collid));
            cur = checkSumcurbal(String(collid));
            stmt = checkSumstmtamt(String(collid));
            data[collid] = {
              collid,
              collname,
              ac,
              os,
              bal,
              cur,
              stmt,
              listList: [oaDetails],
            };
          }
          return data;
        }, {}),
      );

    const newObject = {
      statusName: "noAction",
      collList: groupByList(listNew),
    };
    dataSource.push(newObject);
    return dataSource;
  }

  const toDebt = (status: string, supName: string, supId: string, list: string, collId: string, collName: string) => {
    if (status) {
      let all = {
        type: "SUPOA",
        statusName: status,
        supName: "",
        supId: "",
        listName: list,
        collName: collName,
        collId: collId,
      };
      history.push("/debtmanagement", all);
    }
  };

  useEffect(() => {
    async function fetchDataForSearch() {
      setLoading(true);
      const apiCaller = id24Axios(appConfig.apiBaseUrlDebt);
      const res: any = await DashboardService(apiCaller).getOaManager();

      let conDataNew: any = await integrateDashboardNew(res.result);
      let conDataAction: any = await integrateDashboardAction(res.result);
      let conDataNoAction: any = await integrateDashboardNoAction(res.result);

      await setDataSourceNew(conDataNew);
      await setDataSourceAction(conDataAction);
      await setDataSourceNoAction(conDataNoAction);
      setLoading(false);
    }
    fetchDataForSearch();
  }, []);

  const [evenExtra, setEvenextra] = useState<boolean>(true);
  const genExtra = () => (
    <>
      {evenExtra === false ? (
        <DownCircleOutlined
          style={{
            fontSize: "20px",
            color: "#1890FF",
            backgroundColor: "#E6F7FF",
            borderRadius: "100%",
          }}
          onClick={(event) => {
            setEvenextra(true);
          }}
        />
      ) : (
        <RightCircleOutlined
          style={{
            fontSize: "20px",
            color: "#1890FF",
            backgroundColor: "#E6F7FF",
            borderRadius: "100%",
          }}
          onClick={(event) => {
            setEvenextra(false);
          }}
        />
      )}
    </>
  );
  return loading ? (
    <>
      <div style={{ justifyContent: "center", display: "flex", alignItems: "center", minHeight: "30vh" }}>
        <Spin spinning={loading} tip="Loading" indicator={antIcon} />
      </div>
    </>
  ) : (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Col span={8}>
          <Row>
            {dataSourceNew.map((newstatus: any, index: any) => {
              if (newstatus.statusName === "new") {
                return (
                  <Col span={24} key={index}>
                    <Card
                      style={{
                        backgroundColor: "#0056B4",
                        borderRadius: "5px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <b style={fontStyleHerder}>New</b>
                      {"   "}
                      <Tag style={fontStyleTap} color="#1890FF">
                        <NumberFormat
                          value={newstatus.collList.length}
                          displayType={"text"}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Tag>
                    </Card>

                    {newstatus.collList.map((collector: any, indexTeam: any) => {
                      return (
                        <Col>
                          <Collapse
                            expandIconPosition="end"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#FFFFFF",
                            }}
                            bordered={false}
                            ghost
                          >
                            <Panel
                              showArrow={true}
                              header={
                                <Col span={24}>
                                  <Row gutter={[0, 24]}>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={{ textAlign: "left" }}>
                                      <a
                                        onClick={() => toDebt("new", "", "", "", collector.collid, collector.collname)}
                                        style={{
                                          fontSize: "18px",
                                          color: "#108ee9",
                                        }}
                                      >
                                        <b>{collector.collname}</b>
                                      </a>{" "}
                                      <Tag
                                        style={{
                                          fontSize: "16px",
                                          borderRadius: "10px",
                                        }}
                                        color="#108ee9"
                                      >
                                        <NumberFormat
                                          value={collector.listList.length}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Tag>
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col span={4} style={frontStyleCard}>
                                      <Avatar
                                        style={{ backgroundColor: "#E1EDF9" }}
                                        shape="square"
                                        icon={
                                          <UserOutlined
                                            style={{
                                              color: "#035BBD",
                                            }}
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col span={18} style={frontStyleCard}>
                                      A/C{" "}
                                      <NumberFormat
                                        value={collector.ac}
                                        displayType={"text"}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}>
                                      {/* {genExtra()} */}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      BAL{" "}
                                      <NumberFormat
                                        value={collector.bal}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      OS{" "}
                                      <NumberFormat
                                        value={collector.os}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      CUR{" "}
                                      <NumberFormat
                                        value={collector.cur}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      STMT{" "}
                                      <NumberFormat
                                        value={collector.stmt}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                </Col>
                              }
                              key={"1"}
                            >
                              <Collapse>
                                {collector.listList.map((list: any, indexList: any) => {
                                  return (
                                    <Panel
                                      header={
                                        <>
                                          <Col style={frontStyleInCard} span={8}>
                                            <a
                                              style={{ color: "#000000" }}
                                              onClick={() => toDebt("new", "", "", list.listname, collector.collid, collector.collname)}
                                            >
                                              {list.listname}
                                            </a>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            A/C{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.cnt}
                                                displayType={"text"}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            BAL{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumamt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            OS{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumos}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            CUR{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumcurbal}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            STMT{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumstmtamt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                        </>
                                      }
                                      key={indexList}
                                    ></Panel>
                                  );
                                })}
                              </Collapse>
                            </Panel>
                          </Collapse>
                          <br></br>
                        </Col>
                      );
                    })}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {dataSourceAction.map((newstatus: any, index: any) => {
              if (newstatus.statusName === "action") {
                return (
                  <Col span={24} key={index}>
                    <Card
                      style={{
                        backgroundColor: "#70C787",
                        borderRadius: "5px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <b style={fontStyleHerder}>Action</b>
                      {"   "}
                      <Tag style={fontStyleTap} color="#52C41A">
                        <NumberFormat
                          value={newstatus.collList.length}
                          displayType={"text"}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Tag>
                    </Card>

                    {newstatus.collList.map((collector: any, indexTeam: any) => {
                      // console.log(collector);

                      return (
                        <Col>
                          <Collapse
                            expandIconPosition="end"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#FFFFFF",
                            }}
                            bordered={false}
                            ghost
                          >
                            <Panel
                              showArrow={true}
                              header={
                                <Col span={24}>
                                  <Row gutter={[0, 24]}>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={{ textAlign: "left" }}>
                                      <a
                                        onClick={() => toDebt("action", "", "", "", collector.collid, collector.collname)}
                                        style={{
                                          fontSize: "18px",
                                          color: "#52C41A",
                                        }}
                                      >
                                        <b>{collector.collname}</b>
                                      </a>{" "}
                                      <Tag
                                        style={{
                                          fontSize: "16px",
                                          borderRadius: "10px",
                                        }}
                                        color="#52C41A"
                                      >
                                        <NumberFormat
                                          value={collector.listList.length}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Tag>
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col span={4} style={frontStyleCard}>
                                      <Avatar
                                        style={{ backgroundColor: "#DEFFCD" }}
                                        shape="square"
                                        icon={
                                          <UserOutlined
                                            style={{
                                              color: "#52C41A",
                                            }}
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col span={18} style={frontStyleCard}>
                                      A/C{" "}
                                      <NumberFormat
                                        value={collector.ac}
                                        displayType={"text"}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}>
                                      {/* {genExtra()} */}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      BAL{" "}
                                      <NumberFormat
                                        value={collector.bal}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      OS{" "}
                                      <NumberFormat
                                        value={collector.os}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      CUR{" "}
                                      <NumberFormat
                                        value={collector.cur}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      STMT{" "}
                                      <NumberFormat
                                        value={collector.stmt}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                </Col>
                              }
                              key="1"
                            >
                              <Collapse>
                                {collector.listList.map((list: any, indexList: any) => {
                                  return (
                                    <Panel
                                      header={
                                        <>
                                          <Col style={frontStyleInCard} span={8}>
                                            <a
                                              style={{ color: "#000000" }}
                                              onClick={() => toDebt("action", "", "", list.listname, collector.collid, collector.collname)}
                                            >
                                              {list.listname}
                                            </a>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            A/C{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.cnt}
                                                displayType={"text"}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            BAL{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumamt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            OS{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumos}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>{" "}
                                          <Col style={frontStyleInCard} span={8}>
                                            CUR{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumcurbal}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>{" "}
                                          <Col style={frontStyleInCard} span={8}>
                                            STMT{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumstmtamt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                        </>
                                      }
                                      key={indexList}
                                    ></Panel>
                                  );
                                })}
                              </Collapse>
                            </Panel>
                          </Collapse>
                          <br></br>
                        </Col>
                      );
                    })}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {dataSourceNoAction.map((newstatus: any, index: any) => {
              if (newstatus.statusName === "noAction") {
                return (
                  <Col span={24} key={index}>
                    <Card
                      style={{
                        backgroundColor: "#EE5F77",
                        borderRadius: "5px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <b style={fontStyleHerder}>No-Action</b>
                      {"   "}
                      <Tag style={fontStyleTap} color="#FF4D4F">
                        <NumberFormat
                          value={newstatus.collList.length}
                          displayType={"text"}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Tag>
                    </Card>

                    {newstatus.collList.map((collector: any, indexTeam: any) => {
                      return (
                        <Col>
                          <Collapse
                            expandIconPosition="end"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#FFFFFF",
                            }}
                            bordered={false}
                            ghost
                          >
                            <Panel
                              showArrow={true}
                              header={
                                <Col span={24}>
                                  <Row gutter={[0, 24]}>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={{ textAlign: "left" }}>
                                      <a
                                        onClick={() => toDebt("noAction", "", "", "", collector.collid, collector.collname)}
                                        style={{
                                          fontSize: "18px",
                                          color: "#FF4D4F",
                                        }}
                                      >
                                        <b>{collector.collname}</b>
                                      </a>{" "}
                                      <Tag
                                        style={{
                                          fontSize: "16px",
                                          borderRadius: "10px",
                                        }}
                                        color="#FF4D4F"
                                      >
                                        <NumberFormat
                                          value={collector.listList.length}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Tag>
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col span={4} style={frontStyleCard}>
                                      <Avatar
                                        style={{ backgroundColor: "#e98b9b" }}
                                        shape="square"
                                        icon={
                                          <UserOutlined
                                            style={{
                                              color: "#FF4D4F",
                                            }}
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col span={18} style={frontStyleCard}>
                                      A/C{" "}
                                      <NumberFormat
                                        value={collector.ac}
                                        displayType={"text"}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}>
                                      {/* {genExtra()} */}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      BAL{" "}
                                      <NumberFormat
                                        value={collector.bal}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      OS{" "}
                                      <NumberFormat
                                        value={collector.os}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      CUR{" "}
                                      <NumberFormat
                                        value={collector.cur}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      STMT{" "}
                                      <NumberFormat
                                        value={collector.stmt}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                </Col>
                              }
                              key="1"
                            >
                              <Collapse>
                                {collector.listList.map((list: any, indexList: any) => {
                                  return (
                                    <Panel
                                      header={
                                        <>
                                          <Col style={frontStyleInCard} span={8}>
                                            <a
                                              style={{ color: "#000000" }}
                                              onClick={() => toDebt("noAction", "", "", list.listname, collector.collid, collector.collname)}
                                            >
                                              {list.listname}
                                            </a>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            A/C{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.cnt}
                                                displayType={"text"}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            BAL{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumamt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            OS{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumos}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            CUR{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumcurbal}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            STMT{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.sumstmtamt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                        </>
                                      }
                                      key={indexList}
                                    ></Panel>
                                  );
                                })}
                              </Collapse>
                            </Panel>
                          </Collapse>
                          <br></br>
                        </Col>
                      );
                    })}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
};
