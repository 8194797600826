import React, { useEffect, useState } from "react";
// import { useRouteMatch, useHistory } from "react-router-dom";
import { Card, Row, Col, Button, Spin, Table, message, TablePaginationConfig, Modal, Form, Select, Collapse } from "antd";
import { DatamanagementService } from "../../services/datamanagement-service";
import { EditFilled, CheckCircleOutlined, PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { loadAppConfig } from "../../../../config/app-config";
const appConfig = loadAppConfig();
import { useId24 } from "../../../../drivers/id24/Id24-provider";
import { IDataUnlist } from "../../common";
import { numberWithCommasNodigit } from "../../../../utils";

export interface FilterTable {
  take: number;
  skip: number;
}
interface IUnlistDataTable {
  accountLevel: string;
  accountNo: string;
  accountStatus: string;
  cif: string;
  custName: string;
  grantTotal: string;
  key: number;
  loantype: string;
  productDescription: string;
}

export interface Props {
  baseUrl: string;
  accessRole: any;
}

export const UnlistPage: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlCore = id24Axios(appConfig.apiBaseUrl);
  const [loading, setLoading] = useState<boolean>(true);
  const [statusTable, setStatusTable] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<IUnlistDataTable[]>([]);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [seconds, setSeconds] = useState(0);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [panelAction, setPanelAction] = useState("1");
  const [panelShow, setPanelShow] = useState(true);

  const { Panel } = Collapse;

  useEffect(() => {
    getDataCount();
    getDataTable({ take: 10, skip: 0 });
  }, []);
  const genExtra = () => (
    <>
      {panelShow === false ? (
        <PlusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(true);
            setPanelAction("1");
          }}
        />
      ) : (
        <MinusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(false);
            setPanelAction("0");
          }}
        />
      )}
    </>
  );
  const columns: any = [
    {
      title: "No.",
      width: "6%",
      dataIndex: "key",
      fixed: "left",
      // render: (event: string) => (event),
    },
    {
      title: "CIF",
      dataIndex: "cif",
      key: "cif",
      width: "10%",
      fixed: "left",
    },
    {
      title: "ชื่อลูกหนี้",
      dataIndex: "custName",
      key: "custName",
      width: "18%",
      fixed: "left",
    },
    {
      title: "Loan Type",
      dataIndex: "loantype",
      key: "loantype",
      // width: "10%",
    },
    {
      title: "Account Status",
      dataIndex: "accountStatus",
      key: "accountStatus",
      // width: "10%",
    },
    {
      title: "รายละเอียดผลิตภัณฑ์",
      dataIndex: "productDescription",
      key: "productDescription",
      // width: "25%",
    },
    {
      title: "Account Level",
      dataIndex: "accountLevel",
      key: "accountLevel",
      // width: "10%",
    },

    {
      title: "Account No.",
      dataIndex: "accountNo",
      key: "accountNo",
      // width: "15%",
    },

    {
      title: "ภาระหนี้ทั้งหมด",
      dataIndex: "grantTotal",
      key: "grantTotal",
      // width: "10%",
      align: "right" as const,
      render: (text: string, record: any, index: number) => (
        <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={""} />
      ),
    },
  ];

  const getDataCount = async () => {
    const resultCountUnlist = await DatamanagementService(apiBaseUrlDebt).getCountUnList();
    setPagination({
      total: resultCountUnlist[0]?._count?.UserId,
    });
  };

  const getDataTable = async (filter: FilterTable) => {
    const resualDataUnlist = await DatamanagementService(apiBaseUrlDebt).getUnlistList(filter);
    setLoading(false);
    setStatusTable(false);
    setDataSource(
      resualDataUnlist?.map((item: IDataUnlist, index: number) => {
        return {
          key: index + filter?.skip + 1,
          cif: item?.cif,
          accountNo: item?.accountNo,
          loantype: item?.channel,
          productDescription:
            item.channel === "LOAN"
              ? item?.detail?.productDesc + " (" + item?.detail?.productCode + ")"
              : item?.channel === "Card Link"
              ? item?.detail?.product_desc + " (" + item?.detail?.product_code + ")"
              : "-",
          grantTotal: item?.overdueAmt,
          custName: item?.Cus?.cusTitle + item?.Cus?.custName + " " + item?.Cus?.custLastName,
          accountStatus: item?.accountStatus,
          accountLevel: item?.accountLevel,
        };
      })
    );
  };

  const onClickExportcsvUnlist = async (e: any) => {
    const condition = {
      loanType: e.loanType,
      accountStatus: e.accountStatus,
      take: Number(pagination.total),
      skip: 0,
    };
    await DatamanagementService(apiBaseUrlCore)
      .exportcsvUnlist(condition)
      .then((resultUnlist: any) => {
        setLoadingButton(true);
        setSeconds(10);
        resultUnlist.code !== 500
          ? Modal.confirm({
              title: "สำเร็จ",
              icon: <CheckCircleOutlined />,
              content: `ท่านสามารถกดดาวน์โหลดไฟล์ได้ที่หน้า Task import&export ที่ Id File ${resultUnlist.id}`,
              okText: "ยืนยัน",
              cancelText: "ยกเลิก",
              onOk: () => {
                window.location.href = `${window.location.origin}/debt/taskimport`;
              },
              onCancel: () => {
                setLoadingButton(false);
              },
            })
          : message.error(`ไม่สำเร็จ`);
      })
      .catch((err) => alert("Pless check service !!"));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setLoadingButton(false);
        clearInterval(timer);
      }
    }, 1000);
    console.log(seconds);

    return () => clearInterval(timer);
  }, [seconds]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    setStatusTable(true);
    let skipData;
    let takeData;
    if (newPagination.current && newPagination.pageSize) {
      skipData = (newPagination.current - 1) * newPagination.pageSize;
      takeData = newPagination.pageSize;
    } else {
      skipData = 0;
      takeData = 10;
    }
    getDataTable({ take: takeData, skip: skipData });
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Row style={{ marginTop: "2rem" }}>
        <Col span={24}>
          <Collapse style={{ marginBottom: "10px" }} expandIconPosition="end" collapsible={"header"} activeKey={[panelAction]}>
            <Panel
              extra={genExtra()}
              forceRender={true}
              showArrow={false}
              header={
                <div style={{ justifyContent: "end", display: "flex" }}>
                  <span>{`กำหนดเงื่อนไขในการ Export ข้อมูล`}</span>
                </div>
              }
              key="1"
              style={{
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Form
                name="customized_form_controls"
                layout="inline"
                onFinish={onClickExportcsvUnlist}
                style={{ justifyContent: "start", display: "flex", alignItems: "center" }}
              >
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col style={{ justifyContent: "start", display: "flex" }}>
                    <Form.Item name="loanType" label="Loan Type" rules={[{ required: true, message: "กรุณาเลือก Loan Type" }]}>
                      <Select
                        style={{ width: "250px" }}
                        placeholder="Please Select"
                        options={[
                          { value: "LOAN", label: "LOAN" },
                          { value: "Card Link", label: "Card Link" },
                          { value: "OD", label: "OD" },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item name="accountStatus" label="Account Status" rules={[{ required: true, message: "กรุณาเลือก Account Status" }]}>
                      <Select
                        style={{ width: "250px" }}
                        placeholder="Please Select"
                        showArrow
                        mode="tags"
                        options={[
                          { value: "Non-Npls", label: "Non-Npls" },
                          { value: "Npls", label: "Npls" },
                          { value: "W/O", label: "W/O" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ justifyContent: "start", display: "flex" }}>
                    <Form.Item>
                      <Button
                        size="middle"
                        type="primary"
                        htmlType="submit"
                        disabled={pagination?.total === 0 || pagination?.total === undefined}
                        loading={loadingButton}
                        danger
                      >
                        <EditFilled /> {`Export CSV`}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} style={{ paddingLeft: "0px" }}>
          <Card title="Unlists" style={{ width: "100%", textAlign: "left" }}>
            {/* <Button
                  type="primary"
                  onClick={onClickExportcsvUnlist}
                  disabled={pagination?.total === 0 || pagination?.total === undefined}
                  loading={loadingButton}
                  danger
                >
                  <EditFilled /> {`Export CSV`}
                </Button> */}
            <Table
              size="small"
              columns={columns}
              dataSource={dataSource}
              pagination={pagination}
              onChange={handleTableChange}
              scroll={{ x: "calc(500px + 100%)" }}
              loading={statusTable}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
