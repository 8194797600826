import axios, { AxiosInstance } from "axios";
import { user } from "../common";

export interface ITeamService {
  getTeamList: () => Promise<any>;
  updateTeamById: (data: any) => Promise<string>;
  createTeam: (data: any) => Promise<string>;
  getOaList: (data: any) => Promise<string>;
  getDetailUser: () => Promise<any>;
  getUserList: () => Promise<user[]>;
  findoauser: () => Promise<any>;
}

export const TeamService = (serviceBackendApi: any): ITeamService => ({
  getTeamList: async () => {
    // serviceBackend
    const result = await serviceBackendApi.get("/dist/all");
    return result?.data || {};
  },

  updateTeamById: async (data: any) => {
    // serviceBackend
    const result = await serviceBackendApi.put("/dist/" + data.id, data);
    return result?.data || {};
  },

  createTeam: async (data: any) => {
    // serviceBackend
    const result = await serviceBackendApi.post("/dist/", data);
    return result?.data || {};
  },

  getOaList: async (data: any) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/search/oa", {
      condition: {},
    });
    return result?.data || {};
  },

  getDetailUser: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userDetails");
    return result?.data || {};
  },

  getUserList: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userList");
    return result?.data?.result || {};
  },

  findoauser: async () => {
    const result = await serviceBackendApi.post("/search/oaCollector");
    return result?.data?.result || {};
  },
});
