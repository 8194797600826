import { Card, Row, Col, Input, message, Tabs } from "antd";
import React, { useState } from "react";
// import { Icon } from "@iconify/react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { FilterBatchProcessFile } from "./components/FilterBatchProcessFile";
import { TableBatchProcessFile } from "./components/TableBatchProcessFile";
import { InprogressStatusProcessFile } from "./components/InprogressStatusProcessFile";
import { IFilter } from "./services/service";
import { ProfileFilled, FolderOutlined } from "@ant-design/icons";
import { TableBatchProcessLog } from "./components/TableBatchProcessLog";
import { useLocation } from "react-router-dom";

const { TextArea } = Input;

export interface Props {
  userId: string;
  accessRole: any;
}

export const BatchProcessFile: React.FC<Props> = ({ accessRole, userId }) => {
  const [fillterData, setFillterData] = useState<IFilter>({
    type: "",
    text: "",
  });

  const [reloadTable, setReloadTable] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const chkReload = (text: string) => {
    if (text === "1") {
      setReloadTable(reloadTable + 1);
    }
  };
  const filter = (type: string, text: string) => {
    if (type === "" || type === undefined) {
      message.warning("Please select type");
    } else {
      setFillterData({ type: type, text: text });
    }
  };

  const handleLoadingTable = (load: boolean) => {
    setTableLoading(load);
  };

  const onChangeTab = () => {
    setTableLoading(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          {/* {tabResult} */}
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            {" "}
            <FolderOutlined style={{ width: 36, height: 36, color: "#0056B4" }} />
            File Process
          </b>
        </Card>
      </Row>

      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Switch>
            <Route path={`/batchProcessFile/batchProfilelog/:id`}>
              <TableBatchProcessLog />
            </Route>
            <Route path={`/batchProcessFile`}>
              <Card>
                <FilterBatchProcessFile
                  accessRole={accessRole}
                  userId={"userId"}
                  filter={filter}
                  chkReload={chkReload}
                  handleLoadingTable={handleLoadingTable}
                />
                <Tabs defaultActiveKey="1" onChange={onChangeTab}>
                  <Tabs.TabPane tab="History Status" key="1">
                    <TableBatchProcessFile
                      accessRole={accessRole}
                      userId={"userId"}
                      fillterData={fillterData}
                      reloadTable={reloadTable}
                      loading={tableLoading}
                      handleLoadingTable={handleLoadingTable}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="In Progress Status" key="2">
                    <InprogressStatusProcessFile
                      userId={"userId"}
                      fillterData={fillterData}
                      reloadTable={reloadTable}
                      loading={tableLoading}
                      handleLoadingTable={handleLoadingTable}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Card>
            </Route>
          </Switch>
        </Col>
      </Row>
    </React.Fragment>
  );
};
