import React, { useState, useEffect } from "react";
import { Card, Row, Col, Avatar, Collapse, Tag, Spin } from "antd";
import { DownCircleOutlined, LoadingOutlined, RightCircleOutlined, WarningFilled } from "@ant-design/icons";
import { DashboardService } from "./services/noti-dashboard-service";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();
interface Props {
  baseUrl: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const frontStyleCard = {
  textAlign: "left",
  fontSize: "12px",
  marginTop: "5px",
} as any;

const frontStyleInCard = {
  textAlign: "left",
  fontSize: "10px",
} as any;

export const DashboardManagerBank: React.FC<Props> = ({ baseUrl }) => {
  const history = useHistory();
  const { Panel } = Collapse;
  const { id24Axios } = useId24();
  const apiBaseUrlDebt: any = id24Axios(appConfig.apiBaseUrlDebt);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSourceNew, setDataSourceNew] = useState([]);

  useEffect(() => {
    featchData();
  }, []);

  const featchData = async () => {
    setLoading(true);
    const resultBankManager = await DashboardService(apiBaseUrlDebt).getBankManager();
    const conDataNew: any = await integrateDashboardNew(resultBankManager);
    setDataSourceNew(conDataNew[0].conditionList);
    setLoading(false);
  };

  const toDebt = (type: string, conditionid: string) => {
    if (type) {
      let all = {
        type: type,
        conditionid: conditionid,
      };
      history.push("/notification-list", all);
    }
  };

  const integrateDashboardNew = async (data: any) => {
    let listNew: any = [];
    let condiftionList: any = [];
    let dataSource = [];
    await data?.map((x1: any) => {
      let list = {
        oaname: x1.oaname,
        conditionid: x1.conditionid,
        title: x1.title,
        ac: Number(x1.ac),
        os: Number(x1.os),
      };
      listNew.push(list);

      let team = {
        conditionid: x1.conditionid,
        title: x1.title,
        oaname: x1.oaname,
        ac: Number(x1.ac),
        os: Number(x1.os),
      };
      condiftionList.push(team);
      // }
    });
    var holderCnt: any = {};
    listNew.forEach(function (d: any) {
      if (holderCnt.hasOwnProperty(d.conditionid)) {
        holderCnt[d.conditionid] = holderCnt[d.conditionid] + d.ac;
      } else {
        holderCnt[d.conditionid] = d.ac;
      }
    });
    var objCnt: any = [];
    for (var prop in holderCnt) {
      objCnt.push({ conditionid: prop, ac: holderCnt[prop] });
    }

    const checkCnt = (conditionid: string) => {
      const found = objCnt.find((a: any) => a.conditionid === conditionid);

      if (found) {
        return found.ac;
      } else {
        return "0";
      }
    };

    var holderSumamt: any = {};
    listNew.forEach(function (d: any) {
      if (holderSumamt.hasOwnProperty(d.conditionid)) {
        holderSumamt[d.conditionid] = holderSumamt[d.conditionid] + d.os;
      } else {
        holderSumamt[d.conditionid] = d.os;
      }
    });
    var objSumamt: any = [];
    for (var prop in holderSumamt) {
      objSumamt.push({ conditionid: prop, os: holderSumamt[prop] });
    }

    const checkSumamt = (conditionid: string) => {
      const found = objSumamt.find((a: any) => a.conditionid === conditionid);

      if (found) {
        return found.os;
      } else {
        return "0";
      }
    };

    const groupByList = (listNew: any) =>
      Object.values(
        listNew.reduce((data: any, { oaname, conditionid, title, ac, os }: any) => {
          // let ac :any;
          // let os :any;
          if (data[conditionid]) {
            if (
              !data[conditionid].listList.find((listcheck: any) => {
                return listcheck.oaname === oaname;
              })
            ) {
              let oaDetails = {
                oaname: oaname,
                title: title,
                ac: ac,
                os: os,
              };
              data[conditionid].listList.push(oaDetails);
            } else {
              data[conditionid].listList.find((listcheck: any, index: any) => {
                if (listcheck.oaname == oaname) {
                  listcheck.ac = listcheck.ac + ac;
                  listcheck.os = listcheck.os + os;
                }
              });
            }
          } else {
            let oaDetails = {
              oaname: oaname,
              title: title,
              ac: ac,
              os: os,
            };
            ac = checkCnt(String(conditionid));
            os = checkSumamt(String(conditionid));
            data[conditionid] = {
              conditionid,
              title,
              ac,
              os,
              listList: [oaDetails],
            };
          }
          return data;
        }, {})
      );

    const newObject = {
      statusName: "new",
      conditionList: groupByList(listNew),
    };
    dataSource.push(newObject);
    return dataSource;
  };

  return loading ? (
    <>
      <div style={{ justifyContent: "center", display: "flex", alignItems: "center", minHeight: "50vh" }}>
        <Spin spinning={loading} tip="Loading" indicator={antIcon} />
      </div>
    </>
  ) : (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Col span={24}>
          <Row gutter={16}>
            {dataSourceNew ? (
              dataSourceNew.map((condition: any, index: any) => {
                return (
                  <Col span={12}>
                    <Collapse
                      defaultActiveKey={["0"]}
                      expandIconPosition="end"
                      style={{
                        borderRadius: "7px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        minHeight: "90%",
                      }}
                      bordered={false}
                      ghost
                    >
                      <Panel
                        showArrow={true}
                        header={
                          <Row gutter={16}>
                            <Col
                              span={2}
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                marginTop: "20px",
                              }}
                            >
                              <Avatar
                                style={{
                                  backgroundColor: "rgb(249, 243, 225)",
                                  borderRadius: "50px",
                                }}
                                shape="square"
                                icon={
                                  <WarningFilled
                                    style={{
                                      color: "#FF9500",
                                    }}
                                  />
                                }
                              />
                            </Col>
                            <Col span={22}>
                              <Row>
                                <Col span={24} style={{ textAlign: "left" }}>
                                  <a
                                    onClick={() => toDebt("BANK", condition.conditionid)}
                                    style={{
                                      fontSize: "15px",
                                      color: "#FF9500",
                                    }}
                                  >
                                    <b>{condition.title + " "}</b>
                                  </a>
                                  <Tag
                                    style={{
                                      fontSize: "15px",
                                      borderRadius: "10px",
                                    }}
                                    color="#FF9500"
                                  >
                                    <NumberFormat
                                      value={condition.ac === 0 ? 0 : condition.listList.length}
                                      displayType={"text"}
                                      decimalScale={0}
                                      fixedDecimalScale={true}
                                      thousandSeparator={true}
                                    />
                                  </Tag>
                                </Col>
                                <Col span={24} style={frontStyleCard}>
                                  A/C{" "}
                                  <NumberFormat
                                    value={condition.ac}
                                    displayType={"text"}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                  />
                                </Col>
                                <Col span={24} style={frontStyleCard}>
                                  BAL{" "}
                                  <NumberFormat
                                    value={condition.os}
                                    displayType={"text"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        }
                        key="1"
                      >
                        <Collapse defaultActiveKey={["0"]}>
                          <Panel
                            header={
                              <>
                                <Col style={frontStyleInCard} span={24}>
                                  <a style={{ color: "black" }}>Detail</a>
                                </Col>
                              </>
                            }
                            key={1}
                          >
                            {condition.listList.map((list: any, indexList: any) => {
                              if (list.os === 0 && list.ac === 0) {
                                return <div style={{ textAlign: "left" }}>{"-"}</div>;
                              } else {
                                return (
                                  <Row style={frontStyleInCard} key={index}>
                                    <Col span={10} offset={1}>
                                      <a style={{ color: "#000000" }} onClick={() => toDebt("BANK", condition.conditionid)}>
                                        {list.oaname}
                                      </a>
                                    </Col>
                                    <Col span={4} offset={2}>
                                      A/C{" "}
                                      <b
                                        style={{
                                          color: "#0056B4",
                                        }}
                                      >
                                        <NumberFormat
                                          value={list.ac}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </b>
                                    </Col>
                                    <Col span={4} offset={2}>
                                      BAL{" "}
                                      <b
                                        style={{
                                          color: "#0056B4",
                                        }}
                                      >
                                        <NumberFormat
                                          value={list.os}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </b>
                                    </Col>
                                  </Row>
                                );
                              }
                            })}
                          </Panel>
                        </Collapse>
                      </Panel>
                    </Collapse>
                    <br></br>
                  </Col>
                );
              })
            ) : (
              <></>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
