import React, { useEffect, useState } from "react";
import { Avatar, Col, Dropdown, Image, Layout, Menu, Modal, Row, Space, Select, Spin } from "antd";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import {
  CaretDownOutlined,
  UserOutlined,
  FileDoneOutlined,
  SnippetsFilled,
  SettingFilled,
  TeamOutlined,
  DashboardFilled,
  DatabaseFilled,
  EditFilled,
  BellFilled,
  MailOutlined,
  UserSwitchOutlined,
  BankOutlined,
  ApartmentOutlined,
  TagsOutlined,
  SolutionOutlined,
  FolderOutlined,
  ProfileOutlined,
  WifiOutlined,
  FieldTimeOutlined,
  BellOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import "./styles/space.css";
import { UserRole } from "../common-auth";
import { MainMenu, MainMenuItem } from "./components/MainMenu";
import { MainRoute, MenuItem } from "./MainRoute";
import logo from "./images/ddebt_logo.770c74362dd43d7f83df.png";
import { OverviewDashboard } from "../screens/dashboard/OverviewDashboard";
import { OverviewNotiDashboard } from "../screens/notificationDashboard/OverviewNotiDashboard";
import { loadAppConfig } from "../config/app-config";
import { OverviewSettingModal } from "./components/OverviewSettingModal";
import { DataManagementRoute } from "../screens/datamanagement/DataManagementRoute";
import { LeadManagementRoute } from "../screens/leadmanagement/LeadManagementRoute";
import { DebtmanagementImport } from "../screens/datamanagementImport/DatamanagementImport";
import { TeamManagement } from "../screens/teamManagement/TeamManagement";
import { OaProfile } from "../screens/oaProfile/OaProfile";
import { DebtManagement } from "../screens/debtManagement/DebtManagement";
import { TeamDebtManagement } from "../screens/teamdebtmanagement/TeamDebtManagement";
import { useId24 } from "../drivers/id24/Id24-provider";
import { DataManageMentRouteOa } from "../screens/datamanagementoa/DataManageMentRouteOa";
import { ImporTrargetInFormationRoute } from "../screens/importmanagement/ImportManagementRoute";
import { OaOrganize } from "../screens/oaOrganize/OaOrganize";
import { BankOrganize } from "../screens/bankOrganize/BankOrganize";
import { OaUser } from "../screens/oaUser/OaUser";
import { BankUser } from "../screens/bankUser/BankUser";
import { BatchProcessJob } from "../screens/batchProcessJob/BatchProcessJob";
import { BatchProcessFile } from "../screens/batchProcessFile/BatchProcessFile";
import { BatchProcessDailyFile } from "../screens/batchProcessDailyFile/BatchProcessDailyFile";
import { SetSessionTimeOut } from "../screens/setSessionTimeOut/SetSessionTimeOut";
import { TimeSetting } from "../screens/timesetting/TimeSetting";
import EmailRoute from "../screens/emailtemplate/EmailRoute";
import { NotificationSetting } from "../screens/NotificationSetting/NotificationSetting";
import { PolicyMain } from "../screens/policy/PolicyMain";
import { NotificationSection } from "notification-widget";
import Box from "./styles/Box";
import { NotificationList } from "../screens/notificationDashboard/Notification";
import { LeadDebtManagementRoute } from "../screens/leaddebtmanagement/LeadDebtManagementRoute";
import { Broadcast } from "../screens/broaadcast/Broadcast";
import { MainTaskImport } from "../screens/debtManagement/taskimport/MainTaskImport";
import { Dphone } from "./d-phone";
import { SetSessionTimeOutService } from "../screens/setSessionTimeOut/service/service";
import { TUserProfile } from "./AuthorizationController";
const appConfig = loadAppConfig();
interface Props {
  dataUser: TUserProfile;
}
const { Header, Sider, Content, Footer } = Layout;
const { Option } = Select;

export const MainLayout: React.FC<Props> = ({ dataUser }): React.ReactElement => {
  const { tokenAccess, login, id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [roles, setRoles] = useState<any>([]);
  const [sub, setSub] = useState<string | any>("");
  const [collapse, setCollapse] = React.useState(false);
  const [isModalVisibleResetPassword, setIsModalVisibleResetPassword] = useState(false);
  const [isModalVisibleProfile, setIsModalVisibleProfile] = useState(false);
  const [isModalVisibleOverviewSetting, setIsModalVisibleOverviewSetting] = useState(false);
  const [resTimeExpride, setResTimeExpride] = useState<number>(0);
  const [profile, setProfile] = React.useState<any>({
    name: "unknown",
    empId: "unknown",
    group: "unknown",
    email: "unknown",
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getUserAccess();
    fetchData().catch(console.error);
  }, []);

  const hasRole = (userRoles: UserRole[]): boolean => {
    const inputRoles = new Set(userRoles);
    const union = new Set(roles.filter((role: any) => inputRoles.has(role)));
    return union.size > 0;
  };

  const checkAccessRole = (role: string) => {
    if (role) {
      let result = roles.indexOf(role) > -1;
      if (result == true) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getUserAccess = async () => {
    const datacon = [] as string[];
    if (tokenAccess)
      tokenAccess.userAccess.map((groupId) => {
        groupId.roles.forEach(function (value, i) {
          datacon.push(value);
        });
      });
    const uniqueNames = datacon.filter((val: any, id: any, array: any) => {
      return array.indexOf(val) == id;
    });
    setRoles(uniqueNames);
  };

  const fetchData = async () => {
    // const profile = await apiBaseUrlDebt.get("/debtor/userDetails");
    // const dataResult = profile?.data?.result;
    const timeExpride = await SetSessionTimeOutService(apiBaseUrlDebt).getTimeout();
    const groupNameList = [] as string[];
    if (tokenAccess) {
      tokenAccess.userAccess.map((group: any) => {
        groupNameList.push(group.groupName);
      });
    }
    setSub(dataUser.token);
    setProfile({
      name: dataUser.name,
      email: dataUser.email,
      empId: dataUser.id,
      group: String(groupNameList),
      oaCode: dataUser.token,
    });
    setResTimeExpride(timeExpride[0].sesstionTime);
    setLoading(false);
  };

  const handleOkResetPassword = () => {
    setIsModalVisibleResetPassword(false);
  };

  const handleCancelResetPassword = () => {
    setIsModalVisibleResetPassword(false);
  };

  const handleOkProfile = () => {
    setIsModalVisibleProfile(false);
  };

  const handleCancelProfile = () => {
    setIsModalVisibleProfile(false);
  };

  const createMainMenuItem = (subMenu: MenuItem): MainMenuItem => ({
    label: subMenu.label,
    icon: subMenu.icon,
    path: subMenu.path,
  });

  const handleClickMenu = (values: any) => {
    let host = window.location.host;
    let baseUrl = host;
    if (host === "localhost:3000") {
      baseUrl = "https://user.ddebtuat.gsb.or.th";
      // baseUrl = "https://id24-demo.osd.co.th";
      window.location.href = baseUrl + "/" + values.value;
    } else {
      window.location.href = "/" + values.value;
    }
  };

  const menuItems: MenuItem[] = [
    {
      label: "",
      icon: <DashboardFilled />,
      path: "/",
      exact: true,
      showInMenu: false,
      target: "/",
      roles: [UserRole.ddebtDashboard],
      component: <Redirect to="/dashboard" />,
    },
    {
      label: "Dashboard",
      icon: <DashboardFilled />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtDashboard],
      component: <OverviewDashboard baseUrl={"/dashboard"} />,
    },
    {
      label: "Team Management",
      icon: <TeamOutlined />,
      path: "/teamManagement",
      showInMenu: true,
      target: "/teamManagement",
      roles: [UserRole.ddebtTeamManagement],
      component: <TeamManagement dataUser={dataUser} userId={"1"} accessRole={roles} />,
    },
    {
      label: "Data Management",
      icon: <DatabaseFilled />,
      path: "/datamanagement",
      showInMenu: true,
      target: "/datamanagement",
      roles: [UserRole.ddebtDataManagement],
      component: <DataManagementRoute baseUrl={"/datamanagement"} accessRole={roles} />,
    },
    {
      label: "Lead Management",
      icon: <EditFilled />,
      path: "/lead_management",
      showInMenu: true,
      target: "/lead_management",
      roles: [UserRole.ddebtLeadManagement],
      component: <LeadManagementRoute accessRole={roles} userprofile={profile} />,
    },
    {
      label: "Notification",
      icon: <BellFilled />,
      path: "/notification-dashboard",
      showInMenu: true,
      target: "/notification-dashboard",
      roles: [UserRole.ddebtNotificationDashboard],
      component: <OverviewNotiDashboard accessRole={roles} baseUrl={"/notification-dashboard"} />,
    },
    {
      label: "Debt Management",
      icon: <SnippetsFilled />,
      path: "/maindebtmanagement",
      showInMenu: true,
      target: "/maindebtmanagement",
      roles: [UserRole.ddebtDebtManagement],
      component: <DebtManagement userId={"1"} baseURL={"/maindebtmanagement"} accessRole={roles} />,
      subMenu: [
        {
          key: "1",
          label: "Debt",
          icon: <SnippetsFilled />,
          path: "/debtmanagement",
          showInMenu: true,
          target: "/debtmanagement",
          roles: [UserRole.ddebtDebtManagementDebt],
          component: <DebtManagement userId={"1"} baseURL={"/debtmanagement"} accessRole={roles} />,
        },
        {
          key: "2",
          label: "Import",
          icon: <FileDoneOutlined />,
          path: "/datamanagementimport",
          showInMenu: true,
          target: "/datamanagementimport",
          roles: [UserRole.ddebtDebtManagementImport],
          component: <DebtmanagementImport />,
        },
        {
          key: "3",
          label: "Team",
          icon: <TeamOutlined />,
          path: "/debtTeammanagement",
          showInMenu: true,
          target: "/debtTeammanagement",
          roles: [UserRole.ddebtDebtManagementTeam],
          component: <TeamDebtManagement userId={"1"} accessRole={roles} />,
        },
        {
          key: "4",
          label: "Data Management",
          icon: <DatabaseFilled />,
          path: "/debtdatamanagement/datamanagement",
          showInMenu: true,
          target: "/debtdatamanagement/datamanagement",
          roles: [UserRole.ddebtDebtManagementData],
          component: <DataManageMentRouteOa baseUrl={"/debtdatamanagement/datamanagement"} accessRole={roles} />,
        },
        {
          key: "5",
          label: "Lead Management",
          icon: <EditFilled />,
          path: "/debtleadmanagement/leadmanagement",
          showInMenu: true,
          target: "/debtleadmanagement/leadmanagement",
          roles: [UserRole.ddebtDebtManagementLead],
          component: <LeadDebtManagementRoute baseUrl={"/debtleadmanagement/leadmanagement"} accessRole={roles} dataResult={profile} />,
        },
        {
          key: "6",
          label: "Task Import&Export",
          icon: <FileTextOutlined />,
          path: "/debt/taskimport",
          showInMenu: true,
          target: "/debt/taskimport",
          roles: [UserRole.ddebtDebtManagementDebt],
          component: <MainTaskImport />,
        },
      ].filter((menu) => hasRole(menu.roles)),
    },
    {
      label: "Import Management",
      icon: <FileDoneOutlined />,
      path: "/importmanagement",
      showInMenu: true,
      target: "/importmanagement",
      roles: [UserRole.ddebtImportTargetInformation],
      component: <ImporTrargetInFormationRoute baseUrl="/importmanagement" />,
    },
    {
      label: "Notification",
      icon: <FileDoneOutlined />,
      path: "/notification-list",
      showInMenu: false,
      target: "/notification-list",
      roles: [UserRole.ddebtNotificationDashboardList],
      component: <NotificationList loading={true} baseUrl={"/notification-list"} />,
    },
    {
      label: "Setting",
      icon: <SettingFilled />,
      path: "/setting",
      showInMenu: true,
      target: "/setting",
      roles: [UserRole.ddebtSetting],
      component: <OaProfile userId={"1"} accessRole={roles} />,
      subMenu: [
        {
          key: "1",
          label: "OA Profile",
          icon: <UserSwitchOutlined />,
          path: "/oaProfile",
          showInMenu: true,
          target: "/oaProfile",
          roles: [UserRole.ddebtSettingOaProfile],
          component: <OaProfile userId={"1"} accessRole={roles} />,
        },
        {
          key: "2",
          label: "OA Organize",
          icon: <ApartmentOutlined />,
          path: "/oaOganize",
          showInMenu: true,
          target: "/oaOganize",
          roles: [UserRole.ddebtSettingOaOrganize],
          component: <OaOrganize userId={"1"} />,
        },
        {
          key: "3",
          label: "OA User",
          icon: <BankOutlined />,
          path: "/oaUser",
          showInMenu: true,
          target: "/oaUser",
          roles: [UserRole.ddebtSettingOaUser],
          component: <OaUser roles={roles} />,
        },
        {
          key: "4",
          label: "Bank User",
          icon: <BankOutlined />,
          path: "/bankUser",
          showInMenu: true,
          target: "/bankUser",
          roles: [UserRole.ddebtSettingBankUser],
          component: <BankUser roles={roles} />,
        },
        {
          key: "5",
          label: "Bank Organize",
          icon: <ApartmentOutlined />,
          path: "/bankOrganize",
          showInMenu: true,
          target: "/bankOrganize",
          roles: [UserRole.ddebtSettingBankOrganize],
          component: <BankOrganize userId={"1"} />,
        },
        {
          key: "7",
          label: "Job Process",
          icon: <TagsOutlined />,
          path: "/batchProcessJob",
          showInMenu: true,
          target: "/batchProcessJob",
          roles: [UserRole.ddebtSettingBatchProcessJob],
          component: <BatchProcessJob accessRole={roles} />,
        },
        {
          key: "8",
          label: "Daily Process",
          icon: <SolutionOutlined />,
          path: "/batchProcessDaily",
          showInMenu: true,
          target: "/batchProcessDaily",
          roles: [UserRole.ddebtSettingBatchProcessDaily],
          component: <BatchProcessDailyFile accessRole={roles} userId={"1"} />,
        },
        {
          key: "9",
          label: "File Process",
          icon: <FolderOutlined />,
          path: "/batchProcessFile",
          showInMenu: true,
          target: "/batchProcessFile",
          roles: [UserRole.ddebtSettingBatchProcessFile],
          component: <BatchProcessFile userId={"1"} accessRole={roles} />,
        },
        {
          key: "10",
          label: "Email Template",
          icon: <MailOutlined />,
          path: "/emailtemplate",
          showInMenu: true,
          target: "/emailtemplate",
          roles: [UserRole.ddebtSettingEmailtemplate],
          component: <EmailRoute userId={"1"} baseUrl="/emailtemplate" />,
        },
        {
          key: "11",
          label: "Set Session Timeout",
          icon: <ProfileOutlined />,
          path: "/sessiontimeout",
          showInMenu: true,
          target: "/sessiontimeout",
          roles: [UserRole.ddebtSettingSetSessionTimeout],
          component: <SetSessionTimeOut userId={"1"} accessRole={roles} />,
        },

        {
          key: "12",
          label: "Broadcast",
          icon: <WifiOutlined />,
          path: "/broadcast",
          showInMenu: true,
          target: "/broadcast",
          roles: [UserRole.ddebtSettingBroadcast],
          component: <Broadcast />,
        },
        {
          key: "13",
          label: "Time Set Use",
          icon: <FieldTimeOutlined />,
          path: "/timeset",
          showInMenu: true,
          target: "/timeset",
          roles: [UserRole.ddebtSettingTime],
          component: <TimeSetting accessRole={roles} />,
        },
        {
          key: "14",
          label: "Notification Setting",
          icon: <BellOutlined />,
          path: "/notificationsetting",
          showInMenu: true,
          target: "/notificationsetting",
          roles: [UserRole.ddebtSettingNotificationSetting],
          component: <NotificationSetting accessRole={roles} />,
        },
        {
          key: "15",
          label: "Policy",
          icon: <FileDoneOutlined />,
          path: "/policy",
          showInMenu: true,
          target: "/policy",
          roles: [UserRole.ddebtSettingPolicy],
          component: <PolicyMain accessRole={roles} />,
        },
      ].filter((menu) => hasRole(menu.roles)),
    },
  ].filter((menu) => hasRole(menu.roles));

  const menu = (
    <Menu>
      <Menu.Item key="profile" onClick={() => setIsModalVisibleProfile(true)}>
        Profile
      </Menu.Item>
      <Menu.Item key="logout" onClick={() => login(window.location.origin + "/")}>
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const updateUserActivity = () => {
      const expride = new Date().getTime();
      localStorage.setItem("exprideTime", expride.toString());
    };
    document.addEventListener("click", updateUserActivity);
    document.addEventListener("keypress", updateUserActivity);
    return () => {
      document.removeEventListener("click", updateUserActivity);
      document.removeEventListener("keypress", updateUserActivity);
    };
  }, []);

  const isUserActive = () => {
    const currentTime = new Date().getTime();
    const lastActiveTime = localStorage.getItem("exprideTime");
    if (!lastActiveTime) {
      return true;
    }
    const inactiveTimeThreshold = resTimeExpride * 60 * 1000;
    const timeDifference = currentTime - Number(lastActiveTime);
    return timeDifference <= inactiveTimeThreshold;
  };

  const exprideTime = () => {
    localStorage.removeItem("exprideTime");
    login(window.location.origin + "/");
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          {isUserActive() ? (
            <Box>
              <OverviewSettingModal isVisible={isModalVisibleOverviewSetting} handleCancel={() => setIsModalVisibleOverviewSetting(false)} />
              <Router>
                <Modal title="Profile" open={isModalVisibleProfile} onOk={handleOkProfile} onCancel={handleCancelProfile} footer={false}>
                  <Row>
                    <Col span={24}>Full Name : {profile.name ? profile?.name : "-"}</Col>
                    <Col span={24}>Email : {profile?.email ? profile?.email : "-"}</Col>
                    <Col span={24}>Roles : {profile?.group ? profile?.group : "-"}</Col>
                  </Row>
                </Modal>
                <Modal title="Reset password" open={isModalVisibleResetPassword} onOk={handleOkResetPassword} onCancel={handleCancelResetPassword}>
                  <p>Reset your password</p>
                </Modal>
                <Layout hasSider>
                  <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapse}
                    style={{
                      backgroundColor: "white",
                      overflowX: "hidden",
                      overflowY: "auto",
                      height: "100vh",
                      position: "fixed",
                      left: 0,
                      top: 0,
                      bottom: 0,
                    }}
                  >
                    <Row className="logo">
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Image preview={false} src={logo} style={{ padding: "20%" }} />
                      </Col>
                    </Row>
                    <MainMenu
                      inlineCollapsed={collapse}
                      items={menuItems
                        ?.filter((m) => m.showInMenu)
                        .map(({ label, icon, target, exact, subMenu }) => ({
                          label,
                          icon,
                          path: target,
                          exact,
                          subMenu: subMenu && subMenu.filter((menu) => menu.showInMenu).map((menu: MenuItem) => createMainMenuItem(menu)),
                        }))}
                    />
                  </Sider>
                  <Layout className="site-layout" style={{ paddingLeft: 200 }}>
                    <Header
                      className="header"
                      style={{
                        backgroundColor: "white",
                        padding: 2,
                        textAlign: "left",
                        minHeight: "80px",
                        height: "auto",
                      }}
                    >
                      <Row style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Col style={{ textAlign: "left" }}>
                          <Select labelInValue placeholder="Select Menu" style={{ width: 140 }} bordered={false} onChange={handleClickMenu}>
                            {checkAccessRole("ddebtModule") ? <Option value="dashboard">Ddebt</Option> : ""}
                            {checkAccessRole("reportModule") ? <Option value="report">Report</Option> : ""}
                            {checkAccessRole("commissionModule") ? <Option value="commission">Commission</Option> : ""}
                            {checkAccessRole("notificationModule") ? <Option value="notification">Notification</Option> : ""}
                          </Select>
                        </Col>
                        <Col
                          style={{
                            marginRight: 5,
                            alignItems: "center",
                            flex: 1,
                            textAlign: "right",
                            width: "100%",
                          }}
                        >
                          <Space>
                            <div id="notification-widget">
                              <NotificationSection uid={sub} url={appConfig.apiBaseUrlNoti} ws={appConfig.notiUrl} />
                            </div>
                            <Avatar size="large" icon={<UserOutlined />} />
                            {checkAccessRole("checkringphone") ? <Dphone /> : <></>}
                            <div>
                              <Col
                                span={24}
                                style={{
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                  height: "20px",
                                  textAlign: "left",
                                  fontSize: "16px",
                                  width: "100%",
                                }}
                              >
                                {profile.name}
                              </Col>
                              <Col
                                span={24}
                                style={{
                                  marginRight: "5px",
                                  marginLeft: "5px",
                                  textAlign: "left",
                                  fontSize: "10px",
                                  color: "#979797",
                                  width: "100",
                                }}
                              >
                                {profile.group}
                              </Col>
                            </div>
                          </Space>
                        </Col>
                        <Col style={{ marginRight: 20 }}>
                          <Dropdown overlay={menu}>
                            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <CaretDownOutlined />
                            </a>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Header>

                    <Content
                      className="site-layout-background"
                      style={{
                        paddingBottom: 24,
                        paddingLeft: 24,
                        paddingRight: 24,
                        margin: "0px 16px",
                        minHeight: "80vh",
                        overflow: "initial",
                      }}
                    >
                      <MainRoute menuItems={[...menuItems]} />
                    </Content>

                    <Footer style={{ textAlign: "center", color: "rgba(0,0,0,.45)" }}>
                      <p>D-Debt | Version: {appConfig.ddebtVersion}</p>
                      <p>Copyright ©2022 OSD</p>
                    </Footer>
                  </Layout>
                </Layout>
              </Router>
            </Box>
          ) : (
            exprideTime()
          )}
        </>
      )}
    </>
  );
};
