import React, { useState, useEffect } from "react";
import { Table, Radio, Button, Modal, message } from "antd";
import NumberFormat from "react-number-format";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { IDebtService, DebtService } from "../services/debt-service";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";

const appConfig = loadAppConfig();

import dayjs from "dayjs";
import { DateTH } from "../../../utils/time-local";

export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  cifId: string;
}

export const DebtTarget: React.FC<Props> = ({ caption, accountNo, baseURL, loading, cifId }): React.ReactElement => {
  const debtService = DebtService;
  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);

  const [dataTable, setDataTable] = useState([]);
  const [valueRadio, setValueRadio] = useState<string>("");
  const [getDataSeq, setDataSeq] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        getDataTable(accountNo, cifId);
        allChangestep(getDataSeq);
        if (accountNo) {
          fetchgetPointdetailsearch(accountNo);
        }
      } catch (err: any) {
        console.log("Error");
      }
    };
    fetchData();
  }, []);

  const getDataTable = async (accountNo: string, cifId: string) => {
    const result = (await debtService(apiBaseUrlDebt).getDebtTarget(accountNo, cifId)) as any;
    const data = (await result.result.map(
      (
        x: {
          accountNo: string;
          avg: string;
          cif: string;
          closeAcc: string;
          createDate: string;
          id: string;
          step1: string;
          step2: string;
          step3: string;
          step4: string;
          step5: string;
        },
        index: number
      ) => {
        const mapData = {
          key: index,
          cif: x.cif,
          accountNo: x.accountNo,
          closeAcc: x.closeAcc,
          minInstallment: x.avg,
          stepOne: x.step1,
          stepTwo: x.step2,
          stepThree: x.step3,
          stepFour: x.step4,
          stepFive: x.step5,
          createDate: DateTH(new Date(x.createDate), "DD-MM-BBBB"),
        };
        return mapData;
      }
    )) as any;
    setDataTable(data);
  };

  const columns = [
    {
      title: "จำนวนผ่อนขั้นต่ำโดยประมาณ",
      dataIndex: "minInstallment",
      key: "minInstallment",
      render: (text: string, record: any, index: number) => (
        <div style={{ textAlign: "right" }}>
          <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
        </div>
      ),
    },
    {
      title: "กรณีปิดบัญชี",
      dataIndex: "closeAcc",
      key: "closeAcc",
      render: (text: string, record: any, index: number) => (
        <div style={{ textAlign: "right" }}>
          <Radio.Group onChange={(e: any) => allChangestep(e.target.value)} value={valueRadio}>
            <Radio key={index} value={"closeAcc"}>
              <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      title: "Step 1 ส่งชำระ 12 งวด",
      dataIndex: "stepOne",
      key: "stepOne",
      render: (text: string, record: any, index: number) => (
        <div style={{ textAlign: "right" }}>
          <Radio.Group onChange={(e: any) => allChangestep(e.target.value)} value={valueRadio}>
            <Radio key={index} value={"step1"}>
              <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      title: "Step 2 ส่งชำระ 24 งวด",
      dataIndex: "stepTwo",
      key: "stepTwo",
      render: (text: string, record: any, index: number) => (
        <div style={{ textAlign: "right" }}>
          <Radio.Group onChange={(e: any) => allChangestep(e.target.value)} value={valueRadio}>
            <Radio key={index} value={"step2"}>
              <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      title: "Step 3 ส่งชำระ 36 งวด",
      dataIndex: "stepThree",
      key: "stepThree",
      render: (text: string, record: any, index: number) => (
        <div style={{ textAlign: "right" }}>
          <Radio.Group onChange={(e: any) => allChangestep(e.target.value)} value={valueRadio}>
            <Radio key={index} value={"step3"}>
              <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      title: "Step 4 ส่งชำระ 48 งวด",
      dataIndex: "stepFour",
      key: "stepFour",
      render: (text: string, record: any, index: number) => (
        <div style={{ textAlign: "right" }}>
          <Radio.Group onChange={(e: any) => allChangestep(e.target.value)} value={valueRadio}>
            <Radio key={index} value={"step4"}>
              <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      title: "Step 5 ส่งชำระ 60 งวด",
      dataIndex: "stepFive",
      key: "stepFive",
      render: (text: string, record: any, index: number) => {
        return (
          <div style={{ textAlign: "right" }}>
            <Radio.Group onChange={(e: any) => allChangestep(e.target.value)} value={valueRadio}>
              <Radio key={index} value={"step5"}>
                <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
              </Radio>
            </Radio.Group>
          </div>
        );
      },
    },
    {
      title: "Update Date",
      dataIndex: "createDate",
      key: "createDate",
    },
  ];

  const allChangestep = (radioValue: string) => {
    if (radioValue === "step1") {
      setValueRadio(radioValue);
    } else if (radioValue === "step2") {
      setValueRadio(radioValue);
    } else if (radioValue === "step3") {
      setValueRadio(radioValue);
    } else if (radioValue === "step4") {
      setValueRadio(radioValue);
    } else if (radioValue === "step5") {
      setValueRadio(radioValue);
    } else {
      setValueRadio(radioValue);
    }
  };

  // search data API
  const fetchgetPointdetailsearch = async (data: string) => {
    const result = await debtService(apiBaseUrlDebt).getPointdetailsearch(data);
    setValueRadio(result[0]?.stepCode);
    setDataSeq(result);
  };

  const SaveTable = () => {
    Modal.confirm({
      title: "คุณแน่ใจแล้วใช่ไหม",
      icon: <ExclamationCircleOutlined />,
      content: "ยืนยันการเลือกข้อมูลการชี้เป้านี้ใช่หรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        if (getDataSeq.length >= 1) {
          const res = (await debtService(apiBaseUrlDebt).getDebtTarget(accountNo, cifId)) as any;
          const data = (await res.result.map(
            (x: {
              accountNo: string;
              avg: string;
              cif: string;
              closeAcc: string;
              createDate: string;
              id: string;
              step1: string;
              step2: string;
              step3: string;
              step4: string;
              step5: string;
            }) => {
              let newAcc = "";
              let stepAmt = "";
              if (valueRadio === "closeAcc") {
                newAcc = "closeAcc";
                stepAmt = x.closeAcc;
              }
              if (valueRadio === "step1") {
                newAcc = "step1";
                stepAmt = x.step1;
              }
              if (valueRadio === "step2") {
                newAcc = "step2";
                stepAmt = x.step2;
              }
              if (valueRadio === "step3") {
                newAcc = "step3";
                stepAmt = x.step3;
              }
              if (valueRadio === "step4") {
                newAcc = "step4";
                stepAmt = x.step4;
              }
              if (valueRadio === "step5") {
                newAcc = "step5";
                stepAmt = x.step5;
              }
              const mapData = {
                cif: x.cif,
                accountNo: x.accountNo,
                stepCode: newAcc,
                stepAmt: stepAmt,
              };
              return mapData;
            }
          )) as any;

          const resultData = { data: data[0] };

          // update data API
          const result = await debtService(apiBaseUrlDebt).getPointdetailupdate(resultData);

          if (result) {
            message.success("บันทึกสำเร็จ");
            window.location.reload();
          }
        } else {
          const res = (await debtService(apiBaseUrlDebt).getDebtTarget(accountNo, cifId)) as any;
          const data = (await res.result.map(
            (x: {
              accountNo: string;
              avg: string;
              cif: string;
              closeAcc: string;
              createDate: string;
              id: string;
              step1: string;
              step2: string;
              step3: string;
              step4: string;
              step5: string;
            }) => {
              let newAcc = "";
              let stepAmt = "";
              if (valueRadio === "closeAcc") {
                newAcc = "closeAcc";
                stepAmt = x.closeAcc;
              }
              if (valueRadio === "step1") {
                newAcc = "step1";
                stepAmt = x.step1;
              }
              if (valueRadio === "step2") {
                newAcc = "step2";
                stepAmt = x.step2;
              }
              if (valueRadio === "step3") {
                newAcc = "step3";
                stepAmt = x.step3;
              }
              if (valueRadio === "step4") {
                newAcc = "step4";
                stepAmt = x.step4;
              }
              if (valueRadio === "step5") {
                newAcc = "step5";
                stepAmt = x.step5;
              }
              const mapData = {
                cif: x.cif,
                accountNo: x.accountNo,
                stepCode: newAcc,
                stepAmt: stepAmt,
              };
              return mapData;
            }
          )) as any;
          const resultData = { data: data[0] };

          // create data API
          const result = await debtService(apiBaseUrlDebt).getPointdetailcreate(resultData);

          if (result) {
            message.success("บันทึกสำเร็จ");
            window.location.reload();
          }
        }
      },
      onCancel: () => {},
    });
  };
  return (
    <>
      <h1>ข้อมูลการชี้เป้า</h1>
      <Table size="small" columns={columns} dataSource={dataTable} rowKey={(record: any) => record.key} scroll={{ x: "calc(500px + 100%)" }} />
      {dataTable?.length >= 1 ? (
        <div style={{ textAlign: "right", marginTop: "5px" }}>
          <Button type="primary" style={{ width: "115px" }} onClick={SaveTable}>
            ยืนยัน
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: "right", marginTop: "5px" }}>
          <Button type="primary" style={{ width: "115px" }} disabled>
            ยืนยัน
          </Button>
        </div>
      )}
    </>
  );
};
