import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Table, Space, Col, Typography, Modal, Button, Select, Input, Form, message, TimePicker, DatePickerProps } from "antd";
import moment from "moment";

import { BatchProcessFileService } from "../services/service";

import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();
const { Option } = Select;

const format = "HH:mm";
import dayjs from "dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { config, datePickerTh } from "../../../utils";

const DatePicker = generatePicker(config);

interface Props {
  accessRole: string;
  filter: (type: string, text: string) => void;
  chkReload: (type: string) => void;
  userId: string;
  handleLoadingTable: (load: boolean) => void;
}

export const FilterBatchProcessFile: React.FC<Props> = ({ filter, userId, chkReload, handleLoadingTable, accessRole }) => {
  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);

  const [form] = Form.useForm();
  const [getrmId, setrmId] = useState<number>(0);
  const [getJobs, setJobs] = useState<any>([]);
  const [getpsDate, setpsDate] = useState<any>("");
  const [getscheduleName, setscheduleNmae] = useState<string>("");

  const handleselectschedulename = (e: number) => {
    if (e) {
      setrmId(e);
      if (e === 1) {
        setscheduleNmae("import-cbs");
      } else {
        setscheduleNmae("cardlink");
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
    form.resetFields();
  };

  const onFinishAdd = async (values: any) => {
    handleLoadingTable(true);
    const conTimeOption = {
      psDate: dayjs(getpsDate).format("YYYY-MM-DD"),
      start: {
        hour: moment(values.timeOption).format("HH"),
        minute: moment(values.timeOption).format("mm"),
      },
      end: {
        hour: moment(values.timeOption).format("HH"),
        minute: moment(values.timeOption).format("mm"),
      },
    };
    // const conJobs = JSON.parse(values.jobs);
    const data = {
      rmId: getrmId,
      fileName: values.fileName,
      psDate: dayjs(getpsDate).format("YYYY-MM-DD"),
      processType: getscheduleName,
      details: getJobs,
      jobs: [
        { jobType: "file-transfer", option: getrmId },
        { jobType: getscheduleName, option: getJobs },
      ],
      timeOption: conTimeOption,
    };
    // console.log(data);

    const res: any = await BatchProcessFileService(apiBaseUrlDatalink).addFile(data);
    if (res) {
      message.success("เพิ่มข้อมูลเรียบร้อย");
      setIsModalVisible(false);
      handleLoadingTable(false);
    }
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log(date, dateString);
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setrmId(0);
    form.resetFields();
  };

  type EditableTableProps = Parameters<typeof Table>[0];

  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            File Process
          </Typography.Title>
        </Col>
        {checkAccessRole("ddebtSettingBatchProcessFileAction") ? (
          <Col span={16}>
            <div style={{ textAlign: "right" }}>
              <Space size={"small"}>
                <Button type="primary" onClick={showModal}>
                  + Add Job
                </Button>
                <Modal title="Add Job" open={isModalVisible} onCancel={handleCancel} footer={[]} width={1200}>
                  <Form
                    form={form}
                    autoComplete="off"
                    name="addjobsform"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 17 }}
                    onFinish={onFinishAdd}
                    onFinishFailed={onFinishFailed}
                    fields={[
                      {
                        name: ["id"],
                        value: uuidv4(),
                      },
                    ]}
                  >
                    <Form.Item hidden={true} label="ID" name="id">
                      <Input placeholder="id" style={{ width: "90%" }} />
                    </Form.Item>
                    <Form.Item
                      label="Remote Account"
                      name="rmId"
                      rules={[
                        {
                          required: true,
                          message: "Please input Name",
                        },
                      ]}
                    >
                      <Select placeholder={"Please Select"} onChange={(data: any) => handleselectschedulename(data)}>
                        <Option key={0} value={0} disabled>
                          Please Select
                        </Option>
                        <Option key={1} value={1}>
                          CBS
                        </Option>
                        <Option key={2} value={3}>
                          Card Link
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="File Name"
                      name="fileName"
                      rules={[
                        {
                          required: true,
                          message: "Please input fileName",
                        },
                      ]}
                    >
                      <Input id="fileName" placeholder="Filename" style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      label="Process Date"
                      name="psDate"
                      rules={[
                        {
                          required: true,
                          message: "Please input psDate",
                        },
                      ]}
                    >
                      <DatePicker
                        locale={datePickerTh}
                        disabledDate={(current: any) => {
                          return moment().add(-1, "days") >= current;
                        }}
                        value={getpsDate}
                        id="psDate"
                        name="psDate"
                        style={{ width: "30%" }}
                        format={"DD-MM-BBBB"}
                        onChange={(e: any) => setpsDate(e)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Run Time"
                      name="timeOption"
                      rules={[
                        {
                          required: true,
                          message: "Please input timeOption",
                        },
                      ]}
                    >
                      <TimePicker popupStyle={{ fontSize: 12 }} format={format} style={{ width: "30%" }} />
                    </Form.Item>
                    <Form.Item
                      label="Jobs"
                      name="jobs"
                      rules={[
                        {
                          required: true,
                          message: "Please input jobs",
                        },
                      ]}
                    >
                      {getrmId === 0 || getrmId === undefined ? (
                        <Select disabled placeholder={"Please Select"}></Select>
                      ) : getrmId === 1 ? (
                        <Select placeholder={"Please Select"} onChange={(event: any) => setJobs(event)} allowClear>
                          <Option key={0} disabled>
                            Please Select
                          </Option>
                          <Option key={1} value={"odTrn"}>
                            CBSODT
                          </Option>
                          <Option key={2} value={"payment"}>
                            CBSTRN
                          </Option>
                          <Option key={3} value={"cob"}>
                            CBSCOB
                          </Option>
                          <Option key={4} value={"od"}>
                            CBSOD
                          </Option>
                          <Option key={5} value={"debt"}>
                            CBSLN
                          </Option>
                          <Option key={6} value={"cust"}>
                            CBSCUS
                          </Option>
                        </Select>
                      ) : (
                        <Select placeholder={"Please Select"} onChange={(event: any) => setJobs(event)} allowClear>
                          <Option key={0} disabled>
                            Please Select
                          </Option>
                          <Option key={1} value={"paymentCard"}>
                            Credit Payment
                          </Option>
                          <Option key={2} value={"card2"}>
                            Credit
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                    <Space
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
                        Save
                      </Button>
                      <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
                        Cancel
                      </Button>
                    </Space>
                  </Form>
                </Modal>
              </Space>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};
