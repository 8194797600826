import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Row, Col, Collapse, Form, Input, Button, Select, Space, Table, message, Spin, Pagination, Modal } from "antd";
import { SnippetsFilled, EditFilled, PlusSquareOutlined, MinusSquareOutlined, CheckCircleOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { DebtService } from "./services/debt-service";
import { TeamOANew } from "./model/debt";
import { IuserProfile } from "../datamanagement/models/models-datamanagement";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import { DateStamp, checktext, config, datePickerTh } from "../../utils/index";
import { DateTH } from "../../utils/time-local";
import generatePicker from "antd/es/date-picker/generatePicker";

const appConfig = loadAppConfig();
const DatePicker = generatePicker(config);
const { RangePicker } = DatePicker;

interface CollectorType {
  id: number;
  name: string;
}
interface ProductType {
  type: string;
  code: string;
  desc: string;
}
export interface Props {
  baseURL: string;
  caption: string;
  value: number;
  loading: boolean;
  accessRole: string;
}

const { Option } = Select;
// const { RangePicker } = DatePicker;
const { Panel } = Collapse;

export const DebtSearch: React.FC<Props> = ({ caption, value, baseURL, accessRole }): React.ReactElement => {
  const { id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const debtService = DebtService;
  const { push } = useHistory();
  const [statusCodeRead, setStatusCodeRead] = useState<object[]>([]);
  const [listName, setListName] = useState<object[]>([]);
  const [teamName, setTeamName] = useState<object[]>([]);
  const [supervisorName, setSupervisorName] = useState<object[]>([]);
  const [getTeamOa, setTeamOa] = useState<TeamOANew[]>([]);
  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  let phoneNo = searchParams.get("phoneNo");
  let cif = searchParams.get("cif");
  let accountNo = searchParams.get("acc");
  let filterPhoneNo = "";
  let filterCif = "";
  let filterAccoutNo = "";
  if (phoneNo) {
    filterPhoneNo = phoneNo;
  } else {
    filterPhoneNo = "";
  }
  if (cif) {
    filterCif = cif;
  } else {
    filterCif = "";
  }
  if (accountNo) {
    filterAccoutNo = accountNo;
  } else {
    filterAccoutNo = "";
  }
  let ls: any = "";
  ls = location.state;
  let filterwrapupStatusLo = "";
  let filteractionCodeLo = "";
  let filterstatusCodeLo = "";
  let filterListNameLo = "";
  let filterTeamNameLo = "";
  let filterOaCodeLo = "";
  let filterCollIdLo = "";
  let filterSupNameLo = "";
  let filterCreateDate = "";
  let filterManIdLo = "";

  if (ls) {
    if (ls.create_date) {
      let result = ls.statusCode;
      if (result.substring(0, 3) == "PTP") {
        let dateFromDashBoard = new Date(ls.create_date);
        let datePlus = dateFromDashBoard.setDate(dateFromDashBoard.getDate());
        let dateCon = new Date(datePlus);
        let dateMap = dateCon.getFullYear() + "-" + (dateCon.getMonth() + 1) + "-" + dateCon.getDate();
        filterCreateDate = dateMap.toString();
      } else {
        filterCreateDate = ls.create_date;
      }
    }
    if (ls == "new" || ls == "action" || ls == "noAction") {
      filterwrapupStatusLo = ls;
    }
    if (ls.action == "contact" || ls.action == "noContact") {
      filteractionCodeLo = ls.action;
    }
    if (ls.actionCode == "contact" || ls.actionCode == "noContact") {
      filteractionCodeLo = ls.actionCode;
      filterstatusCodeLo = ls.statusCode;
    }
    if (ls.actionCode == "letter" || ls.actionCode == "other") {
      filteractionCodeLo = ls.actionCode;
      filterstatusCodeLo = ls.statusCode;
    }
    if (ls.type == "BANK") {
      if (ls.statusName) {
        filterwrapupStatusLo = ls.statusName;
        if (ls.teamId) {
          filterTeamNameLo = ls.teamId;
          if (ls.listName) {
            filterListNameLo = ls.listName;
            if (ls.oaName) {
              filterOaCodeLo = ls.oaCode;
            }
          }
        }
      }
    }
    if (ls.type == "OA") {
      if (ls.statusName) {
        filterwrapupStatusLo = ls.statusName;
        if (ls.supId) {
          filterSupNameLo = ls.supId;
          if (ls.listName) {
            filterListNameLo = ls.listName;
            if (ls.collId) {
              filterCollIdLo = ls.collId;
            } else {
              filterManIdLo = ls.manId;
            }
          }
        } else {
          filterManIdLo = ls.manId;
        }
      }
    }
    if (ls.type == "SUPOA") {
      if (ls.statusName) {
        filterwrapupStatusLo = ls.statusName;
        if (ls.collId) {
          filterCollIdLo = ls.collId;
          if (ls.listName) {
            filterListNameLo = ls.listName;
          }
        }
      }
    }
  }

  const [resData, setResData] = useState([]);
  const [panelShow, setPanelShow] = useState(true);
  const [panelAction, setPanelAction] = useState("1");
  const [filtercif, setFiltercif] = useState<string>(filterCif);
  const [filteraccountNo, setFilteraccountNo] = useState<string>(filterAccoutNo);
  const [filteractionCode, setFilteractionCode] = useState<string>(filteractionCodeLo);
  const [filterstatusCode, setFilterstatusCode] = useState<string>(filterstatusCodeLo);
  const [filterListName, setFilterListName] = useState<string>(filterListNameLo);
  const [filterTeamName, setFilterTeamName] = useState<string>(filterTeamNameLo);
  const [filterSupName, setFilterSupName] = useState(filterSupNameLo);
  const [filterManId, setFilterManId] = useState(filterManIdLo);
  const [filtercustName, setFiltercustName] = useState<string>("");
  const [filterproductType, setFilterproductType] = useState<string>("");
  const [filterloanType, setFilterloanType] = useState<string>("");
  const [filteraccountStatus, setFilteraccountStatus] = useState<string>("");
  const [filtercollector, setFiltercollector] = useState<string>(filterCollIdLo);
  const [filteroa, setFilteroa] = useState<string>(filterOaCodeLo);
  const [filterstartDate, setFilterstartDate] = useState<string>("");
  const [filterendDate, setFilterendDate] = useState<string>("");
  const [filterappointmentDate, setfilterappointmentDate] = useState<string>(filterCreateDate); //new design
  const [filterwrapupStatus, setFilterwrapupStatus] = useState<string>(filterwrapupStatusLo);
  const [takePage, setTakePage] = useState<number>(10);
  const [skipPage, setSkipPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lengthPage, setLengthPage] = useState<number>(0);
  const [actionCodeRead, setActionCodeRead] = useState<object[]>([]);
  const [collectorList, setCollectorlist] = useState<CollectorType[]>([]);
  const [prodCode, setProdCode] = useState<ProductType[]>([]);
  const [prodCodeAll, setProdCodeAll] = useState<ProductType[]>([]);
  const [accountStatus, setAccountStatus] = useState<ProductType[]>([]);
  const [userType, setUserType] = useState("");
  const [filterPhonenumber, setPhonenumber] = useState<string>(filterPhoneNo);
  const [showTeam, setShowTeam] = useState<boolean>(false);
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });

  useEffect(() => {
    async function fetchDataForSearch() {
      getDataTable(1, 10);
      getFilterSearch();
      getProductCode();
      getOalist();
      getUserProfile().then(async (data) => {
        if (data) {
          setUserType(data.type);
          if (data.type == "BANK") {
            setShowTeam(true);
          } else {
            setShowTeam(false);
          }
          setUserprofile(data);
          getCollector(data);
          const resultTeam = await debtService(apiBaseUrl).getTeamListBank(data.type);

          const sortTeam = resultTeam.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
          if (sortTeam) {
            setTeamName(sortTeam);
            let dataUn = {
              id: "unknownTeams",
              name: "Unknown Teams",
            };
            setTeamName((teamName) => [dataUn, ...teamName]);
          }
          const result = await debtService(apiBaseUrlDebt).getSupAllByUser();
          if (result.result) {
            const resultMapSup = result.result.filter((x: any) => {
              return x.level === "SUPERVISOR";
            });
            setSupervisorName(resultMapSup);
          }
          const resultList = await DebtService(apiBaseUrlDebt).getlistnameBytoken();
          setListName(resultList.result);
        }
      });
      if (ls != undefined) {
        getStatusCodeByActionCode(ls.actionCode);
      }
      await getAccountStatus();
    }
    fetchDataForSearch();
  }, [baseURL]);

  const getAccountStatus = async () => {
    const res = (await debtService(apiBaseUrlDebt).getAccountStatus()) as any;
    setAccountStatus(res.result);
  };

  const getStatusCodeByActionCode = async (actionCode: any) => {
    const res = (await debtService(apiBaseUrlDebt).getActionCode()) as any;
    {
      res.result.map((opt: any, index: any) => {
        if (opt.actionCode == actionCode) {
          filterstatusCodeLo = opt.statusCode;
          setStatusCodeRead(opt.statusCode);
        }
      });
    }
  };

  const getUserProfile = async () => {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resualProfile = await DebtService(apiBaseUrlDebt).getUserDetail();
      resolve(resualProfile);
    });
  };
  const changePage = (page: number, take: number) => {
    setCurrentPage(page);
    setTakePage(take);
    getDataTable(page, take);
  };

  const getDataTable = async (page: number, take: number) => {
    setLoading(true);
    let skip = (page - 1) * take;
    if (page === 1) {
      setCurrentPage(1);
    }
    setSkipPage(skip);
    let condition = {} as any;
    let detail = {} as any;
    if (filtercif != "") {
      condition = { ...condition, cif: filtercif };
    }
    if (filteraccountNo != "") {
      condition = { ...condition, accountNo: filteraccountNo.toUpperCase() };
    }
    if (Object.keys(detail).length != 0) {
      condition = { ...condition, detail: detail };
    }
    if (filteractionCode != "") {
      condition = { ...condition, actionCode: filteractionCode };
    }
    if (filterstatusCode != "") {
      condition = { ...condition, statusCode: filterstatusCode };
    }
    if (filtercustName != "") {
      condition = { ...condition, custName: filtercustName };
    }
    if (filterproductType != "") {
      condition = { ...condition, productCode: filterproductType };
    }
    if (filterloanType != "") {
      condition = { ...condition, loanType: filterloanType };
    }
    if (filteroa != "") {
      condition = { ...condition, oaCode: filteroa };
    }
    if (filtercollector != "") {
      condition = { ...condition, collector: filtercollector };
    }
    if (filterstartDate != "") {
      condition = { ...condition, startDate: filterstartDate };
    }
    if (filterendDate != "") {
      condition = { ...condition, endDate: filterendDate };
    }
    if (filterwrapupStatus != "") {
      condition = { ...condition, statusDebt: filterwrapupStatus };
    }
    if (filterTeamName != "") {
      condition = { ...condition, teamId: filterTeamName };
    }
    if (filterListName != "") {
      condition = { ...condition, listName: filterListName };
    }
    if (filterSupName != "") {
      condition = { ...condition, supId: filterSupName };
    }
    if (filteraccountStatus != "") {
      condition = { ...condition, accountStatus: filteraccountStatus };
    }

    if (filterappointmentDate != "") {
      condition = { ...condition, appointmentDate: filterappointmentDate };
    }

    if (filterManId != "") {
      condition = { ...condition, manId: filterManId };
    }

    if (filterPhonenumber != "") {
      condition = { ...condition, phoneNo: filterPhonenumber };
    }
    condition = { take: take, skip: skip, ...condition };

    let formPost = condition;

    try {
      const count = (await debtService(apiBaseUrlDebt).getCountDebt(formPost)) as any;
      let countData = count.result[0].count;

      setLengthPage(countData);
      const res = (await debtService(apiBaseUrlDebt).getDebtList(formPost)) as any;
      const data = (await res.result.map(
        (
          x: {
            account_no: string;
            action_code_name: string;
            assign: string;
            bal: string;
            cif: string;
            custname: string;
            loantype: string;
            account_status: string;
            productdesc: string;
            status_code_name: string;
            updated_date: string;
            collname: string;
            oaname: string;
            gtdue: string;
            intamt: string;
          },
          index: number,
        ) => {
          let nameLoantype = "";
          if (x.loantype === "LOAN") {
            nameLoantype = "LOAN";
          } else if (x.loantype === "Card Link") {
            nameLoantype = "CREDIT";
          } else {
            nameLoantype = "OD";
          }
          const mapData = {
            key: index + skip + 1,
            assignDate: x.assign != null ? DateStamp(x.assign) : "-",
            cif: x.cif,
            accountNo: x.account_no,
            loantype: x.loantype,
            nameLoanType: nameLoantype,
            account_status: x.account_status,
            productDescription: x.productdesc,
            customerName: x.custname,
            grantTotal: x.gtdue,
            actionCodeName: x.action_code_name,
            statusCodeName: x.status_code_name,
            collector: x.collname,
            actionDate: x.updated_date,
            oaName: x.oaname,
          };
          return mapData;
        },
      )) as any;

      setResData([]);
      setResData(data);
    } catch (err) {
      message.error("ไม่พบข้อมูล");
    }
    setLoading(false);
  };

  const checkBeForeExport = () => {
    if (showTeam == true) {
      if (!filterloanType) {
        message.warning("กรุณาเลือก Loan Type");
        return false;
      } else if (filterloanType === "LOAN" || filterloanType === "Card Link" || filterloanType === "OD") {
        if (!filteraccountStatus) {
          message.warning("กรุณาเลือก Account Status");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      if (!filterloanType) {
        message.warning("กรุณาเลือก Loan Type");
        return false;
      } else if (filterloanType === "LOAN" || filterloanType === "Card Link" || filterloanType === "OD") {
        if (!filteraccountStatus) {
          message.warning("กรุณาเลือก Account Status");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const getFilterSearch = async () => {
    const res = (await debtService(apiBaseUrlDebt).getActionCode()) as any;
    setActionCodeRead(res.result);
  };

  const getCollector = async (dataProfile: IuserProfile) => {
    const oa = (await debtService(apiBaseUrlDebt).getUserList()) as any;
    const fillterResult = await oa.result.filter(
      (x: { id: number; name: string; level: string }) => x.id !== dataProfile.id && x.level === "COLLECTOR",
    );
    const data = (await fillterResult.map((x: { id: number; name: string }) => {
      const mapData = {
        id: Number(x.id),
        name: x.name,
      };
      return mapData;
    })) as any;
    setCollectorlist(data);
  };

  const getOalist = async () => {
    const oa = await debtService(apiBaseUrlDebt).getOa();
    setTeamOa(oa);
  };

  const FunctionfilterloanType = async (type: string) => {
    setFilterloanType(type);
    if (type === undefined) {
      getProductCode();
    } else {
      const newdata = prodCodeAll.filter((e: ProductType) => e.type === type);
      setProdCode(newdata);
    }
  };

  const getProductCode = async () => {
    const product = await debtService(apiBaseUrlDebt).getProductCode();

    const data = await product.result.map((x: any) => {
      const mapData = {
        type: x.loanType,
        code: x.productCode,
        desc: x.productDesc,
      };
      return mapData;
    });
    await setProdCodeAll(data);
    setProdCode(data);
  };

  const genExtra = () => (
    <>
      {panelShow === false ? (
        <PlusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(true);
            setPanelAction("1");
          }}
        />
      ) : (
        <MinusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(false);
            setPanelAction("0");
          }}
        />
      )}
    </>
  );

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const handleDownload = async () => {
    let skip = 0;
    var check = checkBeForeExport();
    if (check == true) {
      let condition = {} as any;
      if (filtercif != "") {
        condition = { ...condition, cif: filtercif };
      }
      if (filteraccountNo != "") {
        condition = { ...condition, accountNo: filteraccountNo.toUpperCase() };
      }
      if (filteractionCode != "") {
        condition = { ...condition, actionCode: filteractionCode };
      }
      if (filterstatusCode != "") {
        condition = { ...condition, statusCode: filterstatusCode };
      }
      if (filtercustName != "") {
        condition = { ...condition, custName: filtercustName };
      }
      if (filterproductType != "") {
        condition = { ...condition, productCode: filterproductType };
      }
      if (filterloanType != "") {
        condition = { ...condition, loanType: filterloanType };
      }
      if (filteroa != "") {
        condition = { ...condition, oaCode: filteroa };
      }
      if (filtercollector != "") {
        condition = { ...condition, collector: filtercollector };
      }
      if (filterstartDate != "") {
        condition = { ...condition, startDate: filterstartDate };
      }
      if (filterendDate != "") {
        condition = { ...condition, endDate: filterendDate };
      }
      if (filterwrapupStatus != "") {
        condition = { ...condition, statusDebt: filterwrapupStatus };
      }
      if (filterTeamName != "") {
        condition = { ...condition, teamId: filterTeamName };
      }
      if (filterListName != "") {
        condition = { ...condition, listName: filterListName };
      }
      if (filterSupName != "") {
        condition = { ...condition, supId: filterSupName };
      }
      if (filteraccountStatus != "") {
        condition = { ...condition, accountStatus: filteraccountStatus };
      }
      if (filterappointmentDate != "") {
        condition = { ...condition, appointmentDate: filterappointmentDate };
      }
      if (filterManId != "") {
        condition = { ...condition, manId: filterManId };
      }
      if (filterPhonenumber != "") {
        condition = { ...condition, phoneNo: filterPhonenumber };
      }
      let formPost = condition;

      await debtService(apiBaseUrl)
        .exportDebt(formPost)
        .then((data: any) => {
          Modal.confirm({
            title: "สำเร็จ",
            icon: <CheckCircleOutlined />,
            content: "ท่านสามารถกดดาวน์โหลดไฟล์ได้ที่หน้า  Task import&export",
            okText: "ยืนยัน",
            cancelText: "ยกเลิก",
            onOk: () => {
              window.location.href = `${window.location.origin}/debt/taskimport`;
            },
          });
        })
        .catch((err) => alert("Pless check service !!"));
    }
  };

  const columns: any = [
    {
      title: "ลำดับ",
      dataIndex: "key",
    },
    {
      title: "วันที่ได้รับงาน",
      dataIndex: "assignDate",
      key: "assignDate",
      // render: (text: string, record: any, index: number) => (text !== "" && text !== null ? DateTH(new Date(text), "DD-MM-BBBB") : "-"),
    },
    {
      title: "CIF",
      dataIndex: "cif",
      key: "cif",
    },
    {
      title: "Account No.",
      dataIndex: "accountNo",
      key: "key",
      render: (text: string, record: any, index: number) => {
        return text ? (
          <a key={index} onClick={() => push(`${baseURL}/acc/${record.cif}/${text}`)}>
            {text}
          </a>
        ) : (
          <></>
        );
      },
    },
    {
      title: "ประเภทผลิตภัณฑ์",
      dataIndex: "nameLoanType",
      key: "loantype",
    },
    {
      title: "Account Status",
      dataIndex: "account_status",
      key: "account_status",
    },
    {
      title: "รายละเอียดผลิตภัณฑ์",
      dataIndex: "productDescription",
      key: "productDescription",
      render: (event: string) => checktext(event),
    },
    {
      title: "ชื่อลูกหนี้",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "จำนวนเงินค้างชำระ",
      dataIndex: "grantTotal",
      key: "grantTotal",
      align: "right" as const,
      render: (text: string, record: any, index: number) => (
        <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={""} />
      ),
    },
    {
      title: "OA",
      dataIndex: "oaName",
      key: "oaName",
    },
    {
      title: "Action Code",
      dataIndex: "actionCodeName",
      key: "actionCodeName",
      render: (text: string, record: any, index: number) => (text ? text : "-"),
    },
    {
      title: "Status Code",
      dataIndex: "statusCodeName",
      key: "statusCodeName",
      render: (text: string, record: any, index: number) => (text ? text : "-"),
    },
    {
      title: "Collector",
      dataIndex: "collector",
      key: "collector",
      render: (text: string, record: any, index: number) => (text ? text : "-"),
    },
    {
      title: "วันที่ติดตามล่าสุด",
      dataIndex: "actionDate",
      key: "actionDate",
      render: (text: string, record: any, index: number) => (text !== "" && text !== null ? DateTH(new Date(text), "DD-MM-BBBB") : "-"),
    },
  ];

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <SnippetsFilled /> DEBT SEARCH
          </b>
        </Card>
      </Row>
      <Row
        style={{ marginTop: "2rem" }}
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Collapse
          // defaultActiveKey={['1']}
          expandIconPosition="end"
          style={{ width: "100%" }}
          collapsible={"header"}
          activeKey={[panelAction]}
        >
          <Panel
            forceRender={true}
            extra={genExtra()}
            showArrow={false}
            header="กำหนดเงื่อนไขในการค้นหาข้อมูล"
            key="1"
            style={{ backgroundColor: "white" }}
          >
            <Form
              layout="vertical"
              initialValues={{
                cif: filterCif,
                accountNo: filterAccoutNo,
                actioncode: filteractionCodeLo,
                statusCode: filterstatusCodeLo,
                wrapupStatus: filterwrapupStatusLo,
                teamName: filterTeamNameLo,
                listName: filterListNameLo,
                oa: filterOaCodeLo,
                supervisorName: filterSupNameLo,
                collector: filterCollIdLo,
                phoneNo: filterPhoneNo,
              }}
              onFinish={(value: any) => {
                getDataTable(1, takePage);
              }}
            >
              <Row>
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="CIF" name="cif">
                    <Input placeholder="Text" onChange={(e: any) => setFiltercif(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="Account No." name="accountNo">
                    <Input placeholder="Text" onChange={(e: any) => setFilteraccountNo(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="Action Code" name={"actioncode"}>
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                      placeholder="Please Select"
                      onChange={(text: string, row: any) => {
                        setFilteractionCode(text);
                        if (text) {
                          const stausCode: any = actionCodeRead[row.optIndex];
                          setStatusCodeRead(stausCode.statusCode);
                        } else {
                          setStatusCodeRead([]);
                        }
                      }}
                    >
                      <Option key={""} value="" disabled>
                        <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                      </Option>
                      {actionCodeRead?.map((opt: any, index: any) => {
                        return (
                          <Option key={opt.actionCode} value={opt.actionCode} optIndex={index}>
                            {opt.actionName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="Status Code" name="statusCode">
                    <Select
                      allowClear
                      placeholder="Please Select"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                      onChange={(e: string) => setFilterstatusCode(e)}
                    >
                      <Option key={0} value="" disabled>
                        <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                      </Option>
                      {statusCodeRead?.map((opt: any) => (
                        <Option key={opt.id} value={opt.statusCode} label="">
                          {opt.statusName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item label="Status Code" name="statusCodeFromDashboard" 
                  style={
                    ls != undefined ? { display: 'block'} : { display: 'none'}
                    }
                    >
                    <Select
                      defaultValue={filterstatusCodeLo}
                      allowClear
                      // placeholder="Please Select"
                      onChange={(e: string) => setFilterstatusCode(e)}
                    >
                      <Option
                          key={0}
                          value=""
                          disabled
                        >
                          Please Select
                        </Option>
                      {
                      statusCodeRead.map((opt: any) => (   
                        <Option key={opt.id} value={opt.statusCode} label="">
                          {opt.statusName}
                        </Option>
                      ))
                      }
                    </Select>
                  </Form.Item> */}
                </Col>

                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="ชื่อลูกหนี้" name="custName">
                    <Input placeholder="Text" onChange={(e: any) => setFiltercustName(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="Loan Type" name="loantype">
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                      onChange={FunctionfilterloanType}
                      placeholder="Please Select"
                    >
                      <Option key={0} value="" disabled>
                        <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                      </Option>
                      <Option key={1} value={"LOAN"}>
                        LOAN
                      </Option>
                      <Option key={2} value={"Card Link"}>
                        CREDIT
                      </Option>
                      <Option key={3} value={"OD"}>
                        OD
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="Account Status" name="accountStatus">
                    <Select
                      allowClear
                      showArrow
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                      onChange={(e: any) => setFilteraccountStatus(e)}
                      placeholder="Please Select"
                    >
                      <Option key={0} value="" disabled>
                        <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                      </Option>
                      {accountStatus.map((opt: any) => {
                        return (
                          <Option key={opt.id} value={opt.actionStatusName}>
                            {opt.actionStatusName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="รายละเอียดผลิตภัณฑ์" name="productType">
                    <Select
                      allowClear
                      mode="multiple"
                      showArrow
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                      onChange={(e: any) => {
                        if (e) {
                          setFilterproductType(e);
                        }
                      }}
                      placeholder="Please Select"
                    >
                      <Option key={0} value="" disabled>
                        <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                      </Option>
                      {prodCode.map((opt: any, num: number) => (
                        <Option key={num + 1} value={opt.code}>
                          {opt.desc}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={4} style={{ paddingRight: "10px" }}>
                  <Form.Item
                    label="รายละเอียดผลิตภัณฑ์"
                    name="productDescription"
                  >
                    <Input
                      placeholder="Text"
                      onChange={(e: any) =>
                        setFilterproductDescription(e.target.value)
                      }
                    />
                  </Form.Item>
                </Col> */}
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="วันที่ติดตามล่าสุด" name="trackDate">
                    <RangePicker
                      locale={datePickerTh}
                      format={["DD-MM-BBBB", "DD-MM-BBBB"]}
                      style={{ width: "100%" }}
                      onChange={(e: any) => {
                        if (e === null) {
                          setFilterstartDate("");
                          setFilterendDate("");
                        } else {
                          setFilterstartDate(DateTH(e[0], "YYYY-MM-DD"));
                          setFilterendDate(DateTH(e[1], "YYYY-MM-DD"));
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingRight: "10px" }}>
                  <Form.Item label="สถานะรายการ" name="wrapupStatus">
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                      placeholder="Please Select"
                      onChange={(e: any) => {
                        setFilterwrapupStatus(e);
                      }}
                    >
                      <Option key="0" value="" disabled>
                        <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                      </Option>
                      <Option key="1" value="new">
                        New
                      </Option>
                      <Option key="2" value="action">
                        Action
                      </Option>
                      <Option key="3" value="noAction">
                        No Action
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                {checkAccessRole("ddebtDebtManagementTeamfield") ? (
                  <Col span={6} style={{ paddingRight: "10px" }}>
                    <Form.Item label="Team" name="teamName">
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                        placeholder="Please Select"
                        onChange={(e: any) => {
                          setFilterTeamName(e);
                        }}
                      >
                        <Option key="0" value="" disabled>
                          <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                        </Option>
                        {teamName.map((opt: any, num: any) => {
                          return (
                            <Option key={opt.id} value={opt.id}>
                              {opt.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                {checkAccessRole("ddebtDebtManagementListfield") ? (
                  <Col span={6} style={{ paddingRight: "10px" }}>
                    <Form.Item label="List" name="listName">
                      <Select
                        allowClear
                        placeholder="Please Select"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                        onChange={(e: any) => {
                          setFilterListName(e);
                        }}
                      >
                        <Option key="0" value="" disabled>
                          <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                        </Option>
                        {listName.map((opt: any, num: any) => (
                          <Option key={num + 1} value={opt}>
                            {opt}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                {checkAccessRole("ddebtDebtManagementOafield") ? (
                  <Col span={6} style={{ paddingRight: "10px" }}>
                    <Form.Item label="OA" name="oa">
                      <Select
                        allowClear
                        placeholder="Please Select"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                        onChange={(e: any) => setFilteroa(e)}
                      >
                        <Option key="0" value="" disabled>
                          <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                        </Option>
                        {getTeamOa.map((x: TeamOANew, num: number) => {
                          return (
                            <Option key={num + 1} value={x.oa_code}>
                              {x.oa_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                {checkAccessRole("ddebtDebtManagementSupervisorfield") ? (
                  <Col span={6} style={{ paddingRight: "10px" }}>
                    <Form.Item label="Supervisor" name="supervisorName">
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                        placeholder="Please Select"
                        onChange={(e: any) => {
                          setFilterSupName(e);
                        }}
                      >
                        <Option key="" value="" disabled>
                          <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                        </Option>
                        {supervisorName.map((sup: any, num: any) => (
                          <Option key={sup.id} value={sup.id}>
                            {sup.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                {checkAccessRole("ddebtDebtManagementCollectorfield") ? (
                  <Col span={6} style={{ paddingRight: "10px" }}>
                    <Form.Item label="Collector" name="collector">
                      <Select
                        allowClear
                        placeholder="Please Select"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                        onChange={(e: any) => setFiltercollector(e)}
                      >
                        <Option key="0" value="" disabled>
                          <em style={{ fontStyle: "normal", color: "#C8C8C8" }}>Please Select</em>
                        </Option>
                        {collectorList.map((opt, num) => (
                          <Option key={num + 1} value={opt.id}>
                            {opt.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  " "
                )}
                {checkAccessRole("clicktocall") ? (
                  <Col span={6} style={{ paddingRight: "10px" }}>
                    <Form.Item label="Phone Number" name="phoneNo">
                      <Input placeholder="Text" onChange={(e: any) => setPhonenumber(e.target.value)} />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "right", paddingRight: "10px" }}>
                  <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Search
                      </Button>
                      <>
                        {checkAccessRole("ddebtDebtManagementDebtExport") ? (
                          <Button type="primary" onClick={handleDownload} danger>
                            <EditFilled /> Export CSV
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
      </Row>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <h1 style={{ display: "inline" }}>แสดงผลการค้นหาข้อมูล</h1>
          <Row>
            <Table
              size="small"
              dataSource={resData}
              columns={columns}
              scroll={{ x: "calc(1200px + 100%)" }}
              rowKey={(record: any) => record?.key}
              pagination={false}
              style={{ width: "100%", marginTop: "10px" }}
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={24} style={{ textAlign: "right" }}>
              {lengthPage ? (
                <Pagination
                  defaultCurrent={currentPage}
                  // defaultPageSize={currentPage}
                  total={lengthPage}
                  current={currentPage}
                  onChange={changePage}
                />
              ) : null}
            </Col>
          </Row>
        </Card>
      </Row>
    </Spin>
  );
};
