import React, { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import dayjs from "dayjs";
import { Form, Row, Input, Radio, Select, Col, Button, InputNumber, Typography, message, TreeSelect } from "antd";
import { DatamanagementService } from "../../services/datamanagement-service";
import { typeTeam, DataType, ProductType, ValueInDetail, ICenter } from "../../models/models-datamanagement";
import { loadAppConfig } from "../../../../config/app-config";
import { useId24 } from "../../../../drivers/id24/Id24-provider";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { IMasterDataOfBranch } from "../../common";
import { config, datePickerTh } from "../../../../utils";

const appConfig = loadAppConfig();
const DatePicker = generatePicker(config);
const { Option } = Select;
interface Props {
  byPassDataToMainBasic: (
    statusSave: string,
    getConditionLoantype: any,
    getConditionAccountStatus: any,
    getConditionProductType: any,
    getConditionAccountLevel: any,
    getConditionLocation: any,
    getConditionAmount: any,
    getConditionStmtAmt: any,
    getConditionCurBal: any,
    getDataLoanTypeSubmit: any,
    getDataAccountStatusSubmit: any,
    getDataProductTypeSubmit: any,
    getDataAccountLevelSubmit: any,
    getDataLocationSubmit: any,
    getDataAmountSubmit: any,
    getDataStmtAmtSubmit: any,
    getDataCurBalSubmit: any,
    getExpireday: any,
    getValueTeam: any,
    getAssignType: string,
    getconditionPrincipalAmount: string,
    getDataPrincipalAmount: string,
    getExpridedType: string,
    getFristBetweenPrincipalAmount: string,
    getEndBetweenPrincipalAmount: string,
    getFristBetweenOverduleAmount: string,
    getEndBetweenOverduleAmount: string,
    getFristBetweenStmtAmt: string,
    getEndBetweenStmtAmt: string,
    getFristBetweenCurBal: string,
    getEndBetweenCurBal: string,
    setDataBillingCycleValue: string,
  ) => void;
}

export const BasicPage: React.FC<Props> = ({ byPassDataToMainBasic }): React.ReactElement => {
  const { id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const refValueLoanType = useRef<any>(null);
  const refValueAccountStatus = useRef<any>(null);
  const refValueAccountLevel = useRef<any>(null);
  const refBillingCycle = useRef<any>(null);
  const refValueLocation = useRef<any>(null);
  const refValueConditionAmount = useRef<any>(null);
  const refValueConditionStmtAmt = useRef<any>(null);
  const refValueConditionCurBal = useRef<any>(null);
  const refValuePrincipalAmount = useRef<any>(null);
  const refValueTeam = useRef<any>(null);
  const refExpireDay = useRef<any>(null);
  const [getAssignType, setAssignType] = useState<string>("Manual");
  const [getExpridedType, setExpridedType] = useState<string>("StatementDate");
  const [getValueLoanType, setValueLoanType] = useState<any>([]);
  const [getValueAccountStatus, setValueAccountStatus] = useState<any>([]);
  const [getValueProductType, setValueProductType] = useState<ProductType[]>([]);
  const [getValueAccountLevel, setValueAccountLevel] = useState<any>([]);
  const [getValueLocation, setValueLocation] = useState<any>([]);
  const [getValueAmount, setValueAmount] = useState<any>([]);
  const [getDetailDataLoanType, setDetailDataLoanType] = useState<any>([]);
  const [getDetailDataAccountStatus, setDetailDataAccountStatus] = useState<any>([]);
  const [getDetailDataProductType, setDetailDataProductType] = useState<any>([]);
  const [getDetailDataAccountLevel, setDetailDataAccountLevel] = useState<any>([]);
  const [getDetailDataLocation, setDetailDataLocation] = useState<any>([]);
  const [getDetailDataAmount, setDetailDataAmount] = useState<any>([]);
  const [getDataPrincipalAmount, setDataPrincipalAmount] = useState<string>("");
  const [getDataLoanTypeSubmit, setDataLoanTypeSubmit] = useState<any>([]);
  const [getDataAccountStatusSubmit, setDataAccountStatusSubmit] = useState<any>([]);
  const [getDataBillingCycle, setDataBillingCycle] = useState<any>([]);
  const [getDataBillingCycleValue, setDataBillingCycleValue] = useState<any>([]);
  const [getDataBillingCycleSubmit, setDataBillingCycleSubmit] = useState<any>([]);
  const [getDataProductTypeSubmit, setDataProductTypeSubmit] = useState<any[]>([]);
  const [getDataAccountLevelSubmit, setDataAccountLevelSubmit] = useState<any>([]);
  const [getDataLocationSubmit, setDataLocationSubmit] = useState<any>([]);
  const [getDataAmountSubmit, setDataAmountSubmit] = useState<string>("");
  const [getConditionLoantype, setConditionLoantype] = useState<string>("");
  const [getConditionAccountStatus, setConditionAccountStatus] = useState<string>("");
  const [getConditionProductType, setConditionProductType] = useState<string>("");
  const [getConditionAccountLevel, setConditionAccountLevel] = useState<string>("");
  const [getConditionLocation, setConditionLocation] = useState<string>("");
  const [getConditionAmount, setConditionAmount] = useState<string>("select");
  const [getExpireday, setExpireday] = useState<any>();
  const [getcheckValueProductType, setcheckValueProductType] = useState<any>([]);
  const [getcheckValueAccountLevel, setcheckValueAccountLevel] = useState<any>([]);
  const [getDetailProductLoan, setDetailProductLoan] = useState<ValueInDetail[]>([]);
  const [getDetailProductCard, setDetailProductCard] = useState<ValueInDetail[]>([]);
  const [getDetailProductOD, setDetailProductOD] = useState<ValueInDetail[]>([]);
  const [getFristBetweenPrincipalAmount, setFristBetweenPrincipalAmount] = useState<string>("");
  const [getEndBetweenPrincipalAmount, setEndBetweenPrincipalAmount] = useState<string>("");
  const [getFristBetweenOverduleAmount, setFristBetweenOverduleAmount] = useState<string>("");
  const [getEndBetweenOverduleAmount, setEndBetweenOverduleAmount] = useState<string>("");
  const [getTeam, setTeam] = useState<typeTeam[]>([
    {
      id: "",
      name: "",
      detail: [],
    },
  ]);
  const [getLocation, setLocation] = useState<ICenter[]>([]);
  const [getMasterDataLocation, setMasterDataLocation] = useState<IMasterDataOfBranch[]>([]);
  const [getconditionPrincipalAmount, setconditionPrincipalAmount] = useState<string>("select");
  const [getValueTeam, setValueTeam] = useState<string>("");
  const [getConditionStmtAmt, setConditionStmtAmt] = useState<string>("select");
  const [getDataStmtAmtSubmit, setDataStmtAmtSubmit] = useState<string>("");
  const [getFristBetweenStmtAmt, setFristBetweenStmtAmt] = useState<string>("");
  const [getEndBetweenStmtAmt, setEndBetweenStmtAmt] = useState<string>("");
  const [getConditionCurBal, setConditionCurBal] = useState<string>("select");
  const [getDataCurBalSubmit, setDataCurBalSubmit] = useState<string>("");
  const [getFristBetweenCurBal, setFristBetweenCurBal] = useState<string>("");
  const [getEndBetweenCurBal, setEndBetweenCurBal] = useState<string>("");

  const getTeamAllInPageBasic = async () => {
    const resultTeam = await DatamanagementService(apiBaseUrl).getTeamListBank("BANK");
    const sortTeam = resultTeam.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
    setTeam(sortTeam);
  };

  const onChangeTeam = (e: string) => {
    setValueTeam(e);
  };

  useEffect(() => {
    firstData();
  }, []);

  const firstData = () => {
    getProductCodefn();
    getTeamAllInPageBasic();
    setDetailDataLoanType(data[0]);
    setDetailDataAccountStatus(data[1]);
    setDetailDataProductType(data[2]);
    setDetailDataAccountLevel(data[3]);
    setDetailDataLocation(data[4]);
    setDetailDataAmount(data[5]);
    setValueLoanType(data[0].detail);
    setValueAccountStatus(data[1].detail);
    setValueProductType(data[2].detail);
    setDataBillingCycle(data[6].detail);
    setValueAccountLevel(data[3].detail);
    setValueLocation(data[4].detail);
    setValueAmount(data[5].detail);
    getCenter();
    MasterDataCenterOfBranch();
  };

  const MasterDataCenterOfBranch = async () => {
    const dataMasterdataResult = await DatamanagementService(apiBaseUrlDebt).masterDataCenterBranch();
    setMasterDataLocation(dataMasterdataResult);
  };

  async function getProductCodefn() {
    const resultProductCode = await DatamanagementService(apiBaseUrlDebt).getProductCode();
    resultProductCode.map((x: any, index: number) => {
      if (x.loanType === "LOAN") {
        getDetailProductLoan.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      } else if (x.loanType === "Card Link") {
        getDetailProductCard.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      } else if (x.loanType === "OD") {
        getDetailProductOD.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      }
    });
  }

  const fnCheckstmtAmt = (e: any) => {
    setConditionStmtAmt(e);
    if (String(e) === "select") {
      setDataStmtAmtSubmit("");
    }
  };

  const fnCheckCurBal = (e: any) => {
    setConditionCurBal(e);
    if (String(e) === "select") {
      setDataCurBalSubmit("");
    }
  };

  const getCenter = async () => {
    const resual: any = await DatamanagementService(apiBaseUrlDebt).getCenterList();
    setLocation(resual);
  };

  const onChangeAssignType = (e: any) => {
    setAssignType(e.target.value);
  };

  const onChangeExpridedType = (e: any) => {
    setExpridedType(e.target.value);
  };

  const data: DataType[] = [
    {
      id: 1,
      name: "Loan Type",
      detail: [
        {
          name: "",
          type: "select",
          value: ["LOAN", "Card Link", "OD"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 2,
      name: "Account Status",
      detail: [
        {
          name: "",
          type: "select",
          value: ["Non-Npls", "Npls", "W/O"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 3,
      name: "Product Type",
      detail: [
        {
          name: "LOAN",
          type: "select",
          value: getDetailProductLoan,
          condition: ["in"],
        },
        {
          name: "Card Link",
          type: "select",
          value: getDetailProductCard,
          condition: ["in"],
        },
        {
          name: "OD",
          type: "select",
          value: getDetailProductOD,
          condition: ["in"],
        },
      ],
    },
    {
      id: 4,
      name: "Account Level",
      detail: [
        {
          name: "Non-Npls",
          type: "select",
          value: ["P0", "P1", "SM2", "SM3"],
          condition: ["in"],
        },
        {
          name: "Npls",
          type: "select",
          value: [],
          condition: ["in"],
        },
        {
          name: "W/O",
          type: "select",
          value: [],
          condition: ["in"],
        },
      ],
    },
    {
      id: 5,
      name: "Location",
      detail: [
        {
          name: "",
          type: "select",
          value: getLocation,
          condition: ["in"],
        },
      ],
    },
    {
      id: 6,
      name: "เงินงวดค้างชำระ",
      detail: [
        {
          name: "",
          type: "input",
          value: [""],
          condition: [">", "<", "=", "!=", ">=", "<=", "between"],
        },
      ],
    },
    {
      id: 7,
      name: "Billing Cycle",
      detail: [
        {
          name: "billCycle",
          type: "select",
          value: ["10", "16", "17", "18", "20", "25", "31"],
          condition: ["in"],
        },
      ],
    },
  ];

  const checkLoanTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          setDataProductTypeSubmit(getValueProductType[0].value);
          break;
        case "Card Link":
          setDataProductTypeSubmit(getValueProductType[1].value);
          setDataBillingCycleSubmit(getDataBillingCycle[0].value);
          break;
        case "OD":
          setDataProductTypeSubmit(getValueProductType[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link": {
              let valueListin: ValueInDetail[] = getValueProductType[0].value;
              getValueProductType[1].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setDataProductTypeSubmit(valueListin);
              setDataBillingCycleSubmit(getDataBillingCycle[0].value);

              break;
            }
            case "OD": {
              let valueListin: ValueInDetail[] = getValueProductType[0].value;
              getValueProductType[2].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setDataProductTypeSubmit(valueListin);
              break;
            }
          }
          break;
        case "Card Link":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin: any = getValueProductType[1].value;
                getValueProductType[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setDataProductTypeSubmit(valueListin);
                setDataBillingCycleSubmit(getDataBillingCycle[0].value);

                break;
              }
              case "OD": {
                let valueListin = getValueProductType[1].value;
                getValueProductType[2].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setDataProductTypeSubmit(valueListin);
                setDataBillingCycleSubmit(getDataBillingCycle[0].value);

                break;
              }
            }
          }
          break;
        case "OD":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin = getValueProductType[2].value;
                getValueProductType[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setDataProductTypeSubmit(valueListin);
                break;
              }
              case "Card Link": {
                let valueListin = getValueProductType[2].value;
                getValueProductType[1].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setDataProductTypeSubmit(valueListin);
                setDataBillingCycleSubmit(getDataBillingCycle[0].value);

                break;
              }
            }
          }
          break;
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = getValueProductType[0].value;
                    getValueProductType[1].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getDataBillingCycle[0].value);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = getValueProductType[0].value;
                    getValueProductType[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getDataBillingCycle[0].value);
                  }
                  break;
              }
              break;
          }
          break;
        case "Card Link":
          switch (valueTwo) {
            case "LOAN":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = getValueProductType[1].value;
                    getValueProductType[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getDataBillingCycle[0].value);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = getValueProductType[1].value;
                    getValueProductType[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[0].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getDataBillingCycle[0].value);
                  }
                  break;
              }
              break;
          }
          break;
        case "OD":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = getValueProductType[2].value;
                    getValueProductType[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getDataBillingCycle[0].value);
                  }
                  break;
              }
              break;
            case "LOAN":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = getValueProductType[2].value;
                    getValueProductType[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getDataBillingCycle[0].value);
                  }
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setDataBillingCycleValue([]);
      setDataBillingCycleSubmit([]);
      setDataProductTypeSubmit([]);
      setcheckValueProductType([]);
    }
  };

  const checkProductTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          setDataAccountLevelSubmit(getValueAccountLevel[0].value);
          break;
        case "Npls":
          setDataAccountLevelSubmit(getValueAccountLevel[1].value);
          break;
        case "W/O":
          setDataAccountLevelSubmit(getValueAccountLevel[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
              getValueAccountLevel[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
            case "W/O": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
              getValueAccountLevel[2].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
          }
          break;
        case "Npls":
          {
            switch (valueTwo) {
              case "Non-Npls": {
                let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                getValueAccountLevel[0].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setDataAccountLevelSubmit(valueDataAccountLevel);
                break;
              }
              case "W/O": {
                let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                getValueAccountLevel[2].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setDataAccountLevelSubmit(valueDataAccountLevel);
                break;
              }
            }
          }
          break;
        case "W/O": {
          switch (valueTwo) {
            case "Non-Npls": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
              getValueAccountLevel[0].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
            case "Npls": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
              getValueAccountLevel[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
          }
        }
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
                  getValueAccountLevel[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
                  getValueAccountLevel[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "Npls":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                  getValueAccountLevel[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                  getValueAccountLevel[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "W/O":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
                  getValueAccountLevel[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
            case "Npls":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
                  getValueAccountLevel[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setDataAccountLevelSubmit([]);
      setcheckValueAccountLevel([]);
    }
  };

  const OPTIONS = data[0].detail[0].value;
  const filteredOptions = OPTIONS.filter((o: any) => !getDataLoanTypeSubmit.includes(o));

  const onChangeValueLoanType = (e: any) => {
    setDataLoanTypeSubmit(e);
    checkLoanTypeInput(e[0], e[1], e[2]);
  };

  const onChangAccount = (e: any) => {
    setDataAccountStatusSubmit(e);
    checkProductTypeInput(e[0], e[1], e[2]);
  };

  const onSavePage = (e: string) => {
    if (getDataLoanTypeSubmit.length === 0) {
      message.error("โปรดเลือกเงื่อนใข Loan Type");
      return true;
    }

    if (getExpireday === 0 || getExpireday === null || getExpireday === undefined || getExpireday === "") {
      refExpireDay.current.focus();
      message.error("กรุณากรอก Expire Day");
      return true;
    }

    if (getValueTeam.length === 0) {
      refValueTeam.current.focus();
      message.error("กรุณาเลือก Team");
      return true;
    }

    if (getconditionPrincipalAmount === "between") {
      if (!getFristBetweenPrincipalAmount || !getEndBetweenPrincipalAmount) {
        message.error("โปรดกรอกข้อมูลเงินต้นคงเหลือให้ครบถ้วน");
        return true;
      } else if (Number(getFristBetweenPrincipalAmount) > Number(getEndBetweenPrincipalAmount)) {
        message.error("เงินต้นคงเหลือ ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionAmount === "between") {
      if (!getFristBetweenOverduleAmount || !getEndBetweenOverduleAmount) {
        message.error("โปรดกรอกข้อมูลเงินงวดค้างชำระเหลือให้ครบถ้วน");
        return true;
      } else if (Number(getFristBetweenOverduleAmount) > Number(getEndBetweenOverduleAmount)) {
        message.error("เงินงวดค้างชำระ ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionStmtAmt === "between") {
      if (!getFristBetweenStmtAmt || !getEndBetweenStmtAmt) {
        message.error("โปรดกรอกข้อมูลยอดเรียกเก็บตาม statement");
        return true;
      } else if (Number(getFristBetweenStmtAmt) > Number(getEndBetweenStmtAmt)) {
        message.error("ยอดเรียกเก็บตาม statement ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionCurBal === "between") {
      if (!getFristBetweenCurBal || !getEndBetweenCurBal) {
        message.error("โปรดกรอกข้อมูลจำนวนการใช้เงินของบัตร ณ ปัจจุบัน");
        return true;
      } else if (Number(getFristBetweenCurBal) > Number(getEndBetweenCurBal)) {
        message.error("จำนวนการใช้เงินของบัตร ณ ปัจจุบัน ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    const statusSave = e;
    byPassDataToMainBasic(
      statusSave,
      getConditionLoantype,
      getConditionAccountStatus,
      getConditionProductType,
      getConditionAccountLevel,
      getConditionLocation,
      getConditionAmount,
      getConditionStmtAmt,
      getConditionCurBal,
      getDataLoanTypeSubmit,
      getDataAccountStatusSubmit,
      getcheckValueProductType,
      getcheckValueAccountLevel,
      getDataLocationSubmit,
      getDataAmountSubmit,
      getDataStmtAmtSubmit,
      getDataCurBalSubmit,
      getExpireday,
      getValueTeam,
      getAssignType,
      getconditionPrincipalAmount,
      getDataPrincipalAmount,
      getExpridedType,
      getFristBetweenPrincipalAmount,
      getEndBetweenPrincipalAmount,
      getFristBetweenOverduleAmount,
      getEndBetweenOverduleAmount,
      getFristBetweenStmtAmt,
      getEndBetweenStmtAmt,
      getFristBetweenCurBal,
      getEndBetweenCurBal,
      getDataBillingCycleValue,
    );
  };

  const onClickProductType = (e: any) => {
    if (getDataLoanTypeSubmit.length === 0) {
      refValueLoanType.current.focus();
      return false;
    }
  };

  const onClickAccountLevel = (e: any) => {
    if (getDataAccountStatusSubmit.length === 0) {
      refValueAccountStatus.current.focus();
      return false;
    }
  };
  const onClickBillingCycle = (e: any) => {
    if (getDataBillingCycle.length === 0) {
      refBillingCycle.current.focus();
      return false;
    }
  };

  const checkCondition = (text: string) => {
    if (text === "8") {
      if (getConditionAmount === "select") {
        refValueConditionAmount.current.focus();
        message.error("โปรดเลือก Condition ของ เงินงวดค้างชำระ");
        return;
      }
    } else if (text === "9") {
      if (getConditionStmtAmt === "select") {
        refValueConditionStmtAmt.current.focus();
        message.error("โปรดเลือก Condition ของ ยอดเรียกเก็บตาม statement");
        return;
      }
    } else if (text === "10") {
      if (getConditionCurBal === "select") {
        refValueConditionCurBal.current.focus();
        message.error("โปรดเลือก Condition ของ จำนวนการใช้เงินของบัตร ณ ปัจจุบัน");
        return;
      }
    }
  };

  const checkConditionPrincipal = () => {
    if (getconditionPrincipalAmount === "select") {
      refValuePrincipalAmount.current.focus();
      message.error("โปรดเลือก Condition ของ เงินต้นคงเหลือ");
      return;
    }
  };

  const checkconditionsAmount = (e: any) => {
    setConditionAmount(e);
    if (String(e) === "select") {
      setDataAmountSubmit("");
    }
  };

  const fnCheckPrincipleAmount = (e: any) => {
    setconditionPrincipalAmount(e);
    if (String(e) === "select") {
      setDataPrincipalAmount("");
    }
  };
  const onChangTreevalue = (e: any) => {
    setcheckValueProductType(e);
  };
  const treeData = [
    {
      title: "เลือกทั้งหมด",
      value: "001",
      key: "001",
      children: getDataProductTypeSubmit,
    },
  ];
  const tProps = {
    treeData,
    value: getcheckValueProductType,
    onChange: onChangTreevalue,
    allowClear: true,
    treeCheckable: true,
  };
  return (
    <>
      <Row gutter={10}>
        <Col span={2} style={{ textAlign: "left" }}>
          No.
        </Col>
        <Col span={10} style={{ textAlign: "left" }}>
          Field
        </Col>
        <Col span={12} style={{ textAlign: "left" }}>
          Value
        </Col>
      </Row>
      <br></br>
      <Row style={{ marginTop: "10px" }} gutter={10}>
        <Col span={2}>
          <Typography>{data[0].id}</Typography>
        </Col>

        <Col span={10}>
          <Input value={data[0].name} readOnly />
        </Col>
        <Col span={12}>
          <Select
            mode="multiple"
            style={{ width: " 100%" }}
            onChange={onChangeValueLoanType}
            ref={refValueLoanType}
            allowClear
            removeIcon
            showArrow={false}
          >
            {filteredOptions.map((value: any, index: any) => {
              return (
                <Option key={value} value={String(value)}>
                  {value}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      {/* row 2 */}
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>{data[1].id}</Typography>
        </Col>
        <Col span={10}>
          <Input value={data[1].name} readOnly />
        </Col>
        <Col span={12}>
          <Select
            mode="multiple"
            style={{ width: " 100%" }}
            onChange={onChangAccount}
            ref={refValueAccountStatus}
            // placeholder="please select"
          >
            {data[1].detail[0].value.map((accoutnvalue: any, index: any) => {
              return (
                <Option key={index} value={String(accoutnvalue)}>
                  {accoutnvalue}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      {/* row 3 */}
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>{data[2].id}</Typography>
        </Col>
        <Col span={10}>
          <Input value={data[2].name} readOnly />
        </Col>
        <Col span={12}>
          <TreeSelect
            treeNodeFilterProp="title"
            showArrow
            treeDefaultExpandAll
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            disabled={Object.keys(getDataLoanTypeSubmit).length === 1 && getDataLoanTypeSubmit[0] === "OD" ? true : false}
            onClick={onClickProductType}
            style={{ width: " 100%" }}
            {...tProps}
            maxTagCount={"responsive"}
          />
        </Col>
      </Row>
      {/* row 7 */}
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>{data[3].id}</Typography>
        </Col>
        <Col span={10}>
          <Input value={data[6].name} readOnly />
        </Col>
        <Col span={12}>
          <Select
            style={{ width: " 100%" }}
            mode="multiple"
            ref={refBillingCycle}
            onClick={onClickBillingCycle}
            onChange={(e) => {
              setDataBillingCycleValue(e);
            }}
            value={getDataBillingCycleValue}
            // placeholder="please select"
          >
            {getDataBillingCycleSubmit.map((bilcycle: any, index: any) => {
              return (
                <Option key={index} value={bilcycle}>
                  {bilcycle}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      {/* row 4 */}
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>{data[4].id}</Typography>
        </Col>
        <Col span={10}>
          <Input value={data[3].name} readOnly />
        </Col>
        <Col span={12}>
          <Select
            style={{ width: " 100%" }}
            mode="multiple"
            ref={refValueAccountLevel}
            onClick={onClickAccountLevel}
            onChange={(e) => {
              setcheckValueAccountLevel(e);
            }}
            value={getcheckValueAccountLevel}
            // placeholder="please select"
          >
            {getDataAccountLevelSubmit.map((x: any, index: any) => {
              return (
                <Option key={index} value={String(x)}>
                  {x}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      {/* row 5 */}
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>{data[5].id}</Typography>
        </Col>
        <Col span={10}>
          <Input value={data[4].name} readOnly />
        </Col>
        <Col span={12}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
            style={{ width: " 100%" }}
            mode="multiple"
            onChange={(e) => {
              setDataLocationSubmit(e);
            }}
            ref={refValueLocation}
          >
            {getMasterDataLocation?.map((event: IMasterDataOfBranch, index: number) => {
              return (
                <Option key={event.team_group_id} value={String(event.team_group_id)}>
                  {event.desc_local}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      {/* row 6 */}
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>7</Typography>
        </Col>
        <Col span={5}>
          <Input value={"เงินต้นคงเหลือ"} readOnly />
        </Col>
        <Col span={5}>
          <Select
            style={{ width: " 100%" }}
            // onChange={(e) => setconditionPrincipalAmount(e)}
            onChange={(e) => fnCheckPrincipleAmount(e)}
            value={getconditionPrincipalAmount}
            ref={refValuePrincipalAmount}
          >
            <Option value={"select"}>select</Option>
            {data[5].detail[0].condition.map((condition: any, index: any) => {
              return (
                <Option key={index} value={String(condition)}>
                  {condition}
                </Option>
              );
            })}
          </Select>
        </Col>
        {getconditionPrincipalAmount === "between" ? (
          <>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setFristBetweenPrincipalAmount(value);
                }}
                value={getFristBetweenPrincipalAmount}
              />
            </Col>
            <Col span={2}>
              <Input value={"ถึง"} readOnly />
            </Col>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setEndBetweenPrincipalAmount(value);
                }}
                value={getEndBetweenPrincipalAmount}
              />
            </Col>
          </>
        ) : (
          <Col span={12}>
            <NumberFormat
              className="ant-input"
              thousandSeparator={true}
              value={getDataPrincipalAmount}
              decimalScale={2}
              min={0}
              onClick={checkConditionPrincipal}
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                setDataPrincipalAmount(value);
              }}
            />
          </Col>
        )}
      </Row>
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>8</Typography>
        </Col>
        <Col span={5}>
          <Input value={data[5].name} readOnly />
        </Col>
        <Col span={5}>
          <Select style={{ width: " 100%" }} onChange={(e) => checkconditionsAmount(e)} value={getConditionAmount} ref={refValueConditionAmount}>
            <Option value={"select"}>select</Option>
            {data[5].detail[0].condition.map((condition: any, index: any) => {
              return (
                <Option key={index} value={String(condition)}>
                  {condition}
                </Option>
              );
            })}
          </Select>
        </Col>
        {getConditionAmount === "between" ? (
          <>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setFristBetweenOverduleAmount(value);
                }}
                value={getFristBetweenOverduleAmount}
              />
            </Col>
            <Col span={2}>
              <Input value={"ถึง"} readOnly />
            </Col>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setEndBetweenOverduleAmount(value);
                }}
                value={getEndBetweenOverduleAmount}
              />
            </Col>
          </>
        ) : (
          <Col span={12}>
            <NumberFormat
              decimalScale={2}
              className="ant-input"
              thousandSeparator={true}
              value={getDataAmountSubmit}
              min={0}
              onClick={() => checkCondition("8")}
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                setDataAmountSubmit(value);
              }}
            />
          </Col>
        )}
      </Row>
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>9</Typography>
        </Col>
        <Col span={5}>
          <Input value={`ยอดเรียกเก็บตาม statement`} readOnly />
        </Col>
        <Col span={5}>
          <Select style={{ width: " 100%" }} onChange={(e) => fnCheckstmtAmt(e)} value={getConditionStmtAmt} ref={refValueConditionStmtAmt}>
            <Option value={"select"}>select</Option>
            {data[5].detail[0].condition.map((condition: any, index: any) => {
              return (
                <Option key={index} value={String(condition)}>
                  {condition}
                </Option>
              );
            })}
          </Select>
        </Col>
        {getConditionStmtAmt === "between" ? (
          <>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setFristBetweenStmtAmt(value);
                }}
                value={getFristBetweenStmtAmt}
              />
            </Col>
            <Col span={2}>
              <Input value={"ถึง"} readOnly />
            </Col>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setEndBetweenStmtAmt(value);
                }}
                value={getEndBetweenStmtAmt}
              />
            </Col>
          </>
        ) : (
          <Col span={12}>
            <NumberFormat
              decimalScale={2}
              className="ant-input"
              thousandSeparator={true}
              value={getDataStmtAmtSubmit}
              min={0}
              onClick={() => checkCondition("9")}
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                setDataStmtAmtSubmit(value);
              }}
            />
          </Col>
        )}
      </Row>
      <br></br>
      <Row gutter={10}>
        <Col span={2}>
          <Typography>10</Typography>
        </Col>
        <Col span={5}>
          <Input value={`จำนวนการใช้เงินของบัตร ณ ปัจจุบัน`} readOnly />
        </Col>
        <Col span={5}>
          <Select style={{ width: " 100%" }} onChange={(e) => fnCheckCurBal(e)} value={getConditionCurBal} ref={refValueConditionCurBal}>
            <Option value={"select"}>select</Option>
            {data[5].detail[0].condition.map((condition: any, index: any) => {
              return (
                <Option key={index} value={String(condition)}>
                  {condition}
                </Option>
              );
            })}
          </Select>
        </Col>
        {getConditionCurBal === "between" ? (
          <>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setFristBetweenCurBal(value);
                }}
                value={getFristBetweenCurBal}
              />
            </Col>
            <Col span={2}>
              <Input value={"ถึง"} readOnly />
            </Col>
            <Col span={5}>
              <NumberFormat
                className="ant-input"
                thousandSeparator={true}
                decimalScale={2}
                min={0}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values;
                  setEndBetweenCurBal(value);
                }}
                value={getEndBetweenCurBal}
              />
            </Col>
          </>
        ) : (
          <Col span={12}>
            <NumberFormat
              decimalScale={2}
              className="ant-input"
              thousandSeparator={true}
              value={getDataCurBalSubmit}
              min={0}
              onClick={() => checkCondition("10")}
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                setDataCurBalSubmit(value);
              }}
            />
          </Col>
        )}
      </Row>

      {/* row 7 */}
      {Object.keys(getDataLoanTypeSubmit).length === 1 ? (
        getDataLoanTypeSubmit[0] === "Card Link" && Object.keys(getDataAccountStatusSubmit).length === 1 ? (
          getDataAccountStatusSubmit[0] === "Non-Npls" ? (
            <Form.Item style={{ marginTop: "10px" }} label="Expire Day:" name={"expireday"} required>
              <DatePicker
                locale={datePickerTh}
                ref={refExpireDay}
                disabledDate={(current: any) => {
                  return moment().add(-1, "days") >= current;
                }}
                style={{ width: "20%" }}
                format={"DD-MM-BBBB"}
                onChange={(e: any) => {
                  if (e) {
                    let dataFormatDate: any = dayjs(e).format("YYYY-MM-DD");
                    return setExpireday(dataFormatDate);
                  } else {
                    setExpireday("");
                  }
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item style={{ marginTop: "10px" }} label="Expire Day:" name={"expireday"} required>
              <InputNumber type="number" min={1} placeholder="1" value={getExpireday} onChange={(e: any) => setExpireday(e)} ref={refExpireDay} />
            </Form.Item>
          )
        ) : (
          <Form.Item style={{ marginTop: "10px" }} label="Expire Day:" name={"expireday"} required>
            <InputNumber type="number" min={1} placeholder="1" value={getExpireday} onChange={(e: any) => setExpireday(e)} ref={refExpireDay} />
          </Form.Item>
        )
      ) : (
        <Form.Item style={{ marginTop: "10px" }} label="Expire Day:" name={"expireday"} required>
          <InputNumber type="number" min={1} placeholder="1" value={getExpireday} onChange={(e: any) => setExpireday(e)} ref={refExpireDay} />
        </Form.Item>
      )}

      <Form.Item style={{ marginTop: "10px" }} label="Assign Type:" name={"assigntype"}>
        <Radio.Group name="AssignType" value={getAssignType} onChange={onChangeAssignType} defaultValue={getAssignType}>
          <Radio value={"Manual"}>Manual</Radio>
          <Radio value={"Auto"}>Auto</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Team" name={"team"} required>
        <Select
          // mode="multiple"
          style={{ width: "20%" }}
          placeholder="Select Team"
          showSearch
          optionFilterProp="children"
          filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
          onChange={onChangeTeam}
          value={getValueTeam}
          ref={refValueTeam}
        >
          {getTeam?.map((x: typeTeam, index: number) => {
            return (
              <Option value={x.id} key={index + 1}>
                {x.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Row gutter={10}>
        <Col>
          <Form.Item>
            <Button type="primary" onClick={() => onSavePage("DRAFT")}>
              Save Draft
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Button style={{ backgroundColor: "#04C696", color: "white" }} onClick={() => onSavePage("WAITING_APPROVAL")}>
            Request Approval
          </Button>
        </Col>
      </Row>
    </>
  );
};
