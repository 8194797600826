import React, { useState, useRef } from "react";
import { Button, Form, Input, InputNumber, Modal, Select, Space, Table, message, Tabs, Spin } from "antd";
import { OaProfileService } from "../services/oa-profile-service";
import { EditOutlined, PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import TextArea from "antd/lib/input/TextArea";
import notification, { NotificationPlacement } from "antd/lib/notification";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { ContractType, IOaUpdate, IOrganize, ISuborganize, IUser } from "../common";
import { TableaddnewOalist } from "./TableAddnewOalist";
import { DateTH } from "../../../utils/time-local";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import "moment/locale/th";
import { config, datePickerTh } from "../../../utils";

const DatePicker = generatePicker(config);
const appConfig = loadAppConfig();
const { Option } = Select;

interface Props {
  proFile: IUser;
  setLoading: (status: boolean) => void;
  oaProfile: any;
  userOaList: any;
  fetchData: () => void;
}

export const TableOaProfile: React.FC<Props> = ({ proFile, setLoading, oaProfile, userOaList, fetchData }) => {
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const [form] = Form.useForm();
  const [formmanager] = Form.useForm();
  const [statusModalAction, setStatusModalAction] = useState<boolean>(false);
  const [dataSourceContract, setDataSourceContract] = useState<ContractType[]>([]);
  const refadmin = useRef(null);
  const [contractTypeData, setContractTypeData] = useState<ContractType[]>(dataSourceContract);
  const [editingKey, setEditingKey] = useState("");
  const [dataSourceContractCheck, setDataSourceContractCheck] = useState<ContractType[]>([]);
  const [oaId, setOaId] = useState<string>("");
  const [dataOaId, setDataOaId] = useState("");
  const [dataOaCode, setDataOaCode] = useState("");
  const [dataadminId, setdatanadminId] = useState<any>("");
  const [selectSuborganize, setSelectSuborganize] = useState<ISuborganize[]>([]);
  const [getdataUser, setdataUser] = useState<IUser[]>([]);
  const [count, setCount] = useState("");
  const [selectOrganize, setSelectOrganize] = useState<IOrganize[]>([]);

  const validatePhone = (number: string) => {
    if (!number) {
      return true;
    }
    return /^[0-9]{1,10}$/.test(number);
  };

  const handleCancel = () => {
    formmanager.resetFields();
    form.resetFields();
    setStatusModalAction(false);
  };

  const columnsData: any = [
    {
      title: "OA Name",
      dataIndex: "oaName",
      key: "oaName",
      width: "20%",
    },
    {
      title: "ผู้ดูแล",
      dataIndex: "nameAdmin",
      key: "nameAdmin",
      render: (event: any, row: any) => {
        return <>{row?.idAdmin === 0 ? "-" : event}</>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Phone Number",
      dataIndex: "contactPhone",
      key: "contactPhone",
    },
    {
      title: "Contract Name",
      dataIndex: "contactName",
      key: "contactName",
    },
    {
      title: "เลขที่สัญญา",
      dataIndex: "contractNo",
      key: "contractNo",
      width: "5%",
    },
    {
      title: "วงเงิน",
      dataIndex: "contractCredit",
      key: "contractCredit",
      width: "5%",
      align: "right" as const,
      render: (data: number, row: any, index: number) => {
        return data ? <NumberFormat value={data} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} /> : "-";
      },
    },
    {
      title: "Contract Start Date",
      dataIndex: "contractStartDate",
      key: "contractStartDate",
      render: (data: string, row: any, index: number) => {
        return data ? DateTH(new Date(data), "DD-MM-BBBB") : "-";
      },
    },
    {
      title: "Contract End Date",
      dataIndex: "contractEndDate",
      key: "contractEndDate",
      render: (data: string, row: any, index: number) => {
        return data ? DateTH(new Date(data), "DD-MM-BBBB") : "-";
      },
    },
    {
      title: "Update By",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Update Date",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (data: string, row: any, index: number) => {
        return data ? DateTH(new Date(data), "DD-MM-BBBB HH:mm:ss") : "-";
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: "4%",
      align: "center",
      render: (id: string, row: IOaUpdate, index: number) => {
        return (
          <div style={{ textAlign: "center" }}>
            <a onClick={() => openModal(row)}>
              <EditOutlined />
            </a>
          </div>
        );
      },
    },
  ];

  const addContract = async () => {
    const dataAdd: any = [
      ...contractTypeData,
      {
        key: count,
        id: count,
        contractNo: "",
        contractCredit: 0,
        contractStartDate: "",
        contractEndDate: "",
        contractStatus: null,
      },
    ];

    setContractTypeData(dataAdd);
    setCount(String(dataAdd.length));
    setDataSourceContract(dataAdd);
  };

  const openModal = async (row: IOaUpdate) => {
    const resOrganize = await OaProfileService(apiBaseUrlUser).findOrganize(row.oaCode);
    const resSuborganize = await OaProfileService(apiBaseUrlUser).findsuborganize(row.oaCode);
    const resUserByOa = await OaProfileService(apiBaseUrlUser).getuserByoacode(row.oaCode);
    resUserByOa.sort((a: any, b: any) => a.id - b.id);
    setdataUser(resUserByOa);
    setSelectOrganize(resOrganize);
    setSelectSuborganize(resSuborganize);
    setDataOaId(row.oaCode);
    setDataOaCode(row.oaCode);
    setdatanadminId(row.idAdmin);
    setOaId(row.oaId);
    getContract(row.oaId, row.oaCode);
    form.setFieldValue("oaCode", row.oaCode);
    form.setFieldValue("oaName", row.oaName);
    form.setFieldValue("agentcyId", row.agentcyId);
    form.setFieldValue("address", row.address);
    form.setFieldValue("email", row.email);
    form.setFieldValue("contactPhone", row.contactPhone);
    form.setFieldValue("contactName", row.contactName);
    form.setFieldValue("adminUserId", row.idAdmin === 0 ? undefined : row.idAdmin);
    setStatusModalAction(true);
  };

  async function getContract(oaId: string, oaCode: string) {
    const resOaContact = await OaProfileService(apiBaseUrlUser).getOaContract(oaId, oaCode);
    setDataSourceContractCheck(resOaContact);

    const newArray = resOaContact?.map((ct: any, index: number) => {
      return {
        key: index,
        id: index,
        contractNo: ct.contractNo,
        contractCredit: Number(ct.contractCredit),
        contractStartDate: ct.contractStartDate,
        contractEndDate: ct.contractEndDate,
        contractStatus: ct.contractStatus,
      };
    });
    setContractTypeData(newArray);
    setCount(newArray.length);
    setDataSourceContract(newArray);
  }

  const onFinish = async (data: any) => {
    if (data.adminUserId === 0 || data.adminUserId === undefined) {
      message.warning("หากเป็น OA ใหม่ กรุณาสร้าง OA Manager ก่อนแก้ไขข้อมูล");
      return false;
    }
    const result = {
      address: data.address,
      adminUserId: String(data.adminUserId),
      agentcyId: data.agentcyId,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      email: data.email,
      oaCode: data.oaCode,
      oaName: data.oaName,
      updatedBy: String(proFile.id),
    };
    const dataadminID = {
      oaCode: data.oaCode,
      userId: data.adminUserId,
    };
    const resTranferData = await OaProfileService(apiBaseUrlDebt).tranferData(dataadminID);
    if (resTranferData) {
      const resUpdateOaProfile = await OaProfileService(apiBaseUrlUser).updateOaProfile(result);
      if (resUpdateOaProfile) {
        fetchData();
        setStatusModalAction(false);
        openNotification("top");
        form.resetFields();
      }
    }
  };

  const handlesubmitaddManager = (values: any) => {
    const dataSubmit = {
      active: true,
      oaCode: dataOaCode,
      oaId: oaId,
      id: values.id,
      name: values.nameManager,
      username: values.nameManager,
      email: values.emailManager,
      phone: values.phoneManager,
      setorganize: values.organizeManager,
      setroles: values.rolesManager,
    };

    Modal.confirm({
      title: "ยืนยัน",
      icon: <CheckCircleOutlined />,
      content: "ยืนยันการแก้ไขข้อมูลติดต่อ",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        const resCreate = await OaProfileService(apiBaseUrlUser).createUser(dataSubmit);
        if (resCreate) {
          if (resCreate?.status === 400 && resCreate?.message === `Username or Email duplicated`) {
            message.error(`มีชื่อผู้ใช้งาน หรืออีเมลล์นี้แล้วในระบบ`);
          } else if (resCreate.status === 400) {
            message.error(`${resCreate.message}`);
          } else {
            message.success(`ทำการสร้าง Manager สำเร็จ`);
            fetchData();
            setStatusModalAction(false);
            formmanager.resetFields();
          }
        }
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  const checkDataContact = (row: any) => {
    if (!row.contractNo) {
      return "กรุณาระบุ เลขที่สัญญา";
    }
    if (row.contractCredit == null || row.contractCredit == "") {
      return "กรุณาระบุ วงเงิน";
    }
    if (row.contractCredit < 0) {
      return "กรุณาระบุ วงเงิน ตั้งเเต่ 0 ขึ้นไป";
    }
    if (!row.contractStartDate) {
      return "กรุณาระบุ วันที่ทำสัญญา";
    }
    if (row.contractStartDate == "Invalid Date") {
      return "กรุณาระบุ วันที่ทำสัญญา";
    }
    if (!row.contractEndDate) {
      return "กรุณาระบุ วันที่สิ้นสุดทำสัญญา";
    }
    if (row.contractEndDate == "Invalid Date") {
      return "กรุณาระบุ วันที่สิ้นสุดทำสัญญา";
    }
    if (row.contractStartDate > row.contractEndDate) {
      return "กรุณาระบุวันที่สิ้นสุดทำสัญญา มากกว่า วันที่ทำสัญญา หรือ วันเดียวกัน";
    }
    if (row.contractStatus === null || row.contractStatus === "") {
      return "กรุณาระบุสถานะ";
    }
    return true;
  };

  const confirmContract = async (id: number, row: any) => {
    const checkCondition = checkDataContact(row);
    const dataCheck = contractTypeData?.filter((e: any) => e.contractStatus === true);
    if ((dataCheck.length === 1 && row.contractStatus === "active") || (dataCheck.length > 1 && row.contractStatus === true)) {
      message.warning("ขณะนี้มีสัญญาที่มีสถานะ avtive มากกว่า 1 สัญญา , โปรดเลือกสัญญาที่มี สถานะ active เพียงแค่สัญญาเดียว");
      return false;
    }
    if (checkCondition !== true) {
      message.warning(checkCondition);
    } else {
      openNotification("top");
      let checkEdite = "";
      dataSourceContractCheck.find((x, index) => {
        if (index == id) {
          checkEdite = x.contractNo;
        }
      });
      let status = row.contractStatus;
      if (row.contractStatus == "active") {
        status = true;
      }
      if (row.contractStatus == "inactive") {
        status = false;
      }

      if (status == "true") {
        status = true;
      }

      if (status == "false") {
        status = false;
      }

      let contactCre = row.contractCredit;
      if (typeof contactCre == "object") {
        contactCre = row.contractCredit.target.value.replaceAll(",", "");
      }

      let contractN = row.contractNo;
      if (typeof contractN == "object") {
        contractN = row.contractNo.target.value;
      }

      if (contractN == "true") {
        contractN = true;
      }

      if (contractN == "false") {
        contractN = false;
      }

      let newArr = [...contractTypeData];
      newArr[id].contractNo = contractN;
      newArr[id].contractCredit = contactCre;
      newArr[id].contractStartDate = row.contractStartDate;
      newArr[id].contractEndDate = row.contractEndDate;
      newArr[id].contractStatus = status;
      setContractTypeData(newArr);
      setCount(String(newArr.length));

      const dataContract = contractTypeData.find((contract: ContractType) => String(contract.id) === String(id));
      if (dataContract) {
        const dataCreate = {
          contractNoCheck: checkEdite,
          contractNo: row.contractNo,
          contractCredit: Number(row.contractCredit),
          contractStatus: row.contractStatus,
          contractStartDate: row.contractStartDate,
          contractEndDate: row.contractEndDate,
        };
        OaProfileService(apiBaseUrlUser).createContract(dataOaCode, dataCreate);
        setContractTypeData(contractTypeData);
      }
    }
  };

  const contactNoChange = (share: any, row: any, index: number) => {
    let contractN = share;
    if (typeof contractN == "object") {
      contractN = share.target.value;
    }
    let newArr = [...contractTypeData];
    newArr[index].contractNo = contractN;
  };
  const contactCreditChange = (share: any, row: any, index: number) => {
    let newArr = [...contractTypeData];
    newArr[index].contractCredit = share;
  };
  const contractStartDateChange = (share: any, row: any, index: number) => {
    let newArr = [...contractTypeData];
    newArr[index].contractStartDate = share;
  };
  const contractEndDateChange = (share: any, row: any, index: number) => {
    let newArr = [...contractTypeData];
    newArr[index].contractEndDate = share;
  };
  const contractStatusChange = (stat: any, row: any, index: number) => {
    let status = stat.value;
    let newArr = [...contractTypeData];
    if (status === true) {
      status = "active";
    }
    if (status === false) {
      status = "inactive";
    }
    newArr[index].contractStatus = status;
  };

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: ContractType;
    index: number;
    children: React.ReactNode;
  }

  const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record: ContractType) => record.key === editingKey;

  const defaultColumns = [
    {
      title: "เลขที่สัญญา",
      dataIndex: "contractNo",
      key: "contractNo",
      editable: true,
      render: (data: string, row: any, index: number) => {
        if (data) {
          return (
            <Input
              placeholder="Please Select"
              defaultValue={data}
              className="ant-input"
              style={{ width: "100%" }}
              onChange={(e: any) => {
                contactNoChange(e, row, index);
              }}
            />
          );
        } else {
          return (
            <Input
              placeholder="Please Select"
              style={{ width: "100%" }}
              className="ant-input"
              onChange={(e: any) => {
                contactNoChange(e, row, index);
              }}
            />
          );
        }
      },
    },
    {
      title: "วงเงิน",
      dataIndex: "contractCredit",
      key: "contractCredit",
      editable: true,
      render: (data: number, row: any, index: number) => {
        if (data) {
          return (
            <NumberFormat
              placeholder="Please Select"
              defaultValue={data}
              className="ant-input"
              style={{ width: "100%" }}
              decimalScale={2}
              thousandSeparator={true}
              fixedDecimalScale={true}
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                contactCreditChange(value, row, index);
              }}
            />
          );
        } else {
          return (
            <NumberFormat
              placeholder="Please Select"
              defaultValue={data}
              className="ant-input"
              style={{ width: "100%" }}
              decimalScale={2}
              thousandSeparator={true}
              fixedDecimalScale={true}
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                contactCreditChange(value, row, index);
              }}
            />
          );
        }
      },
    },
    {
      title: "วันที่ทำสัญญา",
      dataIndex: "contractStartDate",
      key: "contractStartDate",
      editable: true,
      render: (data: any, row: any, index: number) => {
        if (data) {
          return (
            <DatePicker
              locale={datePickerTh}
              defaultValue={dayjs(data)}
              format={"DD-MM-BBBB"}
              style={{ width: "100%" }}
              onChange={(e: any) => {
                if (e === null) {
                  contractStartDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                } else {
                  contractStartDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                }
              }}
            />
          );
        } else {
          return (
            <DatePicker
              locale={datePickerTh}
              format={"DD-MM-BBBB"}
              style={{ width: "100%" }}
              onChange={(e: any) => {
                if (e === null) {
                  contractStartDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                } else {
                  contractStartDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                }
              }}
            />
          );
        }
      },
    },
    {
      title: "วันที่สิ้นสุดทำสัญญา",
      dataIndex: "contractEndDate",
      key: "contractEndDate",
      editable: true,
      render: (data: string, row: any, index: number) => {
        if (data) {
          return (
            <DatePicker
              defaultValue={dayjs(data)}
              format={"DD-MM-BBBB"}
              style={{ width: "100%" }}
              onChange={(e: any) => {
                if (e === null) {
                  contractEndDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                } else {
                  contractEndDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                }
              }}
            />
          );
        } else {
          return (
            <DatePicker
              format={"DD-MM-BBBB"}
              style={{ width: "100%" }}
              onChange={(e: any) => {
                if (e === null) {
                  contractEndDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                } else {
                  contractEndDateChange(dayjs(e).format("YYYY-MM-DD"), row, index);
                }
              }}
            />
          );
        }
      },
    },
    {
      title: "status",
      dataIndex: "contractStatus",
      key: "contractStatus",
      editable: true,
      render: (data: boolean, row: any, index: number) => {
        if (data == true) {
          return (
            <Select
              defaultValue="active"
              placeholder={"Please Select"}
              onChange={(e: any, option: any) => {
                contractStatusChange(option, row, index);
              }}
            >
              <Option key={-1} disabled>
                Please Select
              </Option>
              <Option key={1} value="active">
                Active
              </Option>
              <Option key={2} value="inactive">
                InActive
              </Option>
            </Select>
          );
        } else if (data == false) {
          return (
            <Select
              defaultValue={false}
              disabled={true}
              placeholder={"Please Select"}
              onChange={(e: any, option: any) => {
                contractStatusChange(option, row, index);
              }}
            >
              <Option key={-1} disabled>
                Please Select
              </Option>
              <Option key={1} value={true}>
                Active
              </Option>
              <Option key={2} value={false}>
                InActive
              </Option>
            </Select>
          );
        } else {
          return (
            <Select
              defaultValue={null}
              placeholder={"Please Select"}
              onChange={(e: any, option: any) => {
                contractStatusChange(option, row, index);
              }}
            >
              <Option key={1} value={true}>
                Active
              </Option>
              <Option key={2} value={false}>
                InActive
              </Option>
            </Select>
          );
        }
      },
    },
    {
      title: "confirm",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <Button
            size="small"
            type="primary"
            disabled={record.contractStatus === false ? true : false}
            onClick={() => confirmContract(record.key, record)}
          >
            save
          </Button>
        );
      },
    },
  ];

  const mergedColumns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ContractType) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `update success`,
    });
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <Table size="small" rowKey={"oaCode"} columns={columnsData} scroll={{ x: "calc(850px + 100%)" }} dataSource={oaProfile} />
      <Modal
        title="Edit OA"
        open={statusModalAction}
        onCancel={handleCancel}
        footer={null}
        width={1200}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Tabs defaultActiveKey="1" type="card">
          <Tabs.TabPane tab="Edit OA" key="1">
            <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item hidden label="ID" name="oaCode">
                <Input placeholder="id" style={{ width: "90%" }} />
              </Form.Item>
              <Form.Item
                label="รหัสบริษัท"
                name="agentcyId"
                rules={[
                  {
                    required: true,
                    message: "Please input Name",
                  },
                ]}
              >
                <Input maxLength={3} placeholder="รหัสบริษัท" style={{ width: "90%" }} />
              </Form.Item>
              <Form.Item
                label="OA Name"
                name="oaName"
                rules={[
                  {
                    required: true,
                    message: "Please input Name",
                  },
                ]}
              >
                <Input placeholder="OA Name" style={{ width: "90%" }} />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please input Address",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Text" style={{ width: "90%" }} />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input Email or is not valid E-mail!",
                  },
                ]}
              >
                <Input placeholder="Email" style={{ width: "90%" }} />
              </Form.Item>
              {getdataUser.length != 0 ? (
                <Form.Item label="ผู้ดูแล" name="adminUserId">
                  <Select ref={refadmin} allowClear placeholder="Please Select" style={{ width: "90%" }}>
                    <Option value={""} key={""} disabled>
                      Please Select
                    </Option>
                    {getdataUser
                      .filter((e: any) => e.oaCode === dataOaId && e.active === true)
                      .map((data: IUser, index: number) => {
                        return (
                          <>
                            <Option key={data.id} value={data.id}>
                              {data.name}
                            </Option>
                          </>
                        );
                      })}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="ผู้ดูแล" name="adminUserId" hidden={true}>
                  <Select allowClear placeholder="Admin Name" style={{ width: "90%" }}>
                    <Option disabled>Please Select</Option>
                    {getdataUser
                      ?.filter((e: any) => e.oaCode === dataOaId && e.active === true)
                      .map((data: IUser, index: number) => {
                        return (
                          <>
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          </>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label="Phone Number"
                name="contactPhone"
                rules={[
                  {
                    required: true,
                    validator: async (_, storeValue) => {
                      if (validatePhone(storeValue)) {
                        return Promise.resolve(storeValue);
                      }
                      return Promise.reject(new Error("Please Input Phone Number"));
                    },
                  },
                ]}
              >
                <Input placeholder="Phone Number" style={{ width: "90%" }} maxLength={10} />
              </Form.Item>
              <Form.Item
                label="Contract Name"
                name="contactName"
                rules={[
                  {
                    required: true,
                    message: "Please input Phone Contract Name",
                  },
                ]}
              >
                <Input placeholder="Contract Name" style={{ width: "90%" }} />
              </Form.Item>
              <Form.Item label="Contract">
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    onClick={addContract}
                    type="primary"
                    style={{
                      width: "50%",
                      borderRadius: "50%",
                    }}
                  >
                    <Space
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <PlusOutlined />
                    </Space>
                  </Button>
                </Space>
                <Table
                  scroll={{ x: "calc(800px + 0%)" }}
                  size="small"
                  rowKey={"id"}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={contractTypeData}
                  columns={mergedColumns}
                  pagination={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
                  Save
                </Button>
                <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
                  Cancel
                </Button>
              </Space>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="New OA Manager" key="2">
            <Form
              form={formmanager}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 17 }}
              onFinish={handlesubmitaddManager}
              fields={[
                {
                  name: ["id"],
                  value: uuidv4(),
                },
              ]}
            >
              <Form.Item hidden={true} label="ID" name="id">
                <Input placeholder="id" style={{ width: "90%" }} />
              </Form.Item>
              <Form.Item
                name={"nameManager"}
                label={"Username:"}
                rules={[
                  { required: true, message: `Please input Username` },
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]+$/i),
                    message: "field does not accept text Thai or special fonts",
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item
                name={"emailManager"}
                label={"Email:"}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input Email or is not valid E-mail!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name={"phoneManager"}
                label={"Phone Number:"}
                rules={[
                  {
                    required: true,
                    validator: async (_, storeValue) => {
                      if (validatePhone(storeValue)) {
                        return Promise.resolve(storeValue);
                      }
                      return Promise.reject(new Error("Please Input Phone Number"));
                    },
                  },
                ]}
              >
                <Input maxLength={10} placeholder="Phone Number" />
              </Form.Item>
              <Form.Item
                name={"organizeManager"}
                label={"Select Organize"}
                rules={[
                  {
                    required: true,
                    message: "Please select Organize",
                  },
                ]}
              >
                <Select placeholder="Please Select">
                  {selectOrganize.map((data: IOrganize, index: number) => {
                    return (
                      <Option key={index} value={data.key}>
                        {data.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={"rolesManager"}
                label={"Select Roles"}
                rules={[
                  {
                    required: true,
                    message: "Please select Roles",
                  },
                ]}
              >
                <Select placeholder="Please Select">
                  {selectSuborganize.map((data: ISuborganize) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
                  Save
                </Button>
                <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
                  Cancel
                </Button>
              </Space>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="OA List" key="3">
            <Spin tip="Loading..." spinning={false}>
              <TableaddnewOalist
                proFile={proFile}
                dataUserOaList={userOaList}
                oaCode={dataOaId}
                dataadminID={dataadminId}
                setLoading={setLoading}
                fetchData={fetchData}
              />
            </Spin>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};
