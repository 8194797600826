import { Card, Typography, Col, Row, Form, Button, Table, Modal, Input, message } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { TimeSetService } from "./services/timeset-service";
import { FieldTimeOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { ColumnsType } from "antd/lib/table";
import { DateTH } from "../../utils/time-local";
const appConfig = loadAppConfig();
const DatePicker = generatePicker(dayjsGenerateConfig);

interface Props {
  accessRole: string;
}

interface type {
  endTime: Date;
  id: string;
  startTime: Date;
  type: string;
  updateBy: string;
  updateDate: Date;
}

export const TimeSetting: React.FC<Props> = ({ accessRole }): React.ReactElement => {
  const [FormEdit] = Form.useForm();
  const [FormStart] = Form.useForm();
  const { id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser); // service user
  const [getDataTime, setDataTime] = useState<type[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const refValueTimeStart = useRef<any>(null);
  const refValueTimeEnd = useRef<any>(null);

  useEffect(() => {
    if (loading) getDataTimesetAll();
  }, []);

  const getDataTimesetAll = async () => {
    const Timeset = await TimeSetService(apiBaseUrlUser).getTimeSetting();
    setDataTime(Timeset);
    setLoading(false);
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const showModal = (e: type) => {
    FormEdit.setFieldValue("id", e.id);
    FormEdit.setFieldValue("startTime", dayjs(e.startTime));
    FormEdit.setFieldValue("endTime", dayjs(e.endTime));
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    FormEdit.resetFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const Onfinish = async (e: type) => {
    if (e.startTime === undefined || e.startTime === null) {
      refValueTimeStart.current.focus();
      message.warning("โปรดเลือก เวลาเริ่มต้น");
      return;
    }
    if (e.endTime === undefined || e.endTime === null) {
      refValueTimeEnd.current.focus();
      message.warning("โปรดเลือก เวลาสิ้นสุด");
      return;
    }
    const data = {
      startTime: dayjs(e.startTime),
      endTime: dayjs(e.endTime),
    };

    Modal.confirm({
      title: "ยืนยัน",
      icon: <CheckCircleOutlined />,
      content: "ยืนยันการเพิ่มข้อมูลเวลา",
      onOk: async () => {
        const dataCreate = await TimeSetService(apiBaseUrlUser).getTimeSettingCreate(data);
        if (dataCreate) {
          message.success("Create สำเร็จ");
          getDataTimesetAll();
          FormStart.resetFields();
          setLoading(true);
        }
      },
      onCancel: () => {
        console.log("cancel");
      },
    });
  };

  const handleUpdate = (event: type) => {
    const data = {
      id: Number(event.id),
      data: {
        startTime: dayjs(event.startTime),
        endTime: dayjs(event.endTime),
      },
    };
    Modal.confirm({
      title: "ยืนยัน",
      icon: <CheckCircleOutlined />,
      content: "ยืนยันการเพิ่มข้อมูลเวลา",
      onOk: async () => {
        const dataCreate = await TimeSetService(apiBaseUrlUser).getTimeSettingUpdate(data);
        if (dataCreate) {
          message.success("Update สำเร็จ");
          getDataTimesetAll();
          setIsModalOpen(false);
          FormEdit.resetFields();
          setLoading(true);
        }
      },
      onCancel: () => {
        console.log("cancel");
      },
    });
  };
  const ColumnsTime: ColumnsType<type> = [
    {
      title: "No.",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      width: "20%",
      render: (e: Date) => {
        return `${DateTH(e, "HH:mm")} น.`;
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      width: "20%",
      render: (e: Date) => {
        return `${DateTH(e, "HH:mm")} น.`;
      },
    },
    {
      title: "Update Date",
      dataIndex: "updateDate",
      width: "20%",
      render: (e: Date) => {
        return `${DateTH(e, "HH:mm")} น.`;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "10%",
      align: "center",
      render: (e: string, row: type, index: number) => {
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <Button type="primary" onClick={() => showModal(row)}>
                แก้ไข
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const ColumnsTimeNoAction: ColumnsType<type> = [
    {
      title: "No.",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      width: "20%",
      render: (e: Date) => {
        return `${DateTH(e, "HH:mm")} น.`;
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      width: "20%",
      render: (e: Date) => {
        return `${DateTH(e, "HH:mm")} น.`;
      },
    },
    {
      title: "Update Date",
      dataIndex: "updateDate",
      width: "20%",
      render: (e: Date) => {
        return `${DateTH(e, "HH:mm")} น.`;
      },
    },
  ];

  return (
    <>
      <Modal title="Update Time Setting" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
        <Form form={FormEdit} layout="vertical" onFinish={handleUpdate}>
          <Form.Item name={"id"} label={"ID"}>
            <Input id="id" disabled style={{ border: "none" }} />
          </Form.Item>
          <Row gutter={16} style={{ justifyContent: "left" }}>
            <Col>
              <Form.Item name={"startTime"} label={"เวลาเริ่มต้น"}>
                <DatePicker id="startTime" picker="time" format={"HH:mm:00"} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={"endTime"} label={"เวลาสิ้นสุด"}>
                <DatePicker id="endTime" picker="time" format={"HH:mm:00"} />
              </Form.Item>
            </Col>
            <Col style={{ marginTop: "30px" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  ยืนยัน
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Card
        style={{
          width: "100%",
          marginTop: "20px",
          textAlign: "left",
          marginBottom: "20px",
        }}
      >
        <Row gutter={16}>
          <Col>
            <FieldTimeOutlined
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                color: "rgb(0, 86, 180)",
                justifyContent: "center",
                display: "flex",
                marginTop: "6px",
              }}
            />
          </Col>
          <Col>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                color: "rgb(0, 86, 180)",
              }}
            >
              {`SETTING TIME`}
            </Typography>
          </Col>
        </Row>
      </Card>
      <Card title={"ตั้งค่าช่วงเวลาทำงานของระบบ"} style={{ textAlign: "left" }}>
        {checkAccessRole("ddebtSettingTimeAction") ? (
          <Form layout="vertical" onFinish={Onfinish} form={FormStart}>
            <Row gutter={16} style={{ justifyContent: "right" }}>
              <Col>
                <Form.Item name={"startTime"} label={"เวลาเริ่มต้น"}>
                  <DatePicker id="startTime" picker="time" ref={refValueTimeStart} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={"endTime"} label={"เวลาสิ้นสุด"}>
                  <DatePicker id="endTime" picker="time" ref={refValueTimeEnd} />
                </Form.Item>
              </Col>
              <Col style={{ marginTop: "30px" }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={getDataTime.length >= 1 ? true : false}>
                    ยืนยัน
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <></>
        )}
        <Table
          loading={loading}
          size="small"
          columns={checkAccessRole("ddebtSettingTimeAction") ? ColumnsTime : ColumnsTimeNoAction}
          dataSource={getDataTime}
          rowKey={(record: type) => record.id}
        />
      </Card>
    </>
  );
};
