import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Table, notification, Modal, message, Form, Button, Input, Typography } from "antd";
// import {uid} from 'react-uid';
import { IDebtService, DebtService } from "../../services/debt-service";
import { loadAppConfig } from "../../../../config/app-config";
import { useId24 } from "../../../../drivers/id24/Id24-provider";
import { ExclamationCircleOutlined, PhoneOutlined } from "@ant-design/icons";
import axios from "axios";

const appConfig = loadAppConfig();

export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  type: string;
  cifId: string;
  debtContact: any;
  accessRole: any;
}

export const TableByBank: React.FC<Props> = ({ caption, accountNo, baseURL, loading, type, cifId, debtContact, accessRole }): React.ReactElement => {
  const debtService = DebtService;
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);

  const [form] = Form.useForm();

  const [resData, setResData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [resDataModal, setDataModal] = useState<any>([]);
  const [getExtension, setExtension] = useState<string>("");
  const refPhone = useRef<any>(null);
  const refAccountId = useRef<any>(null);

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getDataTable(cifId, accountNo);
  }, [cifId, accountNo, resDataModal, getExtension]);

  const getDataTable = async (cif: string, accountNo: string): Promise<any> => {
    const result = (await debtService(apiBaseUrlDebt).getDebtContactBank(cifId, accountNo)) as any;
    const resultUserDetails = (await debtService(apiBaseUrlDebt).getDetailUser()) as any;
    let ExtensionVelue: string = "";
    ExtensionVelue = resultUserDetails?.result?.extension;
    setExtension(ExtensionVelue);
    const data = (await result.result.map(
      (
        x: {
          accountNo: string;
          custCif: string;
          id_card: string;
          status: string;
          work: string;
          add1: string;
          add2: string;
          add3: string;
          add4: string;
          dist: string;
          state: string;
          chn: string;
          zipcode: string;
          city: string;
        },
        index: number,
      ) => {
        let chnTxt = "";
        if (x.chn === "HOME") {
          chnTxt = "ทะเบียนบ้าน";
        } else if (x.chn === "NOW") {
          chnTxt = "ปัจจุบัน";
        } else if (x.chn === "ACC") {
          chnTxt = "สินเชื่อ";
        } else if (x.chn === "CARD") {
          chnTxt = "บัตรเครดิต";
        } else {
          chnTxt = "ไม่ระบุ";
        }
        const mapData = {
          key: index,
          custName: debtContact.custName + " " + debtContact.custLastName,
          custLastName: debtContact.custLastName,
          idCard: debtContact.idCard,
          custStatus: debtContact.status,
          work: debtContact.work,
          homePhone: debtContact.phone,
          officePhone: debtContact.officePhone,
          mobile: debtContact.mobile,
          addNumber: x.add1 + " " + x.add2 + " " + x.add3 + " " + x.add4,
          addSubDistrict: x.dist,
          addDistrict: x.city,
          addProvince: x.state,
          zipcode: x.zipcode,
          accountNo: x.accountNo,
          chn: chnTxt,
        };
        return mapData;
      },
    )) as any;
    setResData(data);
  };

  const onFinish = async (e: any) => {
    let string: any = 0;
    string = String(e.phone);
    if (string.length > 10) {
      notification.error({
        message: "Number have more than 10 value ",
        description: "Please check Phonenumber again",
      });
      return false;
    }
    if (getExtension === null) {
      notification.error({
        message: "Extension is Null",
        description: "Please check Extension on OA User. Due to value is Null",
      });
      return false;
    }
    if (e) {
      Modal.confirm({
        title: "คอนเฟิร์ม Click To Call",
        icon: <ExclamationCircleOutlined />,
        content: "คุณแน่ใจแล้วใช่ไหมในการส่งข้อมูลชุดนี้",
        onOk() {
          // axios.post(`https://dphonedemo.osd.co.th/public/api/dcall/clicktocall.php?internalnum=${getExtension}&outboundnum=${string}`);
          axios.post(`https://dphonedemo.osd.co.th/public/api/v2/call/dial?exten=${getExtension}&phone=${string}&accountId=${e.accountId}`);
          message.success("บันทึกสำเร็จ");
          setIsModalOpen(false);
          form.resetFields();
        },
        onCancel() {
          console.log("cancel button");
          form.resetFields();
        },
      });
    }
    setIsModalOpen(false);
  };

  const showModal = (row: any) => {
    form.setFieldValue("phone", row);
    form.setFieldValue("accountId", accountNo);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const columns = [
    {
      title: "ชื่อ - นามสกุล",
      dataIndex: "custName",
      key: "custName",
      // width: 200,
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      // width: 100,
    },
    {
      title: "ที่อยู่อ้างอิง",
      dataIndex: "chn",
      key: "chn",
      // width: 100,
    },
    {
      title: "อาชีพ",
      dataIndex: "work",
      key: "work",
      // width: 200,
    },
    {
      title: "เบอร์โทรศัพท์บ้าน",
      dataIndex: "homePhone",
      key: "homePhone",
      // width: 140,
      render: (e: any, row: any) => {
        let splitcomma: string = "";
        splitcomma = e.split(",");
        return (
          <>
            <a style={{ whiteSpace: "pre-line" }} onClick={() => showModal(splitcomma)}>
              {splitcomma}
            </a>
          </>
        );
      },
    },
    {
      title: "เบอร์โทรศัพท์ที่ทำงาน",
      dataIndex: "officePhone",
      key: "officePhone",
      // width: 150,
      render: (e: any, row: any) => {
        let splitcomma: string = "";
        splitcomma = e.split(",");
        return (
          <>
            <a style={{ whiteSpace: "pre-line" }} onClick={() => showModal(splitcomma)}>
              {splitcomma}
            </a>
          </>
        );
      },
    },
    {
      title: "เบอร์มือถือ",
      dataIndex: "mobile",
      key: "mobile",
      render: (e: any, row: any) => {
        let splitcomma: string = "";
        splitcomma = e.split(",");
        return (
          <>
            <a style={{ whiteSpace: "pre-line" }} onClick={() => showModal(splitcomma)}>
              {splitcomma}
            </a>
          </>
        );
      },
    },
    {
      title: "บ้านเลขที่",
      dataIndex: "addNumber",
      key: "addNumber",
    },
    {
      title: "ตำบล / แขวง ",
      dataIndex: "addSubDistrict",
      key: "addSubDistrict",
    },
    {
      title: "อำเภอ / เขต ",
      dataIndex: "addDistrict",
      key: "addDistrict",
    },
    {
      title: "จังหวัด",
      dataIndex: "addProvince",
      key: "addProvince",
      width: 150,
    },
    {
      title: "รหัสไปรษณีย์",
      dataIndex: "zipcode",
      key: "zipcode",
    },
  ];

  const columnsnoClicktocall = [
    {
      title: "ชื่อ - นามสกุล",
      dataIndex: "custName",
      key: "custName",
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
    },
    {
      title: "ที่อยู่อ้างอิง",
      dataIndex: "chn",
      key: "chn",
    },
    {
      title: "อาชีพ",
      dataIndex: "work",
      key: "work",
    },
    {
      title: "เบอร์โทรศัพท์บ้าน",
      dataIndex: "homePhone",
      key: "homePhone",
    },
    {
      title: "เบอร์โทรศัพท์ที่ทำงาน",
      dataIndex: "officePhone",
      key: "officePhone",
    },
    {
      title: "เบอร์มือถือ",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "บ้านเลขที่",
      dataIndex: "addNumber",
      key: "addNumber",
    },
    {
      title: "ตำบล / แขวง ",
      dataIndex: "addSubDistrict",
      key: "addSubDistrict",
    },
    {
      title: "อำเภอ / เขต ",
      dataIndex: "addDistrict",
      key: "addDistrict",
    },
    {
      title: "จังหวัด",
      dataIndex: "addProvince",
      key: "addProvince",
      width: 150,
    },
    {
      title: "รหัสไปรษณีย์",
      dataIndex: "zipcode",
      key: "zipcode",
    },
  ];

  return (
    <>
      <Modal
        title={
          <>
            <Row gutter={16}>
              <Col>
                <PhoneOutlined style={{ color: "green" }} rotate={360} />
              </Col>
              <Col>
                <Typography style={{ fontWeight: "bold" }}>Confirm Click To Call</Typography>
              </Col>
            </Row>
          </>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item name={"accountId"} hidden>
            <Input ref={refAccountId} style={{ width: "100%" }} name={"accountId"} readOnly />
          </Form.Item>
          <Form.Item label={"เบอร์โทรศัพท์"} name={"phone"}>
            <Input type="phone" ref={refPhone} style={{ width: "100%" }} name={"phone"} />
          </Form.Item>

          <Row gutter={16} style={{ justifyContent: "center" }}>
            <Col>
              <Form.Item>
                <Button type="primary" danger onClick={handleCancel}>
                  ยกเลิก
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  ยืนยัน
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Table
        size="small"
        dataSource={resData}
        columns={checkAccessRole("clicktocall") ? columns : columnsnoClicktocall}
        scroll={{ x: "calc(1000px + 50%)" }}
      />
    </>
  );
};
