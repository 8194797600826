import axios, { AxiosInstance } from "axios";
import { type } from "os";
import { loadAppConfig } from "../../../config/app-config";
// import { serviceBackendUser } from "../../../drivers/service/axiosBackend";

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export interface IFilter {
  type: string;
  text: string;
}
export interface ITeamService {
  getSubOrganize: () => Promise<string>;
  getOaOrganize: () => Promise<string>;
  getUser: (fillterData: IFilter) => Promise<string>;
  getProfileOaById: (data: any) => Promise<string>;

  getAllJob: () => Promise<string>;
  addJob: (data: any) => Promise<string>;
  deleteJob: (data: any) => Promise<string>;
  getJobById: (data: any) => Promise<string>;
  updateJobById: (id: any, data: any) => Promise<string>;
  //
  getAllJobLog: () => Promise<any>;
  getAllJobName: () => Promise<string>;
  getAllJobType: () => Promise<string>;
  getJobSMS: () => Promise<string>;
  getJobBatch: () => Promise<string>;
  getJobService: () => Promise<string>;
}

export const BatchProcessServiceJob = (serviceBackendUser: any): ITeamService => ({
  updateJobById: async (id, data) => {
    const result = await serviceBackendUser.put(`/link/v1/job/${id}`, data);
    return result?.data || {};
  },
  getJobById: async (data) => {
    const result = await serviceBackendUser.get(`/link/v1/job/${data}`);
    return result?.data || {};
  },
  deleteJob: async (data) => {
    const result = await serviceBackendUser.delete(`/link/v1/job/${data}`);
    return result?.data || {};
  },
  addJob: async (data) => {
    const result = await serviceBackendUser.post(`/link/v1/job`, data);
    return result?.data || {};
  },
  getAllJob: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(`/link/v1/job`, headerConfig);
    return result?.data || {};
  },
  getAllJobLog: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(`/link/v1/joblog`, headerConfig);
    return result?.data || {};
  },

  getAllJobName: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(`/link/v1/job/job-names`, headerConfig);
    return result?.data || {};
  },
  getAllJobType: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(`/link/v1/job/type`, headerConfig);
    return result?.data || {};
  },
  getJobSMS: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(`/link/v1/job/sms`, headerConfig);
    return result?.data || {};
  },
  getJobBatch: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(`/link/v1/job/batch`, headerConfig);
    return result?.data || {};
  },
  getJobService: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(`/link/v1/job/service`, headerConfig);
    return result?.data || {};
  },

  getProfileOaById: async (data) => {
    const result = await serviceBackendUser.get(`/oa/getprofileoabyid/${data}`);
    return result?.data || {};
  },
  getSubOrganize: async () => {
    const result = await serviceBackendUser.post("/oa/profile/subGroups", {});
    return result?.data || {};
  },
  getOaOrganize: async () => {
    const result = await serviceBackendUser.post("/oa/organize/roles", {});
    return result?.data || {};
  },
  getUser: async (filter: IFilter) => {
    let data = {};
    if (filter.type != "" && filter.text != "") {
      data = filter;
    }
    const result = await serviceBackendUser.post("/oa/user", data);
    return result?.data || {};
  },
});
