import React, { useState } from "react";
import { Button, Card, Col, Form, Input, message, Row, Upload, Select, notification, Modal } from "antd";
import { FileDoneOutlined, UploadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { DataImportcsvtService } from "./services/import-service";
import { loadAppConfig } from "../../config/app-config";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { useHistory } from "react-router";
const appConfig = loadAppConfig();

const { Option } = Select;
export const DebtmanagementImport: React.FC<{}> = () => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlExport = id24Axios(appConfig.apiBaseUrlExport);
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [type, setType] = useState("");

  const handleUpload = async () => {
    setUploading(true);
    var formData = new FormData();
    formData.append("file", fileList[0]);
    const resual = await DataImportcsvtService(apiBaseUrl)
      .upLoadfilecsv(formData)
      .then((e: any) => {
        e.code !== 500
          ? Modal.confirm({
              title: "สำเร็จ",
              icon: <CheckCircleOutlined />,
              content: `ท่านสามารถกดดาวน์โหลดไฟล์ได้ที่หน้า Task import&export ที่ Id File ${e.id}`,
              okText: "ยืนยัน",
              cancelText: "ยกเลิก",
              onOk: () => {
                window.location.href = `${window.location.origin}/debt/taskimport`;
              },
            })
          : notification.error({
              message: `ไม่สำเร็จ `,
              description: "Format ไฟล์ข้อมูลนำเข้าไม่ถูกต้อง กรุณาตรวจสอบ.",
              placement: "topRight",
              duration: 10,
              onClose: close,
            });
        setUploading(false);
      });
  };

  const props = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error(`${file.name} is not a csv file`);
      } else {
        setFileList([...fileList, file]);
      }
      return false;
    },
    fileList,
  };

  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <FileDoneOutlined /> IMPORT
          </b>
        </Card>
      </Row>

      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Col span={24}>
          <Card style={{ textAlign: "left" }} title={"import"}>
            <Col offset={1} span={22}>
              <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item label="Type" rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}>
                  <Select onChange={(e) => setType(e)} allowClear placeholder={"Please Select"}>
                    <Option value="0" key="0" disabled>
                      {"Please Select"}
                    </Option>
                    <Option value="1" key="1">
                      ข้อมูลการติดตามหนี้
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Upload CSV File">
                  <Upload {...props}>
                    <Button disabled={fileList.length === 1} icon={<UploadOutlined />}>
                      Select File
                    </Button>
                  </Upload>
                  <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length === 0 || type === ""}
                    loading={uploading}
                    style={{
                      marginTop: 16,
                    }}
                  >
                    {uploading ? "Uploading" : "Start Upload"}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};
