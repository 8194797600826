import React, { useState, useEffect } from "react";
import { Card, Row, Col, Avatar, Collapse, Tag, Spin } from "antd";
import { DownCircleOutlined, LoadingOutlined, RightCircleOutlined, UserOutlined } from "@ant-design/icons";
import { DashboardService } from "./services/dashboard-service";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();
interface Props {
  baseUrl: string;
}

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const DashboardManagerBank: React.FC<Props> = ({ baseUrl }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const frontStyleCard = {
    textAlign: "left",
    fontSize: "12px",
  } as any;

  const fontStyleHerder = {
    fontSize: "18px",
    color: "#FFFFFF",
  } as any;

  const fontStyleTap = {
    fontSize: "14px",
    borderRadius: "10px",
  } as any;

  const frontStyleInCard = {
    textAlign: "left",
    fontSize: "10px",
  } as any;

  const fontStyleRow = {
    textAlign: "left",
    fontSize: "10px",
    borderBottom: "1px solid #ddd",
  } as any;

  const history = useHistory();
  const { id24Axios } = useId24();

  // function getCookie(key: string) {
  //   var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  //   return b ? b.pop() : "";
  // }

  // const decodedCookie = getCookie("id24Token");
  const [dataSourceNew, setDataSourceNew] = useState([]);
  const [dataSourceAction, setDataSourceAction] = useState([]);
  const [dataSourceNoAction, setDataSourceNoAction] = useState([]);

  const toDebt = (status: string, team: string, list: string, oaName: string, oaCode: string) => {
    if (status) {
      let all = {
        type: "BANK",
        statusName: status,
        teamId: team,
        listName: list,
        oaName: oaName,
        oaCode: oaCode,
      };
      history.push("/debtmanagement", all);
    }
  };

  function integrateDashboardNew(data: any) {
    let listNew: any = [];
    let teamList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionStatus === "new") {
        let list = {
          listName: x1.listName,
          oaCode: x1.oaCode,
          oaname: x1.oaname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumstmtamt: x1.sumstmtamt,
          sumos: x1.sumos,
          teamName: x1.teamName,
          teamId: x1.teamId,
        };
        listNew.push(list);

        let team = {
          teamName: x1.teamName,
          teamId: x1.teamId,
          listName: x1.listName,
        };
        teamList.push(team);
      }
    });
    const groupByList = listNew.reduce((list: any, groupList: any) => {
      // console.log(groupList);

      const { teamName, listName } = groupList;
      const getGroup = list.find((x: any) => x.teamName === teamName && x.listName === listName);
      if (getGroup) {
        getGroup.oalist.push(groupList);
      } else {
        let listGroup = {
          teamName,
          listName: listName,
          oalist: [groupList],
        };
        list.push(listGroup);
      }
      return list;
    }, []);
    const groupByTeamsForCheck = (teamList: any) =>
      Object.values(
        teamList.reduce((data: any, { teamName, listName }: any) => {
          if (data[teamName]) {
            if (
              !data[teamName].list.find((listcheck: any) => {
                return listcheck.listName === listName;
              })
            ) {
              let oaList = groupByList.find((list: any) => {
                return list.listName === listName && list.teamName === teamName;
              });
              const list = {
                listName: listName,
                oaList: oaList,
                teamName: teamName,
              };
              data[teamName].list.push(list);
            }
          } else {
            let oaList = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
            const list = {
              listName: listName,
              oaList: oaList,
              teamName: teamName,
            };
            data[teamName] = { teamName, list: [list] };
          }

          return data;
        }, {}),
      );

    const groupByTeams = (teamList: any) =>
      Object.values(
        teamList.reduce((data: any, { teamName, teamId, listName }: any) => {
          if (data[teamName]) {
            if (
              !data[teamName].list.find((listcheck: any) => {
                return listcheck.listName === listName;
              })
            ) {
              let oaList: any = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
              let ac = 0;
              let os = 0;
              let bal = 0;
              let cur = 0;
              let stmt = 0;
              oaList.oalist.map((item: any) => {
                // console.log(item);
                ac += item.cnt;
                os += item.sumos;
                bal += item.sumamt;
                cur += item.sumcurbal;
                stmt += item.sumstmtamt;
              });
              const list = {
                listName: listName,
                oaList: oaList,
                ac: ac,
                os: os,
                bal: bal,
                cur: cur,
                stmt: stmt,
              };
              data[teamName].list.push(list);
            }
          } else {
            let oaList: any = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
            let ac = 0;
            let os = 0;
            let bal = 0;
            let cur = 0;
            let stmt = 0;
            oaList.oalist.map((item: any) => {
              // console.log(item);

              ac += item.cnt;
              os += item.sumos;
              bal += item.sumamt;
              cur += item.sumcurbal;
              stmt += item.sumstmtamt;
            });
            const list: any = {
              listName: listName,
              oaList: oaList,
              ac: ac,
              os: os,
              bal: bal,
              cur: cur,
              stmt: stmt,
            };

            let acTeam: number = 0;
            let osTeam = 0;
            let balTeam = 0;
            let stmtTeam = 0;
            let curTeam = 0;
            let teamListCeck: any = groupByTeamsForCheck(teamList).find((team: any) => team.teamName === teamName);
            teamListCeck.list.map((oaCount: any) => {
              oaCount.oaList.oalist.map((oa: any) => {
                acTeam += oa.cnt;
                osTeam += oa.sumos;
                balTeam += oa.sumamt;
                stmtTeam += oa.sumstmtamt;
                curTeam += oa.sumcurbal;
              });
            });
            data[teamName] = {
              teamName,
              teamId: teamId,
              list: [list],
              ac: acTeam,
              os: osTeam,
              bal: balTeam,
              cur: curTeam,
              stmt: stmtTeam,
            };
          }
          return data;
        }, {}),
      );
    const newObject = {
      statusName: "new",
      teamList: groupByTeams(teamList),
    };
    dataSource.push(newObject);
    return dataSource;
  }

  function integrateDashboardAction(data: any) {
    let listNew: any = [];
    let teamList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionStatus === "action") {
        let list = {
          listName: x1.listName,
          oaCode: x1.oaCode,
          oaname: x1.oaname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumstmtamt: x1.sumstmtamt,
          sumos: x1.sumos,
          teamName: x1.teamName,
          teamId: x1.teamId,
        };
        listNew.push(list);

        let team = {
          teamName: x1.teamName,
          teamId: x1.teamId,
          listName: x1.listName,
        };
        teamList.push(team);
      }
    });
    const groupByList = listNew.reduce((list: any, groupList: any) => {
      // console.log(groupList);

      const { teamName, listName } = groupList;
      const getGroup = list.find((x: any) => x.teamName === teamName && x.listName === listName);
      if (getGroup) {
        getGroup.oalist.push(groupList);
      } else {
        let listGroup = {
          teamName,
          listName: listName,
          oalist: [groupList],
        };
        list.push(listGroup);
      }
      return list;
    }, []);
    const groupByTeamsForCheck = (teamList: any) =>
      Object.values(
        teamList.reduce((data: any, { teamName, listName }: any) => {
          if (data[teamName]) {
            if (
              !data[teamName].list.find((listcheck: any) => {
                return listcheck.listName === listName;
              })
            ) {
              let oaList = groupByList.find((list: any) => {
                return list.listName === listName && list.teamName === teamName;
              });
              const list = {
                listName: listName,
                oaList: oaList,
                teamName: teamName,
              };
              data[teamName].list.push(list);
            }
          } else {
            let oaList = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
            const list = {
              listName: listName,
              oaList: oaList,
              teamName: teamName,
            };
            data[teamName] = { teamName, list: [list] };
          }

          return data;
        }, {}),
      );

    const groupByTeams = (teamList: any) =>
      Object.values(
        teamList.reduce((data: any, { teamName, teamId, listName }: any) => {
          if (data[teamName]) {
            if (
              !data[teamName].list.find((listcheck: any) => {
                return listcheck.listName === listName;
              })
            ) {
              let oaList: any = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
              let ac = 0;
              let os = 0;
              let bal = 0;
              let cur = 0;
              let stmt = 0;
              oaList.oalist.map((item: any) => {
                // console.log(item);
                ac += item.cnt;
                os += item.sumos;
                bal += item.sumamt;
                cur += item.sumcurbal;
                stmt += item.sumstmtamt;
              });
              const list = {
                listName: listName,
                oaList: oaList,
                ac: ac,
                os: os,
                bal: bal,
                cur: cur,
                stmt: stmt,
              };
              data[teamName].list.push(list);
            }
          } else {
            let oaList: any = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
            let ac = 0;
            let os = 0;
            let bal = 0;
            let cur = 0;
            let stmt = 0;
            oaList.oalist.map((item: any) => {
              // console.log(item);

              ac += item.cnt;
              os += item.sumos;
              bal += item.sumamt;
              cur += item.sumcurbal;
              stmt += item.sumstmtamt;
            });
            const list: any = {
              listName: listName,
              oaList: oaList,
              ac: ac,
              os: os,
              bal: bal,
              cur: cur,
              stmt: stmt,
            };

            let acTeam: number = 0;
            let osTeam = 0;
            let balTeam = 0;
            let stmtTeam = 0;
            let curTeam = 0;
            let teamListCeck: any = groupByTeamsForCheck(teamList).find((team: any) => team.teamName === teamName);
            teamListCeck.list.map((oaCount: any) => {
              oaCount.oaList.oalist.map((oa: any) => {
                acTeam += oa.cnt;
                osTeam += oa.sumos;
                balTeam += oa.sumamt;
                stmtTeam += oa.sumstmtamt;
                curTeam += oa.sumcurbal;
              });
            });
            data[teamName] = {
              teamName,
              teamId: teamId,
              list: [list],
              ac: acTeam,
              os: osTeam,
              bal: balTeam,
              cur: curTeam,
              stmt: stmtTeam,
            };
          }
          return data;
        }, {}),
      );

    const newObject = {
      statusName: "action",
      teamList: groupByTeams(teamList),
    };

    dataSource.push(newObject);

    return dataSource;
  }

  function integrateDashboardNoAction(data: any) {
    let listNew: any = [];
    let teamList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionStatus === "noAction") {
        let list = {
          listName: x1.listName,
          oaCode: x1.oaCode,
          oaname: x1.oaname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumstmtamt: x1.sumstmtamt,
          sumos: x1.sumos,
          teamName: x1.teamName,
          teamId: x1.teamId,
        };
        listNew.push(list);

        let team = {
          teamName: x1.teamName,
          teamId: x1.teamId,
          listName: x1.listName,
        };
        teamList.push(team);
      }
    });
    const groupByList = listNew.reduce((list: any, groupList: any) => {
      // console.log(groupList);

      const { teamName, listName } = groupList;
      const getGroup = list.find((x: any) => x.teamName === teamName && x.listName === listName);
      if (getGroup) {
        getGroup.oalist.push(groupList);
      } else {
        let listGroup = {
          teamName,
          listName: listName,
          oalist: [groupList],
        };
        list.push(listGroup);
      }
      return list;
    }, []);
    const groupByTeamsForCheck = (teamList: any) =>
      Object.values(
        teamList.reduce((data: any, { teamName, listName }: any) => {
          if (data[teamName]) {
            if (
              !data[teamName].list.find((listcheck: any) => {
                return listcheck.listName === listName;
              })
            ) {
              let oaList = groupByList.find((list: any) => {
                return list.listName === listName && list.teamName === teamName;
              });
              const list = {
                listName: listName,
                oaList: oaList,
                teamName: teamName,
              };
              data[teamName].list.push(list);
            }
          } else {
            let oaList = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
            const list = {
              listName: listName,
              oaList: oaList,
              teamName: teamName,
            };
            data[teamName] = { teamName, list: [list] };
          }

          return data;
        }, {}),
      );

    const groupByTeams = (teamList: any) =>
      Object.values(
        teamList.reduce((data: any, { teamName, teamId, listName }: any) => {
          if (data[teamName]) {
            if (
              !data[teamName].list.find((listcheck: any) => {
                return listcheck.listName === listName;
              })
            ) {
              let oaList: any = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
              let ac = 0;
              let os = 0;
              let bal = 0;
              let cur = 0;
              let stmt = 0;
              oaList.oalist.map((item: any) => {
                // console.log(item);
                ac += item.cnt;
                os += item.sumos;
                bal += item.sumamt;
                cur += item.sumcurbal;
                stmt += item.sumstmtamt;
              });
              const list = {
                listName: listName,
                oaList: oaList,
                ac: ac,
                os: os,
                bal: bal,
                cur: cur,
                stmt: stmt,
              };
              data[teamName].list.push(list);
            }
          } else {
            let oaList: any = groupByList.find((list: any) => list.listName === listName && list.teamName === teamName);
            let ac = 0;
            let os = 0;
            let bal = 0;
            let cur = 0;
            let stmt = 0;
            oaList.oalist.map((item: any) => {
              // console.log(item);

              ac += item.cnt;
              os += item.sumos;
              bal += item.sumamt;
              cur += item.sumcurbal;
              stmt += item.sumstmtamt;
            });
            const list: any = {
              listName: listName,
              oaList: oaList,
              ac: ac,
              os: os,
              bal: bal,
              cur: cur,
              stmt: stmt,
            };

            let acTeam: number = 0;
            let osTeam = 0;
            let balTeam = 0;
            let stmtTeam = 0;
            let curTeam = 0;
            let teamListCeck: any = groupByTeamsForCheck(teamList).find((team: any) => team.teamName === teamName);
            teamListCeck.list.map((oaCount: any) => {
              oaCount.oaList.oalist.map((oa: any) => {
                acTeam += oa.cnt;
                osTeam += oa.sumos;
                balTeam += oa.sumamt;
                stmtTeam += oa.sumstmtamt;
                curTeam += oa.sumcurbal;
              });
            });
            data[teamName] = {
              teamName,
              teamId: teamId,
              list: [list],
              ac: acTeam,
              os: osTeam,
              bal: balTeam,
              cur: curTeam,
              stmt: stmtTeam,
            };
          }
          return data;
        }, {}),
      );

    const newObject = {
      statusName: "noAction",
      teamList: groupByTeams(teamList),
    };

    dataSource.push(newObject);

    return dataSource;
  }

  useEffect(() => {
    async function fetchDataForSearch() {
      setLoading(true);
      const apiCaller = id24Axios(appConfig.apiBaseUrlDebt);
      const res: any = await DashboardService(apiCaller).getBankManager();
      let conDataNew: any = await integrateDashboardNew(res.result);
      let conDataAction: any = await integrateDashboardAction(res.result);
      let conDataNoAction: any = await integrateDashboardNoAction(res.result);
      await setDataSourceNew(conDataNew);
      await setDataSourceAction(conDataAction);
      await setDataSourceNoAction(conDataNoAction);
      setLoading(false);
    }

    fetchDataForSearch();
  }, []);

  const [evenExtra, setEvenextra] = useState<boolean>(true);
  const genExtra = () => (
    <>
      {evenExtra === false ? (
        <DownCircleOutlined
          style={{
            fontSize: "20px",
            color: "#1890FF",
            backgroundColor: "#E6F7FF",
            borderRadius: "100%",
          }}
          onClick={(event) => {
            setEvenextra(true);
          }}
        />
      ) : (
        <RightCircleOutlined
          style={{
            fontSize: "20px",
            color: "#1890FF",
            backgroundColor: "#E6F7FF",
            borderRadius: "100%",
          }}
          onClick={(event) => {
            setEvenextra(false);
          }}
        />
      )}
    </>
  );
  return loading ? (
    <>
      <div style={{ justifyContent: "center", display: "flex", alignItems: "center", minHeight: "30vh" }}>
        <Spin spinning={loading} tip="Loading" indicator={antIcon} />
      </div>
    </>
  ) : (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Col span={8}>
          <Row>
            {dataSourceNew ? (
              dataSourceNew.map((newstatus: any, index: any) => {
                // console.log(newstatus);

                if (newstatus.statusName === "new") {
                  return (
                    <Col span={24} key={index}>
                      <Card
                        style={{
                          backgroundColor: "#0056B4",
                          borderRadius: "5px",
                          marginBottom: "10px",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <b style={fontStyleHerder}>New</b>
                        {"   "}
                        <Tag style={fontStyleTap} color="#1890FF" key={index}>
                          {newstatus.teamList.length}
                        </Tag>
                      </Card>

                      {newstatus.teamList.map((teams: any, indexTeam: any) => {
                        return (
                          <Col>
                            <Collapse
                              key={indexTeam}
                              defaultActiveKey={["0"]}
                              expandIconPosition="end"
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "#FFFFFF",
                              }}
                              bordered={false}
                              ghost
                            >
                              <Panel
                                showArrow={true}
                                header={
                                  <Col span={24}>
                                    <Row gutter={[0, 24]}>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={{ textAlign: "left" }} key={indexTeam}>
                                        <a
                                          onClick={() => toDebt("new", teams.teamId, "", "", "")}
                                          style={{
                                            fontSize: "18px",
                                            color: "#108ee9",
                                          }}
                                        >
                                          <b>{teams.teamName}</b>
                                        </a>{" "}
                                        <Tag
                                          style={{
                                            fontSize: "16px",
                                            borderRadius: "10px",
                                          }}
                                          color="#108ee9"
                                        >
                                          <NumberFormat
                                            value={teams.list.length}
                                            displayType={"text"}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                          />
                                        </Tag>
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col span={4} style={frontStyleCard}>
                                        <Avatar
                                          style={{ backgroundColor: "#E1EDF9" }}
                                          shape="square"
                                          icon={
                                            <UserOutlined
                                              style={{
                                                color: "#035BBD",
                                              }}
                                            />
                                          }
                                        />
                                      </Col>
                                      <Col span={20} style={frontStyleCard}>
                                        A/C{" "}
                                        <NumberFormat
                                          value={teams.ac}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      {/* <Col span={2} style={frontStyleCard}> */}
                                      {/* {genExtra()} */}
                                      {/* </Col> */}
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        BAL{" "}
                                        <NumberFormat
                                          value={teams.bal}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        OS{" "}
                                        <NumberFormat
                                          value={teams.os}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        CUR{" "}
                                        <NumberFormat
                                          value={teams.cur}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        STMT{" "}
                                        <NumberFormat
                                          value={teams.stmt}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                  </Col>
                                }
                                key="1"
                              >
                                <Collapse defaultActiveKey={["0"]}>
                                  {teams.list.map((list: any, indexList: any) => {
                                    return (
                                      <Panel
                                        header={
                                          <>
                                            <Col style={frontStyleInCard} span={8}>
                                              <a style={{ color: "black" }} onClick={() => toDebt("new", teams.teamId, list.listName, "", "")}>
                                                {list.listName}
                                              </a>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              A/C{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.ac}
                                                  displayType={"text"}
                                                  decimalScale={0}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              BAL{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.bal}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              OS{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.os}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              CUR{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.cur}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              STMT{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.stmt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                          </>
                                        }
                                        key={indexList}
                                      >
                                        {list.oaList.oalist.map((oa: any, index: any) => {
                                          return (
                                            <Row style={fontStyleRow} key={index}>
                                              <Col span={7} offset={1}>
                                                <a
                                                  style={{ color: "black" }}
                                                  onClick={() => toDebt("new", teams.teamId, list.listName, oa.oaname, oa.oaCode)}
                                                >
                                                  {oa.oaname}
                                                </a>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                A/C{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.cnt}
                                                    displayType={"text"}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                BAL{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumamt}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={1}>
                                                OS{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumos}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>{" "}
                                              <Col span={6} offset={3}>
                                                CUR{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumcurbal}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                STMT{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumstmtamt}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </Panel>
                                    );
                                  })}
                                </Collapse>
                              </Panel>
                            </Collapse>
                            <br></br>
                          </Col>
                        );
                      })}
                    </Col>
                  );
                }
              })
            ) : (
              <></>
            )}
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {dataSourceAction ? (
              dataSourceAction.map((newstatus: any, index: any) => {
                // console.log(newstatus);

                if (newstatus.statusName === "action") {
                  return (
                    <Col span={24} key={index}>
                      <Card
                        style={{
                          backgroundColor: "#70C787",
                          borderRadius: "5px",
                          marginBottom: "10px",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <b style={fontStyleHerder}>Action</b>
                        {"   "}
                        <Tag style={fontStyleTap} color="#52C41A">
                          <NumberFormat
                            value={newstatus.teamList.length}
                            displayType={"text"}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                          />
                        </Tag>
                      </Card>

                      {newstatus.teamList.map((teams: any, indexTeam: any) => {
                        // console.log(teams);

                        return (
                          <Col>
                            <Collapse
                              key={indexTeam}
                              defaultActiveKey={["0"]}
                              expandIconPosition="end"
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "#FFFFFF",
                              }}
                              bordered={false}
                              ghost
                            >
                              <Panel
                                showArrow={true}
                                header={
                                  <Col span={24}>
                                    <Row gutter={[0, 24]}>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={{ textAlign: "left" }}>
                                        <a
                                          onClick={() => toDebt("action", teams.teamId, "", "", "")}
                                          style={{
                                            fontSize: "18px",
                                            color: "#52C41A",
                                          }}
                                        >
                                          <b>{teams.teamName}</b>
                                        </a>{" "}
                                        <Tag
                                          style={{
                                            fontSize: "16px",
                                            borderRadius: "10px",
                                          }}
                                          color="#52C41A"
                                        >
                                          <NumberFormat
                                            value={teams.list.length}
                                            displayType={"text"}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                          />
                                        </Tag>
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col span={4} style={frontStyleCard}>
                                        <Avatar
                                          style={{ backgroundColor: "#DEFFCD" }}
                                          shape="square"
                                          icon={
                                            <UserOutlined
                                              style={{
                                                color: "#52C41A",
                                              }}
                                            />
                                          }
                                        />
                                      </Col>
                                      <Col span={18} style={frontStyleCard}>
                                        A/C{" "}
                                        <NumberFormat
                                          value={teams.ac}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}>
                                        {/* {genExtra()} */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        BAL{" "}
                                        <NumberFormat
                                          value={teams.bal}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        OS{" "}
                                        <NumberFormat
                                          value={teams.os}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>{" "}
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        CUR{" "}
                                        <NumberFormat
                                          value={teams.cur}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>{" "}
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        STMT{" "}
                                        <NumberFormat
                                          value={teams.stmt}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                  </Col>
                                }
                                key="1"
                              >
                                <Collapse defaultActiveKey={["0"]}>
                                  {teams.list.map((list: any, indexList: any) => {
                                    // console.log(list);

                                    return (
                                      <Panel
                                        header={
                                          <>
                                            <Col style={frontStyleInCard} span={8}>
                                              <a style={{ color: "black" }} onClick={() => toDebt("action", teams.teamId, list.listName, "", "")}>
                                                {list.listName}
                                              </a>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              A/C{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.ac}
                                                  displayType={"text"}
                                                  decimalScale={0}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              BAL{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.bal}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              OS{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.os}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              CUR{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.cur}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              STMT{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.stmt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                          </>
                                        }
                                        key={indexList}
                                      >
                                        {list.oaList.oalist.map((oa: any, index: any) => {
                                          return (
                                            <Row style={fontStyleRow} key={index}>
                                              <Col span={7} offset={1}>
                                                <a
                                                  style={{ color: "black" }}
                                                  onClick={() => toDebt("action", teams.teamId, list.listName, oa.oaname, oa.oaCode)}
                                                >
                                                  {oa.oaname}
                                                </a>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                A/C{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.cnt}
                                                    displayType={"text"}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                BAL{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumamt}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={1}>
                                                OS{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumos}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={3}>
                                                CUR{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumcurbal}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                STMT{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumstmtamt}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </Panel>
                                    );
                                  })}
                                </Collapse>
                              </Panel>
                            </Collapse>
                            <br></br>
                          </Col>
                        );
                      })}
                    </Col>
                  );
                }
              })
            ) : (
              <></>
            )}
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {dataSourceNoAction ? (
              dataSourceNoAction.map((newstatus: any, index: any) => {
                if (newstatus.statusName === "noAction") {
                  return (
                    <Col span={24} key={index}>
                      <Card
                        style={{
                          backgroundColor: "#EE5F77",
                          borderRadius: "5px",
                          marginBottom: "10px",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <b style={fontStyleHerder}>No-Action</b>
                        {"   "}
                        <Tag style={fontStyleTap} color="#FF4D4F">
                          <NumberFormat
                            value={newstatus.teamList.length}
                            displayType={"text"}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                          />
                        </Tag>
                      </Card>
                      {newstatus?.teamList.map((teams: any, indexTeam: any) => {
                        return (
                          <Col>
                            <Collapse
                              key={indexTeam}
                              defaultActiveKey={["0"]}
                              expandIconPosition="end"
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "#FFFFFF",
                              }}
                              bordered={false}
                              ghost
                            >
                              <Panel
                                showArrow={true}
                                header={
                                  <Col span={24}>
                                    <Row gutter={[0, 24]}>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={{ textAlign: "left" }}>
                                        <a
                                          onClick={() => toDebt("noAction", teams.teamId, "", "", "")}
                                          style={{
                                            fontSize: "18px",
                                            color: "#FF4D4F",
                                          }}
                                        >
                                          <b>{teams.teamName}</b>
                                        </a>{" "}
                                        <Tag
                                          style={{
                                            fontSize: "16px",
                                            borderRadius: "10px",
                                          }}
                                          color="#FF4D4F"
                                        >
                                          <NumberFormat
                                            value={teams.list.length}
                                            displayType={"text"}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                          />
                                        </Tag>
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col span={4} style={frontStyleCard}>
                                        <Avatar
                                          style={{ backgroundColor: "#e98b9b" }}
                                          shape="square"
                                          icon={
                                            <UserOutlined
                                              style={{
                                                color: "#FF4D4F",
                                              }}
                                            />
                                          }
                                        />
                                      </Col>
                                      <Col span={18} style={frontStyleCard}>
                                        A/C{" "}
                                        <NumberFormat
                                          value={teams.ac}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}>
                                        {/* {genExtra()} */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        BAL{" "}
                                        <NumberFormat
                                          value={teams.bal}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        OS{" "}
                                        <NumberFormat
                                          value={teams.os}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        CUR{" "}
                                        <NumberFormat
                                          value={teams.cur}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: "left",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      ></Col>
                                      <Col span={18} style={frontStyleCard}>
                                        STMT{" "}
                                        <NumberFormat
                                          value={teams.stmt}
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Col>
                                      <Col span={2} style={frontStyleCard}></Col>
                                    </Row>
                                  </Col>
                                }
                                key="1"
                              >
                                <Collapse defaultActiveKey={["0"]}>
                                  {teams.list.map((list: any, indexList: any) => {
                                    return (
                                      <Panel
                                        header={
                                          <>
                                            <Col style={frontStyleInCard} span={8}>
                                              <a style={{ color: "black" }} onClick={() => toDebt("noAction", teams.teamId, list.listName, "", "")}>
                                                {list.listName}
                                              </a>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              A/C{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.ac}
                                                  displayType={"text"}
                                                  decimalScale={0}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              BAL{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.bal}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              OS{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.os}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              CUR{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.cur}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col style={frontStyleInCard} span={8}>
                                              STMT{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={list.stmt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                          </>
                                        }
                                        key={indexList}
                                      >
                                        {list.oaList.oalist.map((oa: any, index: any) => {
                                          return (
                                            <Row style={fontStyleRow} key={index}>
                                              <Col span={7} offset={1}>
                                                <a
                                                  style={{ color: "black" }}
                                                  onClick={() => toDebt("noAction", teams.teamId, list.listName, oa.oaname, oa.oaCode)}
                                                >
                                                  {oa.oaname}
                                                </a>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                A/C{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.cnt}
                                                    displayType={"text"}
                                                    decimalScale={0}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                BAL{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumamt}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={1}>
                                                OS{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumos}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={3}>
                                                CUR{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumcurbal}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                              <Col span={6} offset={2}>
                                                STMT{" "}
                                                <b
                                                  style={{
                                                    color: "#0056B4",
                                                  }}
                                                >
                                                  <NumberFormat
                                                    value={oa.sumstmtamt}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                  />
                                                </b>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </Panel>
                                    );
                                  })}
                                </Collapse>
                              </Panel>
                            </Collapse>
                            <br></br>
                          </Col>
                        );
                      })}
                    </Col>
                  );
                }
              })
            ) : (
              <></>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
