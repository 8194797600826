import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Input, Modal, Select, Space, Table, Row, Tooltip, Tag, Switch, Typography, Spin } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { BankUserService } from "../services/service";
import notification from "antd/lib/notification";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { IUser } from "../../oaProfile/common";
import { IDataTable } from "../BankUser";
import { TablePaginationConfig } from "antd/es/table/interface";
import { TPagination } from "../../oaUser/components/TableOaUser";
import { dateCheck, dateCheckTime } from "../../../utils";

const appConfig = loadAppConfig();
const { Option } = Select;
interface Props {
  proFile: IUser;
  dataUsers: IDataTable[];
  setLoading: (status: boolean) => void;
  countNoActive: number;
  countInActive: number;
  organizeBank: any;
  subOrganize: any;
  fetchData: () => void;
  dataUsersAll: any;
}

export const TableBankUser: React.FC<Props> = ({
  proFile,
  dataUsers,
  setLoading,
  countNoActive,
  countInActive,
  organizeBank,
  subOrganize,
  fetchData,
  dataUsersAll,
}) => {
  const { id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const refTitlettree = useRef<any>(null);
  const [form] = Form.useForm();
  const [statusModalEditUser, setStatusModalEditUser] = useState<boolean>(false);
  const [loaddingModal, setLoadingModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });

  const validatePhone = (number: string) => {
    if (!number) {
      return true;
    }
    return /^[0-9]{1,10}$/.test(number);
  };

  function onChange(pagination: TablePaginationConfig) {
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
  }
  const columns: any = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      render: (event: string, row: IDataTable, index: number) => {
        let skip = (pagination.current - 1) * pagination.pageSize;
        return index + skip + 1;
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Roles",
      dataIndex: "subOrganizationProfile",
      key: "subOrganizationProfile",
      render: (e: any, record: number) => {
        if (e) {
          if (e.length <= 1) {
            return (
              <>
                {subOrganize?.map((x: any, index: number) => {
                  return <div key={index}>{x.id == e ? x.name : ""}</div>;
                })}
              </> 
            );
          } else {
            let data: string = "";
            e?.map((e: any, index: number) => {
              subOrganize?.map((x: any, index: number) => {
                if (x.id === e) {
                  data += " , " + x.name;
                } else {
                  <></>;
                }
              });
            });
            let splittt = data.replace(" , ", " ");
            return <div>{splittt}</div>;
          }
        } else {
          return "-";
        }
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      render: (event: string, row: IDataTable, index: number) => (event ? event : "-"),
    },
    {
      title: "update date",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (event: string, row: IDataTable, index: number) => dateCheckTime(event),
    },
    {
      title: "update by",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (event: string) => {
        const userupdateName = dataUsersAll?.find((value: any) => value.id === Number(event));
        return userupdateName?.name;
      },
    },
    {
      title: "Stauts",
      dataIndex: "active",
      key: "active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "In Active",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.active === value,
      render: (event: boolean, row: any, index: number) => (event === true ? <Tag color="green">{`Active`}</Tag> : <Tag>{`InActive`}</Tag>),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (e: string, record: any) => {
        return (
          <>
            <Row gutter={10} style={{ justifyContent: "center", display: "flex" }}>
              <Col>
                <Tooltip placement="topLeft" title="Edit">
                  <Button
                    type="default"
                    onClick={() => {
                      openModalEdit(record);
                    }}
                  >
                    <FormOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const openModalEdit = async (e: any) => {
    setStatusModalEditUser(true);
    setLoadingModal(true);
    const resultSubByid = await BankUserService(apiBaseUrlUser).getrolesBytoken(e.token);
    form.setFieldValue("id", e.id);
    form.setFieldValue("name", e.name);
    form.setFieldValue("address", e.address);
    form.setFieldValue("email", e.email);
    form.setFieldValue("phone", e.phone);
    form.setFieldValue("setorganize", e.oaOrganize);
    form.setFieldValue("setroles", Object.keys(resultSubByid).length > 0 ? resultSubByid : []);
    form.setFieldValue("active", e.active);
    setLoadingModal(false);
  };

  const onFinishEdit = async (values: any) => {
    setLoading(true);
    const resUpdateUser = await BankUserService(apiBaseUrlUser).updateUser(values);
    if (resUpdateUser) {
      notification.success({
        message: "แก้ไขข้อมูลสำเร็จ",
        description: "คุณได้ทำการบันทึกข้อมูลเรียบร้อยแล้ว",
      });
      setStatusModalEditUser(false);
      fetchData();
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const handleCancel = () => {
    setStatusModalEditUser(false);
    form.resetFields();
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <div style={{ width: "100%", justifyContent: "end", display: "flex", margin: "10px 10px 10px -10px" }}>
        <Row gutter={[32, 0]}>
          <Col>
            <Typography>{`Active : ${countInActive}`}</Typography>
          </Col>
          <Col>
            <Typography>{`In-Active : ${countNoActive}`}</Typography>
          </Col>
        </Row>
      </div>

      <Table size="small" columns={columns} dataSource={dataUsers} rowKey={`id`} onChange={onChange} />

      <Modal title="Edit User" open={statusModalEditUser} onCancel={handleCancel} footer={null} width={1200}>
        <Spin spinning={loaddingModal} tip="Loading...">
          <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} onFinish={onFinishEdit} onFinishFailed={onFinishFailed}>
            <Form.Item hidden={true} label="ID" name="id">
              <Input placeholder="id" style={{ width: "90%" }} />
            </Form.Item>
            <Form.Item name={"active"} label="Active" valuePropName={"checked"}>
              <Switch ref={refTitlettree} checkedChildren={"Active"} unCheckedChildren={"No Active"} />
            </Form.Item>
            <Form.Item label="Name" name="name">
              <Input id="name" placeholder="User Name" style={{ width: "90%" }} disabled />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input id="email" placeholder="Email" style={{ width: "90%" }} disabled />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  validator: async (_, storeValue) => {
                    if (validatePhone(storeValue)) {
                      return Promise.resolve(storeValue);
                    }
                    return Promise.reject(new Error("Please Input Phone Number"));
                  },
                },
              ]}
            >
              <Input id="phone" placeholder="Phone Number" style={{ width: "90%" }} maxLength={10} />
            </Form.Item>
            <Col>
              <Form.Item
                label="Set organize"
                name="setorganize"
                rules={[
                  {
                    required: true,
                    message: "Please Select Organize",
                  },
                ]}
              >
                <Select defaultValue="Select Organize" style={{ width: "50%" }} placeholder={"Please Select"}>
                  <Option key={-1} disabled>
                    Please Select
                  </Option>
                  {organizeBank.map((x: any) => (
                    <Option key={x.key}>{x.title}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Set roles"
                name="setroles"
                rules={[
                  {
                    required: true,
                    message: "Please Select Roles",
                  },
                ]}
              >
                <Select mode="multiple" allowClear defaultValue="Select Roles" style={{ width: 200 }} placeholder={"Please Select"} showArrow>
                  <Option key={-1} disabled>
                    Please Select
                  </Option>
                  {subOrganize.map((x: any, row: any, index: number) => {
                    return (
                      <Option value={x.id} key={x.id}>
                        {x.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
                Save
              </Button>
              <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
                Cancel
              </Button>
            </Space>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};
