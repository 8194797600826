import { Card, Row, Col, message, Button, Space, Select, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { SetSessionTimeOutService } from "./service/service";
import { ProfileOutlined } from "@ant-design/icons";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import type { ColumnsType } from "antd/es/table";
import { DateTH } from "../../utils/time-local";
const appConfig = loadAppConfig();

export interface Props {
  userId: string;
  accessRole: string;
}

interface DataType {
  updatedDate: string;
  updatedBy: string;
  sesstionTime: string;
}

const { Option } = Select;

export const SetSessionTimeOut: React.FC<Props> = ({ userId, accessRole }) => {
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [dataTimeout, setDataTimeout] = useState(0);
  const [dataSource, setDataSource] = useState<DataType[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const columns: ColumnsType<DataType> = [
    {
      title: "Update Date",
      dataIndex: "updatedDate",
      key: "id",
      render: (data: string) => {
        if (data) {
          return data != "null" ? DateTH(new Date(data), "DD-MM-BBBB HH:mm:ss") : "-";
        }
      },
    },
    {
      title: "Update By",
      dataIndex: "updatedBy",
      key: "id",
      responsive: ["md"],
      render: (data: string) => {
        if (data) {
          return data != "null" ? data : "-";
        }
      },
    },
    {
      title: "Timeout",
      dataIndex: "sesstionTime",
      key: "id",
      responsive: ["lg"],
      render: (value) => {
        return `${value} Minutes`;
      },
    },
  ];

  useEffect(() => {
    if (loading) featchData();
  }, [loading]);

  const featchData = async () => {
    const resultTimeout = await SetSessionTimeOutService(apiBaseUrlDebt).getTimeout();
    setDataSource(resultTimeout);
    setLoading(false);
  };

  const getParentTitle = (key: any, tree: any): any => {
    let parentTitle;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentTitle = node.title;
        } else if (getParentTitle(key, node.children)) {
          parentTitle = getParentTitle(key, node.children);
        }
      }
    }
    return parentTitle;
  };

  const handleLoadingTable = (load: boolean) => {
    setLoading(load);
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (value: number) => {
    setDataTimeout(value);
  };

  const updateTimeout = async () => {
    if (dataTimeout == 0 || dataTimeout == undefined) {
      message.error("กรุณาเลือก Timeout");
    } else {
      handleLoadingTable(true);
      const res: any = await SetSessionTimeOutService(apiBaseUrlDebt).updateTimeout(dataTimeout);
      if (res) {
        handleLoadingTable(false);
      }
      message.success("Update Timeout เรียบร้อย สามารถใช้งานได้ในวันถัดไป");
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <ProfileOutlined style={{ width: 36, height: 36, color: "#0056B4" }} />
            Set Session Timeout
          </b>
        </Card>
      </Row>

      {loading ? (
        <Spin />
      ) : (
        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem", width: "100%" }}
        >
          <Col span={24}>
            <Card style={{ width: "100%" }}>
              {checkAccessRole("ddebtSettingSetSessionTimeoutAction") ? (
                <Row style={{ marginBottom: "20px" }}>
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Space>
                      <b>Set Session Timeout</b>
                      <Select allowClear placeholder="Select Time" style={{ width: 120, textAlign: "left" }} onChange={handleChange}>
                        <Option value="0" disabled>
                          Please Select
                        </Option>
                        <Option value="2">2 Minutes</Option>
                        <Option value="5">5 Minutes</Option>
                        <Option value="10">10 Minutes</Option>
                        <Option value="15">15 Minutes</Option>
                      </Select>
                      <Button type="primary" onClick={updateTimeout}>
                        Save
                      </Button>
                    </Space>
                  </Col>
                </Row>
              ) : (
                <></>
              )}

              <Table columns={columns} dataSource={dataSource} loading={loading} rowKey={(row) => row.updatedBy} />
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};
