export enum UserRole {
  ddebtModule = "ddebtModule",
  reportModule = "reportModule",
  commissionModule = "commissionModule",
  notificationnModule = "notificationnModule",

  //Update
  ddebtDataManagement = "ddebtDataManagement",
  ddebtDataManagementAddConditionlist = "ddebtDataManagementAddConditionlist",
  ddebtDataManagementExecute = "ddebtDataManagementExecute",
  ddebtDataManagementSimulate = "ddebtDataManagementSimulate",
  ddebtDataManagementAssign = "ddebtDataManagementAssign",
  ddebtDataManagementCancelAssign = "ddebtDataManagementCancelAssign",
  ddebtDataManagementDisableRulebase = "ddebtDataManagementDisableRuleBase",

  ddebtLeadManagement = "ddebtLeadManagement",
  ddebtLeadManagementAssignto = "ddebtLeadManagementAssignto",

  ddebtTeamManagement = "ddebtTeamManagement",
  ddebtTeamManagementAddteam = "ddebtTeamManagementAddteam",
  ddebtTeamManagementEditteam = "ddebtTeamManagementEditteam",

  ddebtDebtManagement = "ddebtDebtManagement",
  ddebtDebtManagementDebt = "ddebtDebtManagementDebt",
  ddebtDebtManagementDebtExport = "ddebtDebtManagementDebtExport",
  ddebtDebtManagementImport = "ddebtDebtManagementImport",
  ddebtDebtManagementTeam = "ddebtDebtManagementTeam",
  ddebtDebtManagementData = "ddebtDebtManagementData",
  ddebtDebtManagementLead = "ddebtDebtManagementLead",
  ddebtDebtManagementDebtAction = "ddebtDebtManagementDebtAction",
  // New update 09-09-65
  ddebtDebtManagementOafield = "ddebtDebtManagementOafield",
  ddebtDebtManagementCollectorfield = "ddebtDebtManagementCollectorfield",
  ddebtDebtManagementListfield = "ddebtDebtManagementListfield",
  ddebtDebtManagementTeamfield = "ddebtDebtManagementTeamfield",
  ddebtDebtManagementSupervisorfield = "ddebtDebtManagementSupervisorfield",

  ddebtImportTargetInformation = "ddebtImportTargetInformation",

  ddebtDashboard = "ddebtDashboard",
  ddebtDashboardBank = "ddebtDashboardBank",
  ddebtDashboardOaManager = "ddebtDashboardOaManager",
  ddebtDashboardOaSupervisor = "ddebtDashboardOaSupervisor",
  ddebtDashboardOaUser = "ddebtDashboardOaUser",

  ddebtSetting = "ddebtSetting",
  ddebtSettingOaOrganize = "ddebtSettingOaOrganize",
  ddebtSettingOaUser = "ddebtSettingOaUser",
  ddebtSettingOaProfile = "ddebtSettingOaProfile",
  ddebtSettingBankUser = "ddebtSettingBankUser",
  ddebtSettingBankOrganize = "ddebtSettingBankOrganize",
  ddebtSettingNotification = "ddebtSettingNotification",
  ddebtSettingBatchProcessJob = "ddebtSettingBatchProcessJob",
  ddebtSettingBatchProcessFile = "ddebtSettingBatchProcessFile",
  ddebtSettingBatchProcessDaily = "ddebtSettingBatchProcessDaily",
  ddebtSettingBatchProcessLogs = "ddebtSettingBatchProcessLogs",
  // new
  ddebtSettingBroadcast = "ddebtSettingBroadcast",
  ddebtSettingEmailtemplate = "ddebtSettingEmailtemplate",
  ddebtSettingSetSessionTimeout = "ddebtSettingSetSessionTimeout",
  ddebtSettingTime = "ddebtSettingTime",

  ddebtNotificationDashboard = "ddebtNotificationDashboard",
  ddebtNotificationDashboardBank = "ddebtNotificationDashboardBank",
  ddebtNotificationDashboardOaManager = "ddebtNotificationDashboardOaManager",
  ddebtNotificationDashboardOaSupervisor = "ddebtNotificationDashboardOaSupervisor",
  ddebtNotificationDashboardOaUser = "ddebtNotificationDashboardOaUser",
  ddebtNotificationDashboardList = "ddebtNotificationDashboardList",
  //Update
  // New
  ddebtSettingNotificationSetting = "ddebtSettingNotificationSetting",
  ddebtSettingBatchProcessFileAction = "ddebtSettingBatchProcessFileAction",
  ddebtSettingBatchProcessJobAction = "ddebtSettingBatchProcessJobAction",
  ddebtSettingBatchProcessDailyAction = "ddebtSettingBatchProcessDailyAction",
  ddebtSettingSetSessionTimeoutAction = "ddebtSettingSetSessionTimeoutAction",
  ddebtSettingTimeAction = "ddebtSettingTimeAction",
  ddebtSettingNotificationSettingAction = "ddebtSettingNotificationSettingAction",
  ddebtSettingPolicy = "ddebtSettingPolicy",
  ddebtSettingSyncUserBank = "ddebtSettingSyncUserBank",
  ddebtSettingSyncUserOa = "ddebtSettingSyncUserOa",
  ddebtSettingAddUserOa = "ddebtSettingAddUserOa",
  clicktocall = "clicktocall",
  checkringphone = "checkringphone",
  ddebtSettingSyncOaProfile = "ddebtSettingSyncOaProfile",
  shutDownPage = "shutdownpage",

  //Before
  // authSuperAdmin = "super-admin",
  // authBankOaAdmin = "bank_oa_admin",
  // authBankManager = "bank_manager",
  // authBankSupervisor = "bank_supervisor",
  // authBankUser = "bank_user",
  // authBankAudit = "bank_audit",
  // authBankReporter = "bank_reporter",
  // authOaManager = "oa_manager",
  // authOaSupervisor = "oa_supervisor",
  // authOaCollector = "oa_collector",
  // organizationAdmin = "Organization Admin",
  //Befor
}
