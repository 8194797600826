import { Card, Row, Col, Input, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FilterTeamManagement } from "./components/FilterTeamManagement";
import { TableTeamManagement } from "./components/TableTeamManagement";
import { TeamOutlined } from "@ant-design/icons";
import { TeamService } from "./services/team-debt-service";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import { IUser } from "../oaProfile/common";
const appConfig = loadAppConfig();
export interface Props {
  userId: string;
  accessRole: any;
}

export interface TTeamlist {
  id: string;
  name: string;
  description: string;
  user: string;
  detail: TTeamListDetail[];
  assignMode: string;
  assignBy: string;
  cifGrouping: boolean;
  createDate: string;
  updateDate: string;
  updateBy: string;
  createBy: string;
}

export interface TTeamListDetail {
  share: number;
  UserId: number;
  userBank: number;
}

export interface TTListUserOa {
  id: number;
  name: string;
  level: string;
}

export const TeamDebtManagement: React.FC<Props> = ({ userId, accessRole }) => {
  const { id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [fillterData, setFillterData] = useState({ type: "", text: "" });
  const [reloadTable, setReloadTable] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [dataTeamList, setDataTeamList] = useState<TTeamlist[]>([]);
  const [countTeam, setCountTeam] = useState<number>(0);
  const [userProfile, setUserProfile] = useState<IUser>({
    id: 0,
    type: "unknown",
    username: "unknown",
    name: "unknown",
    oaCode: "unknown",
    capabilities: {},
    level: "unknown",
    team: "unknown",
    token: "unknown",
    active: false,
    parentId: "unknown",
    parentNode: "unknown",
    phone: "unknown",
    email: "unknown",
    oaOrganize: "unknown",
    subOrganizationProfile: [],
    extension: "unknown",
  });
  const [userList, setUserlist] = useState<TTListUserOa[]>([]);

  useEffect(() => {
    if (loading) featchData();
  }, [loading]);

  useEffect(() => {}, [dataTeamList]);

  const featchData = async () => {
    const resTeam = await TeamService(apiBaseUrl).getTeamList();
    const userDetail = await TeamService(apiBaseUrlDebt).getDetailUser();
    const resultFindOauser = await TeamService(apiBaseUrlDebt).findoauser();
    const dataUserlist = resultFindOauser?.map((x: { id: number; name: string; level: string }) => {
      const mapData = {
        id: Number(x.id),
        name: x.name,
        level: x.level,
      };
      return mapData;
    });
    const filterTeamList = resTeam.result.filter((item: TTeamlist) => item.user === userDetail.result.oaCode);
    setDataTeamList(filterTeamList.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate)));
    setCountTeam(filterTeamList.length);
    setUserProfile(userDetail.result);
    setUserlist(dataUserlist);
    setLoading(false);
    setLoadingTable(false);
  };

  const chkReload = (text: string) => {
    if (text === "1") {
      setReloadTable(reloadTable + 1);
    }
  };

  const filter = (type: string, text: string) => {
    if (type === "none" || type === undefined) {
      message.error("กรุณาเลือก Filter ก่อนค้นหา", 5);
    } else {
      if (text === "") return;
      setLoadingTable(true);
      if (type === "TeamName") {
        setDataTeamList(dataTeamList.filter((item: TTeamlist) => item.name.match(text)));
        setLoadingTable(false);
      } else if (type === "TeamDescription") {
        setDataTeamList(dataTeamList.filter((item: TTeamlist) => item.description.match(text)));
        setLoadingTable(false);
      } else if (type === "UpdateBy") {
        setDataTeamList(dataTeamList.filter((item: TTeamlist) => item.updateBy.match(text)));
        setLoadingTable(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            {" "}
            <TeamOutlined style={{ width: 36, height: 36, color: "#0056B4" }} /> TEAM DEBT MANAGEMENT
          </b>
        </Card>
      </Row>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            {loading ? (
              <Spin />
            ) : (
              <>
                <FilterTeamManagement
                  userProfile={userProfile}
                  userList={userList}
                  userId={userId}
                  filter={filter}
                  chkReload={chkReload}
                  accessRole={accessRole}
                  setLoading={setLoading}
                />
                <TableTeamManagement
                  dataTeamList={dataTeamList}
                  countTeam={countTeam}
                  userProfile={userProfile}
                  userId={userId}
                  fillterData={fillterData}
                  reloadTable={reloadTable}
                  accessRole={accessRole}
                  setLoading={setLoading}
                  userList={userList}
                  loadingTable={loadingTable}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
