import { Card, Col, Row, Typography, Space, Tag } from "antd";
import { ExclamationCircleFilled, WarningFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DashboardService } from "../services/dashboard-service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { DateTH } from "../../../utils/time-local";
interface Props {
  baseUrl: string;
}

export const ActionBroadcast: React.FC<Props> = ({ baseUrl }): React.ReactElement => {
  const appConfig = loadAppConfig();
  const { id24Axios } = useId24();
  const userProfile = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlBroadcast = id24Axios(appConfig.apiBaseUrlBroadcast);
  const [getDataAll, setDataAll] = useState<any>([]);
  const [getCount, setCount] = useState<any>([]);

  useEffect(() => {
    featchData();
  }, []);
  const featchData = async () => {
    const profile = await userProfile.get("/debtor/userDetails");
    let Token = profile?.data?.result?.token;
    let datanoti: any = [];
    datanoti = {
      uid: Token,
      ref: "broadcast",
    };
    let Date: any = dayjs().format("YYYY-MM-DD");
    const dataResult: any = await DashboardService(apiBaseUrlBroadcast).getBroadcast(datanoti);
    const datafilter: any = dataResult?.data?.filter((event: any) => Date <= event.expireDate);
    setCount(datafilter?.length);
    setDataAll(datafilter);
  };

  return (
    <>
      <Card
        style={{
          width: "auto",
          overflow: "auto",
          height: "100%",
          minHeight: "400px",
        }}
        title={
          <>
            <Space>
              <ExclamationCircleFilled style={{ color: "orange" }} />
              <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>Notice</Typography>
              <Tag style={{ fontSize: "10px", color: "grey" }}>{getCount ? getCount : 0}</Tag>
            </Space>
          </>
        }
      >
        {getDataAll?.map((e: any) => {
          if (e.priority === "general") {
            return (
              <>
                <Card
                  key={e._id}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    boxShadow: "rgb(45, 183, 245) 0px 3px 8px",
                  }}
                >
                  <Row gutter={16}>
                    <Col span={4}>
                      <ExclamationCircleFilled
                        style={{
                          color: "rgb(45, 183, 245)",
                          fontWeight: "bold",
                          fontSize: "24px",
                          marginTop: "3px",
                          borderRadius: "16px",
                          borderWidth: "30px",
                          boxShadow: "rgb(45, 183, 245) 0px 0px 0px 2px, rgb(45, 183, 245) 0px 4px 6px -1px, rgb(45, 183, 245) 0px 1px 0px inset",
                        }}
                      />
                    </Col>
                    <Col span={20}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Typography
                            style={{
                              color: "rgb(45, 183, 245)",
                              fontWeight: "bold",
                              fontSize: "18px",
                              marginBottom: "3px",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            {e.subject}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Typography
                            // key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "14px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {e.body}
                          </Typography>
                        </Col>
                        <Col style={{ marginTop: "10px" }} span={24}>
                          <Typography
                            // key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "12px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {`Create Date: ${DateTH(e.createdAt, "DD/MM/BBBB")}`}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Typography
                            // key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "12px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {`Expire Date: ${DateTH(e.expireDate, "DD/MM/BBBB")}`}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          } else if (e.priority === "high") {
            return (
              <>
                <Card
                  key={e._id}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    boxShadow: "#FF1493 0px 3px 8px",
                  }}
                >
                  <Row gutter={16}>
                    <Col span={4}>
                      <ExclamationCircleFilled
                        style={{
                          color: "#FF1493",
                          fontWeight: "bold",
                          fontSize: "24px",
                          marginTop: "3px",
                          borderRadius: "16px",
                          boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                        }}
                      />
                    </Col>
                    <Col span={20}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Typography
                            // key={e._id}
                            style={{
                              color: "#FF1493",
                              fontWeight: "bold",
                              fontSize: "18px",
                              marginBottom: "3px",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            {e.subject}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Typography
                            // key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "14px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {e.body}
                          </Typography>
                        </Col>
                        <Col style={{ marginTop: "10px" }} span={24}>
                          <Typography
                            // key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "12px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {`Create Date: ${DateTH(e.createdAt, "DD/MM/BBBB")}`}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Typography
                            // key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "12px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {`Expire Date: ${DateTH(e.expireDate, "DD/MM/BBBB")}`}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          } else {
            return (
              <>
                <Card
                  key={e.id}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    boxShadow: "orange 0px 3px 8px",
                  }}
                >
                  <Row gutter={16}>
                    <Col span={4}>
                      <WarningFilled
                        style={{
                          color: "orange",
                          fontWeight: "bold",
                          fontSize: "24px",
                          marginTop: "3px",
                        }}
                      />
                    </Col>
                    <Col span={20}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Typography
                            key={e._id}
                            style={{
                              color: "orange",
                              fontWeight: "bold",
                              fontSize: "18px",
                              marginBottom: "3px",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            {e.subject}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Typography
                            key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "14px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {e.body}
                          </Typography>
                        </Col>
                        <Col style={{ marginTop: "10px" }} span={24}>
                          <Typography
                            key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "12px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {`Create Date: ${DateTH(e.createdAt, "DD/MM/BBBB")}`}
                          </Typography>
                        </Col>{" "}
                        <Col span={24}>
                          <Typography
                            key={e._id}
                            style={{
                              color: "grey",
                              fontSize: "12px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {`Expire Date: ${DateTH(e.expireDate, "DD/MM/BBBB")}`}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          }
        })}
      </Card>
    </>
  );
};
