import React from "react";
import { Button, Result } from "antd";
import { useId24 } from "../drivers/id24/Id24-provider";

export const UnknownPage: React.FC<{}> = ({}): React.ReactElement => {
  const { login, logout } = useId24();

  const onClick = async () => {
    const dIf = new Date();
    dIf.setTime(dIf.getTime() + -1 * 24 * 60 * 60 * 1000);
    document.cookie = "id24Token=0;expires=" + dIf.toUTCString() + ";path=/";
    document.cookie = "refresh-token=0;expires=" + dIf.toUTCString() + ";path=/";
    document.cookie = "autoTimeOut=0;expires=" + dIf.toUTCString() + ";path=/";
    let n = sessionStorage.length;
    while (n--) {
      let key: any = sessionStorage.key(n);
      if (/foo/.test(key)) {
        sessionStorage.removeItem(key);
      }
    }
    await localStorage.clear();
    await deleteAllCookies();
    logout().then(() => {
      login(window.location.origin + "/");
    });
  };

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  return (
    <div>
      <Result
        status="403"
        title="ไม่พบข้อมูลผู้ใช้งานในระบบ"
        subTitle="กรุณาติดต่อกับทางแอดมินของระบบ เพื่อทำการ sync ข้อมูลของท่านกับระบบ"
        extra={
          <Button type="primary" onClick={onClick}>
            {`Back To Login`}
          </Button>
        }
      />
    </div>
  );
};
