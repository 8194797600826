export interface IDashboardService {
  getBankManager: () => Promise<any>;
  getOaManager: () => Promise<any>;
  getOaSupervisor: (dataTeam: any, token: any) => Promise<any>;
  getOaCollectorTodoList: (dataTeam: any, token: any) => Promise<any>;
  getOaCollectorByAction: (dataTeam: any, token: any) => Promise<any>;
  getOaCollectorAll: (dataTeam: any, token: any) => Promise<any>;
  getCountDebt: (filter: any) => Promise<any>;
  getDebtList: (filter: any) => Promise<any>;
}

export const DashboardService = (serviceBackendDebt: any): IDashboardService => ({
  getCountDebt: async (filter: any) => {
    const result = await serviceBackendDebt.post("/notification/debtSearchCount", filter);
    return result?.data || {};
  },
  getDebtList: async (filter) => {
    const result = await serviceBackendDebt.post("/notification/debtSearch", filter);
    return result?.data || {};
  },
  getBankManager: async () => {
    const result = await serviceBackendDebt.get("/notification/dashboardBank");
    return result?.data || {};
  },
  getOaManager: async () => {
    const result = await serviceBackendDebt.get("/notification/dashboardOa");
    return result?.data || {};
  },
  getOaSupervisor: async (data, token) => {
    const result = await serviceBackendDebt.get("/debtor/dashboardOaAuth");
    return result?.data || {};
  },
  getOaCollectorTodoList: async (data, token) => {
    const result = await serviceBackendDebt.get("/debtor/dashboardOaAgentTodoListAuth");
    return result?.data || {};
  },
  getOaCollectorByAction: async (data, token) => {
    const result = await serviceBackendDebt.post("/debtor/dashboardOaAgentByActionAuth", data);
    return result?.data || {};
  },
  getOaCollectorAll: async (data, token) => {
    const result = await serviceBackendDebt.get("/debtor/dashboardOaAgentAuth");
    return result?.data || {};
  },
});
