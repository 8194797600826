import dayjs from "dayjs";
import "dayjs/locale/th";
import thTH from "antd/lib/locale-provider/th_TH";
import "antd/es/date-picker/style";
var buddhistEra = require("dayjs/plugin/buddhistEra");
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import locale from "antd/es/date-picker/locale/th_TH";
dayjs.extend(buddhistEra);
dayjs.locale("th");

export const config = {
  ...dayjsGenerateConfig,
  setYear: (date: any, year: any) => {
    return date.year(year - 543);
  },
  getYear: (date: any) => Number(date.format("BBBB")),
};

export const datePickerTh = {
  ...locale,
  lang: {
    ...locale.lang,
    yearFormat: "BBBB",
    dateFormat: "M/D/BBBB",
    dateTimeFormat: "M/D/BBBB HH:mm:ss",
  },
  dateFormat: "BBBB-MM-DD",
  dateTimeFormat: "BBBB-MM-DD HH:mm:ss",
  weekFormat: "BBBB-wo",
  monthFormat: "BBBB-MM",
};

export const CheckspecialFont = (specialfont: string) => {
  if (specialfont) {
    if (specialfont.charAt(0) === "-" || specialfont.charAt(0) === "+" || specialfont.charAt(0) === "=") {
      return specialfont.substring(1);
    } else {
      return specialfont;
    }
  } else {
    return "";
  }
};

export const numberWithCommas = (x: any) => {
  if (x === undefined || x === "-" || x === null || x === "") {
    return "0.00";
  } else {
    const fixedNumber = Number.parseFloat(x).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const numberWithCommasNodigit = (x: any) => {
  if (x === undefined || x === "-" || x === null || x === "") {
    return "-";
  } else {
    const fixedNumber = Number.parseFloat(x);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const DateStamp = (stamp: any) => {
  const stamptime = new Intl.DateTimeFormat("en-US").format(stamp);
  if (stamptime != null && stamptime != "-" && stamptime != "") {
    return dayjs(stamptime).locale("th").locale("th").format("DD-MM-BBBB");
  } else {
    return "-";
  }
};

export const checktext = (text: any) => {
  if (text === undefined || text === "-" || text === null || text === "") {
    return "-";
  } else {
    return text;
  }
};

export const dateCheck = (date: string) => {
  if (date != null && date != "-" && date != "") {
    return dayjs(date).locale("th").locale("th").format("DD-MM-BBBB");
  } else {
    return "-";
  }
};

export const dateCheckTime = (date: string) => {
  if (date != null && date != "-" && date != "") {
    return dayjs(date).locale("th").locale("th").format("DD-MM-BBBB HH:mm:ss น.");
  } else {
    return "-";
  }
};

export const configuredLocale: any = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker?.lang,
      yearFormat: "BBBB",
    },
  },
};
