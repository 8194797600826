import React from "react";
import { Card, Row } from "antd";
import { BellFilled, DashboardOutlined } from "@ant-design/icons";
import { UserRole } from "../../common-auth/models/user-role";
import { DashboardManagerBank } from "./DashboardNotiBankmanager";
import { DashboardManagerOa } from "./DashboardNotiOamanager";
import { DashboardManager } from "./DashboardNotiManager";
import { DashboardManagerCollector } from "./DashboardNotiCollector";
import { Route } from "react-router";

interface DashboardMenu {
  label: string;
  path: string;
  target: string;
  roles: UserRole[];
  component: React.ReactElement;
}
interface Props {
  baseUrl: string;
  accessRole: any;
}

export const OverviewNotiDashboard: React.FC<Props> = ({ accessRole, baseUrl }) => {
  const hasRole = (userRoles: UserRole[]): boolean => {
    const inputRoles = new Set(userRoles);
    const union = new Set(accessRole.filter((role: any) => inputRoles.has(role)));
    return union.size > 0;
  };

  const menuItems: DashboardMenu[] = [
    {
      label: "DashboardManagerbank",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardBank],
      component: <DashboardManagerBank baseUrl={"/dashboard"} />,
    },
    {
      label: "DashboardManagerOa",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardOaManager],
      component: <DashboardManagerOa baseUrl={"/dashboard"} />,
    },
    {
      label: "DashboardManager",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardOaSupervisor],
      component: <DashboardManager baseUrl={"/dashboard"} />,
    },
    {
      label: "DashboardManager",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardOaUser],
      component: <DashboardManagerCollector baseUrl={"/dashboard"} />,
    },
  ].filter((menu) => hasRole(menu.roles));

  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#FF9500", fontSize: "24px" }}>
            <BellFilled /> {`NOTIFICATION`}
          </b>
        </Card>
      </Row>
      <br></br>
      {menuItems?.map((menuItem, index) => (
        <Route key={index} exact path={`${baseUrl}`}>
          {menuItem.component}
          <br></br>
        </Route>
      ))}
    </>
  );
};
