export interface IBroadcastServiece {
  logBroadcast: () => Promise<any>;
  getDataAll: () => Promise<any>;
  createBroadcast: (data: any) => Promise<any>;
}

export const BroadcastServiece = (UrlServiece: any): IBroadcastServiece => ({
  logBroadcast: async () => {
    const result = await UrlServiece.get("/notification/broadcast");
    return result?.data || {};
  },
  getDataAll: async () => {
    const result = await UrlServiece.get(`/profile/organize`);
    return result?.data || {};
  },
  createBroadcast: async (data: any) => {
    const result = await UrlServiece.post("/notification/send/broadcast", data);
    return result?.data || {};
  },
});
