import React, { useState, useEffect } from "react";
import { Button, Modal, Table, message, Popconfirm } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { IFilter, BatchProcessServiceJob } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { DateTH } from "../../../utils/time-local";

const appConfig = loadAppConfig();
interface IDataTable {
  key: React.Key;
  no: number;
  jobId: number;
  active: number;
  runTime: string;
  detail: string;
  jobType: string;
  jobs: string;
  lastRun: string;
  createAt: string;
}
interface Props {
  fillterData: IFilter;
  userId: string;
  reloadTable: number;
  loading: boolean;
  handleLoadingTable: (load: boolean) => void;
  accessRole: string;
}

export const TableBatchProcessJob: React.FC<Props> = ({ fillterData, userId, reloadTable, loading, handleLoadingTable, accessRole }) => {
  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  const { id24Axios } = useId24();
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);
  const [tableLoading, setTableLoading] = useState(false);
  const [fileNameFilter, setFileNameFilter] = useState<any>([]);
  const [fileRunTimeFilter, setFileRunTimeFilter] = useState<any>([]);
  const [fileJobTypeFilter, setFileJobTypeFilter] = useState<any>([]);
  const [fileCreateDateFilter, setFileCreateDateFilter] = useState<any>([]);
  const [fileLastRunFilter, setFileLastRunFilter] = useState<any>([]);
  const [fileDescription, setFileDescription] = useState<any>([]);
  const [filejobId, setFilejobId] = useState<any>([]);
  const [dataSource, setDataSource] = useState<IDataTable[]>([]);
  const [switchChecked, setSwitchChecked] = useState<boolean>();
  const [count, setCount] = useState(dataSource?.length);
  const columns: ColumnsType<IDataTable> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      fixed: "left",
      width: "5%",
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
      // fixed: "left",
      // width: "8%",
      filters: filejobId,
      onFilter: (value: any, record: any) => record.jobId?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Job Name",
      dataIndex: "jobs",
      key: "jobs",
      // width: "20%",
      filters: fileNameFilter,
      onFilter: (value: any, record) => record.jobs?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Description",
      dataIndex: "detail",
      key: "detail",
      // width: "20%",
      filters: fileDescription,
      onFilter: (value: any, record) => record.detail?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },

    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "jobType",
      // width: "10%",
      filters: fileJobTypeFilter,
      onFilter: (value: any, record) => record.jobType?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Run Time",
      dataIndex: "runTime",
      key: "runTime",
      // width: "10%",
      filters: fileRunTimeFilter,
      onFilter: (value: any, record) => record.runTime?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? `${data} น.` : "-";
      },
    },
    {
      title: "Last Run",
      dataIndex: "lastRun",
      key: "lastRun",
      // width: "15%",
      filters: fileLastRunFilter,
      onFilter: (value: any, record) => record.lastRun.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data ? DateTH(new Date(data), "DD-MM-BBBB HH:mm:ss") : "-";
      },
    },
    {
      title: "Create Date",
      dataIndex: "createAt",
      key: "createAt",
      filters: fileCreateDateFilter,
      onFilter: (value: any, record) => record.createAt.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data ? DateTH(new Date(data), "DD-MM-BBBB HH:mm:ss") : "-";
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      // width: "8%",
      fixed: "right",
      render: (data: any, row: any, index: number) => {
        if (data === 1) {
          return (
            <div style={{ textAlign: "center" }}>
              {/* <Switch
                defaultChecked={data}
                checkedChildren={"on"}
                unCheckedChildren={"off"}
                onChange={(number: boolean) => setSwitchChecked(number)}
                onClick={() => {
                  onClickChecked(data, row, index);
                }}
              /> */}
              <Button
                onChange={(number: any) => setSwitchChecked(number)}
                onClick={() => {
                  onClickChecked(data, row, index);
                }}
                style={{
                  border: "none",
                  backgroundColor: "#1890ff",
                  color: "white",
                  borderRadius: "20px",
                }}
              >
                On
              </Button>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              {/* <Switch
                defaultChecked={data}
                checkedChildren={"on"}
                unCheckedChildren={"off"}
                onChange={(number: boolean) => setSwitchChecked(number)}
                onClick={() => {
                  onClickChecked(data, row, index);
                }}
              /> */}
              <Button
                onChange={(number: any) => setSwitchChecked(number)}
                onClick={() => {
                  onClickChecked(data, row, index);
                }}
                style={{
                  border: "none",
                  backgroundColor: "#ff4d4f",
                  color: "white",
                  borderRadius: "20px",
                }}
              >
                Off
              </Button>
            </div>
          );
        }
      },
    },
    // {
    //   title: "Active",
    //   dataIndex: "active",
    //   key: "active",
    //   width: "5%",
    //   fixed: "right",
    //   render: (data: number, row: any, index: number) => {
    //     if (data === 1) {
    //       return (
    //         <div style={{ textAlign: "center" }}>
    //           <Button type="primary" ghost>
    //             On
    //           </Button>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div style={{ textAlign: "center" }}>
    //           <Button type="primary" danger ghost>
    //             Off
    //           </Button>
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      // width: "10%",
      align: "center" as const,
      fixed: "right",
      render: (_: any, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="คุณต้องการบลข้อมูลใช่ หรือ ไม่?" onConfirm={() => handleDelete(record)}>
            <Button
              style={{
                textAlign: "center",
                border: "none",
                background: "none",
              }}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];
  const columnsNoAction: ColumnsType<IDataTable> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      fixed: "left",
      width: "5%",
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
      filters: filejobId,
      onFilter: (value: any, record: any) => record.jobId?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Job Name",
      dataIndex: "jobs",
      key: "jobs",
      // width: "20%",
      filters: fileNameFilter,
      onFilter: (value: any, record) => record.jobs?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Description",
      dataIndex: "detail",
      key: "detail",
      filters: fileDescription,
      onFilter: (value: any, record) => record.detail?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },

    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "jobType",
      filters: fileJobTypeFilter,
      onFilter: (value: any, record) => record.jobType?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Run Time",
      dataIndex: "runTime",
      key: "runTime",
      filters: fileRunTimeFilter,
      onFilter: (value: any, record) => record.runTime?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? `${data} น.` : "-";
      },
    },
    {
      title: "Last Run",
      dataIndex: "lastRun",
      key: "lastRun",
      filters: fileLastRunFilter,
      onFilter: (value: any, record) => record.lastRun.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data ? DateTH(new Date(data), "DD-MM-YYYY HH:mm:ss") : "-";
      },
    },
    {
      title: "Create Date",
      dataIndex: "createAt",
      key: "createAt",
      filters: fileCreateDateFilter,
      onFilter: (value: any, record) => record.createAt.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data ? DateTH(new Date(data), "DD-MM-YYYY HH:mm:ss") : "-";
      },
    },
  ];

  const handleDelete = async (record: any) => {
    const newData = dataSource?.filter((item) => item.key !== record.key);
    const res: any = await BatchProcessServiceJob(apiBaseUrlDatalink).deleteJob(record.jobId);
    if (res) {
      message.success("ลบข้อมูลสำเร็จ");
    }
    setDataSource(newData);
  };

  const onClickChecked = async (data: any, row: any, index: any) => {
    handleLoadingTable(true);
    Modal.confirm({
      title: "ยืนยันการเปลี่ยนแปลง",
      icon: <ExclamationCircleOutlined />,
      content: "คุณต้องการเปลี่ยนแปลงการ Active ใช่ หรือ ไม่ ?",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        console.log("OK");
        handleLoadingTable(true);
        const resGetById: any = await BatchProcessServiceJob(apiBaseUrlDatalink).getJobById(row.jobId);
        if (resGetById) {
          let active = 0;
          if (resGetById.active === 1) {
            active = 0;
          } else {
            active = 1;
          }
          let data = {
            active: active,
          };
          const resUpdate: any = await BatchProcessServiceJob(apiBaseUrlDatalink).updateJobById(row.jobId, data);
          if (resUpdate) {
            handleLoadingTable(false);
          }
        }
        handleLoadingTable(false);
      },
      onCancel: async () => {
        handleLoadingTable(false);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [loading, tableLoading, fillterData]);

  async function fetchData() {
    const res1: any = await BatchProcessServiceJob(apiBaseUrlDatalink).getAllJob();
    let fileNameArray: any = [];
    let Filejobs = res1?.filter((ele: any, ind: any) => ind === res1?.findIndex((elem: any) => elem.jobs === ele.jobs));
    Filejobs?.map((data: any) => {
      fileNameArray.push({
        text: data.jobs != null ? data.jobs : "-",
        value: data.jobs,
      });
    });
    setFileNameFilter(fileNameArray);

    let fileJobIdArray: any = [];
    let FilejobId = res1?.filter((ele: any, ind: any) => ind === res1?.findIndex((elem: any) => elem.jobId === ele.jobId));
    FilejobId?.map((data: any) => {
      fileJobIdArray.push({
        text: data.jobId != null ? data.jobId : "-",
        value: data.jobId,
      });
    });
    setFilejobId(fileJobIdArray);

    let filDescriptionArray: any = [];
    let FileDesjobs = res1?.filter((ele: any, ind: any) => ind === res1?.findIndex((elem: any) => elem.detail === ele.detail));
    FileDesjobs?.map((data: any) => {
      filDescriptionArray.push({
        text: data.detail != null ? data.detail : "-",
        value: data.detail,
      });
    });
    setFileDescription(filDescriptionArray);

    let fileRunTimArray: any = [];
    let fileRuntime = res1?.filter((ele: any, ind: any) => ind === res1?.findIndex((elem: any) => elem.runTime === ele.runTime));
    fileRuntime?.map((data: any) => {
      fileRunTimArray.push({
        text: data.runTime != null ? data.runTime : "-",
        value: data.runTime,
      });
    });
    let fileJobType: any = [];
    let fileJobTypearray = res1?.filter((ele: any, ind: any) => ind === res1?.findIndex((elem: any) => elem.jobType === ele.jobType));
    fileJobTypearray?.map((data: any) => {
      fileJobType.push({
        text: data.jobType != null ? data.jobType : "-",
        value: data.jobType,
      });
    });
    let fileCreateDate: any = [];
    let fileCreateDateArray = res1?.filter((ele: any, ind: any) => ind === res1?.findIndex((elem: any) => elem.createAt === ele.createAt));
    fileCreateDateArray?.map((data: any) => {
      fileCreateDate.push({
        text: DateTH(data.createAt, "DD-MM-BBBB HH:mm:ss"),
        value: data.createAt,
      });
    });
    let fileLastRun: any = [];
    let fileLastRunArray = res1?.filter((ele: any, ind: any) => ind === res1?.findIndex((elem: any) => elem.lastRun === ele.lastRun));
    fileLastRunArray?.map((data: any) => {
      fileLastRun.push({
        text: DateTH(data.lastRun, "DD-MM-BBBB HH:mm:ss"),
        value: data.lastRun,
      });
    });

    setFileRunTimeFilter(fileRunTimArray);
    setFileJobTypeFilter(fileJobType);
    setFileCreateDateFilter(fileCreateDate);
    setFileLastRunFilter(fileLastRun);

    let convertData: any = [];
    await res1?.map((e: any, i: number) => {
      convertData.push({
        key: i,
        no: i + 1,
        jobId: String(e.jobId),
        active: Number(e.active),
        runTime: String(e.runTime),
        detail: e.detail,
        jobType: String(e.jobType),
        jobs: String(e.jobs),
        lastRun: e.lastRun,
        createAt: e.createAt,
      });
    });
    setDataSource(convertData);
    setCount(convertData.length);
  }

  return (
    <>
      <Table
        loading={loading ? loading : tableLoading}
        columns={checkAccessRole("ddebtSettingBatchProcessJobAction") ? columns : columnsNoAction}
        dataSource={dataSource}
        size={"small"}
        style={{ marginTop: 5 }}
        rowKey={(record: any) => record.id}
      />
    </>
  );
};
