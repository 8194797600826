// import { serviceTimeout } from "../../../drivers/service/axiosBackend";
export interface IOaProfile {
  updateTimeout: (data: any) => Promise<string>;
  getTimeout: () => Promise<any>;
}

export const SetSessionTimeOutService = (serviceTimeout: any): IOaProfile => ({
  updateTimeout: async (data: any) => {
    const result = await serviceTimeout.put("/debtor/settimeout", { settimeout: data });
    return result?.data || {};
  },
  getTimeout: async () => {
    const result = await serviceTimeout.get("/debtor/getsettimeout");
    return result?.data || {};
  },
});
