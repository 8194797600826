import React, { useEffect, useState } from "react";
import { MainService } from "./services/service";
import { useId24 } from "../drivers/id24/Id24-provider";
import { loadAppConfig } from "../config/app-config";
import { Avatar, Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined, PhoneFilled } from "@ant-design/icons";

export const Dphone: React.FC = (): React.ReactElement => {
  const { tokenAccess, id24Axios } = useId24();
  const appConfig = loadAppConfig();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [extension, setExtension] = useState<string>("");
  const [statusModel, setStatusModel] = useState<boolean>(false);
  const [accountNo, setAccountNo] = useState<string>("");
  const [Iconcolor, setIconcolor] = useState<{
    colorAstatus: string;
    colorPstatus: string;
    colorDialstatus: string;
  }>({
    colorAstatus: "#32CD32",
    colorPstatus: "#32CD32",
    colorDialstatus: "#32CD32",
  });
  const [Textstatus, setTextstatus] = useState<{
    textAstatus: string;
    textPstatus: string;
    textDialstatus: string;
  }>({
    textAstatus: "Idle",
    textPstatus: "Idle",
    textDialstatus: "Idle",
  });
  const [roles, setRoles] = useState<any>([]);

  const checkAccessRole = (role: string) => {
    if (role) {
      let result = roles.indexOf(role) > -1;
      if (result == true) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (checkAccessRole("checkringphone")) {
      getStatusPhone();
    }
    getDetailUser();
    setRole();
  }, [extension, statusModel, accountNo]);

  const getStatusPhone = async () => {
    const checkColorphone = async (status: string, accountNo: string) => {
      let color = "" as string;
      if (status === "Idle") {
        color = "#32CD32";
      } else if ((status === "Ringing" || status === "RingInuse" || status === "InUse" || status === "Hold") && accountNo !== "") {
        color = "#FFAE42";
      } else {
        color = "#DC143C";
      }
      return color;
    };
    const checkTextphone = async (status: string, accountNo: string) => {
      let text = "" as string;
      if (status === "Idle") {
        text = "Ready";
      } else if ((status === "Ringing" || status === "RingInuse" || status === "InUse" || status === "Hold") && accountNo !== "") {
        text = "Ringing";
      } else {
        text = "Other";
      }
      return text;
    };
    const dataStatusPhone = await MainService(apiBaseUrlDebt).getStatusPhone(extension);
    checkColorphone(dataStatusPhone?.aStatus, dataStatusPhone?.refNo);
    const Astatuscolor = await checkColorphone(dataStatusPhone?.aStatus, dataStatusPhone?.refNo);
    const Pstatuscolor = await checkColorphone(dataStatusPhone?.pStatus, dataStatusPhone?.refNo);
    const Dialstatuscolor = await checkColorphone(dataStatusPhone?.dialPhone, dataStatusPhone?.refNo);
    const AstatusText = await checkTextphone(dataStatusPhone?.aStatus, dataStatusPhone?.refNo);
    const PstatusText = await checkTextphone(dataStatusPhone?.pStatus, dataStatusPhone?.refNo);
    const DialstatusText = await checkTextphone(dataStatusPhone?.dialPhone, dataStatusPhone?.refNo);
    const dataColor: any = {
      colorAstatus: Astatuscolor,
      colorPstatus: Pstatuscolor,
      colorDialstatus: Dialstatuscolor,
    };
    const dataText: any = {
      textAstatus: AstatusText,
      textPstatus: PstatusText,
      textDialstatus: DialstatusText,
    };
    setIconcolor(dataColor);
    setTextstatus(dataText);
    if (PstatusText === "Ringing" || PstatusText === "RingInuse" || PstatusText === "InUse" || PstatusText === "Hold") {
      setAccountNo(dataStatusPhone?.refNo);
      if (!dataStatusPhone?.refNo) {
        return;
      } else {
        localStorage.setItem("accountNo", dataStatusPhone?.refNo);
        Modal.confirm({
          title: "ต้องการไปที่หน้ารายละเอียดลูกหนี้หรือไม่ ?",
          icon: <ExclamationCircleOutlined />,
          content: "กดยืนยันเพื่อปที่หน้ารายละเอียดลูก",
          okText: "ยืนยัน",
          cancelText: "ยกเลิก",
          onOk: async () => {
            window.location.href = `${window.location.origin}/debtmanagement/acc/${dataStatusPhone?.refNo}`;
          },
          onCancel: async () => {
            setStatusModel(false);
          },
        });
      }
    }
  };

  const setRole = async () => {
    const datacon = [] as string[];
    if (tokenAccess)
      tokenAccess.userAccess.map((groupId) => {
        groupId.roles.forEach(function (value, i) {
          datacon.push(value);
        });
      });
    const uniqueNames = datacon.filter((val: any, id: any, array: any) => {
      return array.indexOf(val) == id;
    });
    setRoles(uniqueNames);
  };

  const getDetailUser = async () => {
    const resultUserDetails: any = await MainService(apiBaseUrlDebt).getDetailUser();
    setExtension(resultUserDetails.result.extension);
    const intervalId = setInterval(() => {
      setExtension((prevExtension) => {
        return resultUserDetails.result.extension;
      });
    }, 5000);
    return () => clearInterval(intervalId);
  };
  return (
    <Tooltip placement="bottom" title={`${Textstatus?.textPstatus}`}>
      <Avatar
        size="large"
        style={{
          backgroundColor: `${Iconcolor?.colorPstatus}`,
        }}
        icon={<PhoneFilled />}
      />
    </Tooltip>
  );
};
