// import { serviceBackendUser } from "../../../drivers/service/axiosBackend";

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export interface IOaProfile {
  createBankOrganize: (data: any) => Promise<string>;
  getBankOrganize: () => Promise<string>;
  deleteBankOrganize: (key: string) => Promise<string>;
}

export const BankOrganizeService = (serviceBackendUser: any): IOaProfile => ({
  getBankOrganize: async () => {
    const result = await serviceBackendUser.post("/bank/organize/details", {});
    return result?.data || {};
  },
  createBankOrganize: async (data: any) => {
    const result = await serviceBackendUser.post("/bank/organize", data);
    return result?.data || {};
  },
  deleteBankOrganize: async (key: string) => {
    const result = await serviceBackendUser.post("/bank/organize/delete", { key: key });
    return result?.data || {};
  },
});
