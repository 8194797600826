import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Button, Card, Col, Divider, Input, message, Modal, Row, Space, Spin, Table, Tag, Tooltip, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { Consditions, IuserProfile } from "../../datamanagement/models/models-datamanagement";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { DatamanagementServiceOa } from "../services/datamanagementoa";
import { ExclamationCircleOutlined, PlusOutlined, RightCircleFilled } from "@ant-design/icons";
import { DatamanagementSimulateOa } from "./DataManagementSimulateOa";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { Role } from "../../datamanagement/common";
import { UserAccess } from "../../../drivers/id24/models/user-access";
import { AssignStatus } from "../../datamanagement/enums/AssignStatus.enum";
import { ExecuteStatus } from "../../datamanagement/enums/ExecuteStatus.enum";
import { SimulateStatus } from "../../datamanagement/enums/SimulateStatus.enum";
import { TPagination } from "../../datamanagement/components/DatamanagementTable";
import { DateTH } from "../../../utils/time-local";

dayjs.extend(localizedFormat);
const appConfig = loadAppConfig();

interface Props {
  baseUrl: string;
  accessRole: string;
}
export const DataManageMentMain: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  const { push } = useHistory();
  const searchInput = useRef(null);
  const { tokenAccess, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });
  const [getResualData, setResualData] = useState<Consditions[]>([]);
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [getFilterlistname, setFilterlistname] = useState<any>({});
  const [openModalSimulate, setOpenModalSimulate] = useState(false);
  const [LoadingModalsimulate, setLoadingModalsimulate] = useState<boolean>(false);
  const [getDataGetUser, setDataGetUser] = useState<any>([]);
  const [getConditions, setGetConditions] = useState<string>("");
  const [permissionId24, setPermissionId24] = useState<any>([]);
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [CleardistNew, setCleardistNew] = useState<number>(0);
  const [modalStatusApply, setModalStatusApply] = useState(false);
  const [modalStatusAssign, setModalStatusAssign] = useState(false);
  const [openModelSimulateinprogress, setOpenModelSimulateinprogress] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModelSimulateinprogress, setShowModelSimulateinprogress] = useState(false);
  const [getUpdateDateSimulate, setUpdateDateSimulate] = useState<any>();

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const columns: ColumnsType<Consditions> = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      width: 50,
      sortOrder: sortedInfo.columnKey === "age" ? sortedInfo.order : "ascend",
    },
    {
      title: "List Name",
      dataIndex: "listname",
      key: "listname",
      width: 150,
      filteredValue: getFilterlistname.listname || null,
      onFilter: (value: any, record: any) => {
        return record.listname.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 130,
      align: "center",
      render: (value: any) => {
        let statusText: string = "";
        let color: string = "";
        {
          value === "DRAFT"
            ? ((statusText = "Draft"), (color = "gold"))
            : value === "WAITING_APPROVAL"
            ? ((statusText = "Waiting Approval"), (color = "blue"))
            : value === "APPROVED"
            ? ((statusText = "Approved"), (color = "green"))
            : value === "DISABLED"
            ? ((statusText = "Disabled"), (color = "red"))
            : (statusText = "Waiting Approval");
        }

        return <Tag color={color}>{statusText}</Tag>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: {
        showTitle: false,
      },
      width: 300,
      render: (description: string) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: "Update By",
      dataIndex: "updateBy",
      key: "updateBy",
      width: 150,
      filtered: true,
    },
    {
      title: "Update Date",
      dataIndex: "createDate",
      key: "createDate",
      width: 150,
      render: (createDate: number) => {
        return <Row>{DateTH(new Date(createDate), "DD-MM-YYYY HH:mm")}</Row>;
      },
    },
    {
      title: "Action",
      dataIndex: "seq",
      width: 90,
      align: "center",
      render: (index: number, data: any) => {
        const dataBody = data;
        return (
          <>
            <Button type="link" onClick={() => push(`${baseUrl}/editcondition`, dataBody)}>
              View
            </Button>
          </>
        );
      },
    },
  ];
  const [countRuleBase, setCountRuleBase] = useState<number>(0);
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    featchData();
  }, []);

  const featchData = async () => {
    setLoading(true);
    const resualProfile = await DatamanagementServiceOa(apiBaseUrlDebt).getUserDetail();
    const resultMap = await mapRoleAccess();
    const userAssign = () => {
      let AssignUserNew;
      multipleInArray(resultMap, ["bank_manager", "bank_supervisor"])
        ? (AssignUserNew = "AssignUser")
        : (AssignUserNew = `User:${resualProfile.id}:AssignUser`);
      setAssignUserNew(AssignUserNew);
      return AssignUserNew;
    };
    const userCleardist = () => {
      let AssignUserNew;
      multipleInArray(resultMap, ["bank_manager", "bank_supervisor"]) ? (AssignUserNew = 0) : (AssignUserNew = resualProfile.id);
      setCleardistNew(AssignUserNew);
      return AssignUserNew;
    };
    userCleardist();
    const resRuleBase = await DatamanagementServiceOa(apiBaseUrl).getRuleBaseByStatus(userAssign(), "ALL", 0, 10);
    const resCountRuleBase = await DatamanagementServiceOa(apiBaseUrl).countRuleBase(userAssign(), "ALL");
    let index = 0;
    const mapRuleBase: Consditions[] = resRuleBase.map((x: Consditions) => {
      index++;
      return {
        key: index,
        seq: x.seq,
        listname: x.listname,
        days: x.days,
        status: x.status,
        description: x.description,
        createDate: x.createDate,
        createBy: x.createBy,
        type: x.type,
        fields: x.fields,
        assignType: x.assignType,
        distribution: x.distribution,
        expression: x.expression,
        updateBy: x.updateBy,
      };
    });
    setResualData(mapRuleBase);
    setCountRuleBase(resCountRuleBase);
    setUserprofile(resualProfile);
    setPermissionId24(resultMap);
    setLoading(false);
  };

  const multipleInArray = (arr: string[], values: Role[]) => {
    return values.some((value) => {
      return arr.includes(value);
    });
  };

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };

  // const handleChange = (pagination: any, filters: any, sorter: any) => {
  //   setSortedInfo(sorter);
  // };

  const setSearch = (e: any) => {
    const listnametext = {
      listname: e,
    };
    setFilterlistname(listnametext);
  };

  const onclickExecuteRuleBase = async () => {
    const executeStatus = await DatamanagementServiceOa(apiBaseUrlDebt).executeStatus(CleardistNew);
    if (executeStatus.status === ExecuteStatus.IN_PROGRESS) {
      setModalStatusApply(true);
      return;
    } else {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อบันทึกข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          F_ExcuteData();
        },
        onCancel: () => {},
      });
    }
  };

  const F_ExcuteData = async () => {
    message.warning("กำลังดำเนินการ Apply งาน");
    // let dataStringBasicUpdate: any = [];
    // const newcondition: any = [];
    // getResualData.map((x: Consditions, index: number) => {
    //   dataStringBasicUpdate.push({
    //     seq: index + 1,
    //     listname: x.listname,
    //     days: x.days,
    //     status: x.status,
    //     description: x.description,
    //     createDate: x.createDate,
    //     createBy: userProfile.id,
    //     type: x.type,
    //     fields: x.fields,
    //     assignType: x.assignType,
    //     distribution: x.distribution,
    //     expression: x.expression,
    //     updateBy: x.updateBy,
    //   });
    // });

    // newcondition.push({
    //   detail: dataStringBasicUpdate,
    // });

    // const resultUpdatecondition = await DatamanagementServiceOa(apiBaseUrl).updateConditionByid(AssignUserNew, newcondition);
    // if (resultUpdatecondition === 200) {
    await DatamanagementServiceOa(apiBaseUrl).setExecuteRuleBase(CleardistNew);
    // }
  };

  const DisabledApply = () => {
    return multipleInArray(permissionId24, ["bank_manager", "bank_audit"]) ? (
      <Button type="primary" onClick={onclickExecuteRuleBase} hidden>
        Apply
      </Button>
    ) : getConditions === null ? (
      <Button type="primary" onClick={onclickExecuteRuleBase} disabled>
        Apply
      </Button>
    ) : (
      <Button type="primary" onClick={onclickExecuteRuleBase}>
        Apply
      </Button>
    );
  };

  const buttonSimulateManual = async () => {
    const executeStatus = await DatamanagementServiceOa(apiBaseUrlDebt).executeStatus(userProfile.id);
    if (executeStatus.status === ExecuteStatus.IN_PROGRESS) {
      setModalStatusApply(true);
      return;
    } else {
      const resultSimulate = await DatamanagementServiceOa(apiBaseUrlDebt).simulateStatus(userProfile);
      if (resultSimulate.status === SimulateStatus.IN_PROGRESS) {
        setShowModelSimulateinprogress(true);
      } else {
        setShowModelSimulateinprogress(false);
        setOpenModalSimulate(true);
        setLoadingModalsimulate(true);
        const resultSimulateAssign = await DatamanagementServiceOa(apiBaseUrl).simulateAssign(userProfile);
        setUpdateDateSimulate(resultSimulateAssign?.updateDate);

        if (resultSimulateAssign.detail?.userData !== undefined) {
          let dataToarray = [];
          for (let x in resultSimulateAssign.detail?.userData) {
            dataToarray.push({
              userid: x,
              data: resultSimulateAssign.detail?.userData[x],
            });
          }
          setDataGetUser(dataToarray);
        }
        setLoadingModalsimulate(false);
      }
    }
  };

  const modalCancelSimulate = () => {
    setOpenModalSimulate(false);
  };

  const buttonSimulate = async () => {
    message.warning("กำลังดำเนินการ Simulate งาน");
    setOpenModelSimulateinprogress(false);
    setOpenModalSimulate(false);
    DatamanagementServiceOa(apiBaseUrl).setSimulateRuleBase(CleardistNew);
  };

  const buttonAssign = async () => {
    const assignStatus = await DatamanagementServiceOa(apiBaseUrlDebt).assignStatus(CleardistNew);
    if (assignStatus.status === AssignStatus.IN_PROGRESS) {
      message.warning("อยู่ระหว่างการดำเนินการ Assign งาน");
      setOpenModalSimulate(false);
      setModalStatusAssign(true);
      return;
    } else {
      setOpenModelSimulateinprogress(false);
      setOpenModalSimulate(false);
      message.warning("เริ่มทำการ Assign งาน");
      setLoadingModalsimulate(false);
      await DatamanagementServiceOa(apiBaseUrl).assignConditions(CleardistNew);
    }
  };

  const changePagination = async (pagination: TablePaginationConfig) => {
    setLoading(true);
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
    const resultRuleBase = await DatamanagementServiceOa(apiBaseUrl).getRuleBaseByStatus(
      AssignUserNew,
      "ALL",
      (Number(pagination.current) - 1) * Number(pagination.pageSize),
      Number(pagination.pageSize),
    );
    const countRulBase = await DatamanagementServiceOa(apiBaseUrl).countRuleBase(AssignUserNew, "ALL");
    if (resultRuleBase) {
      let index = (Number(pagination.current) - 1) * Number(pagination.pageSize);
      const mapDetailRuleBase: Consditions[] = resultRuleBase?.map((x: Consditions) => {
        index++;
        return {
          key: index,
          seq: x.seq,
          listname: x.listname,
          days: x.days,
          status: x.status,
          description: x.description,
          createDate: x.createDate,
          updateby: resultRuleBase.name,
          type: x.type,
          fields: x.fields,
          assignType: x.assignType,
          distribution: x.distribution,
          expression: x.expression,
          updateBy: x.updateBy,
          createBy: x.createBy,
          expridedType: x.expridedType,
        };
      });
      setResualData(mapDetailRuleBase);
      setCountRuleBase(countRulBase);
    }
    setLoading(false);
  };

  const dateStamp = (stamp: any) => {
    const date = new Date(stamp);
    const timestamp = date.toLocaleString("th-TH");
    return timestamp;
  };
  return (
    <>
      <Spin spinning={loading} tip={`Loading`}>
        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem" }}
        >
          <Col span={6}>
            <Card>
              <Row>
                <Col span={14}>
                  <Typography style={{ textAlign: "left", fontSize: "120%" }}>Condition List</Typography>
                </Col>
                <Col span={10} style={{ textAlign: "right", width: "80%" }}>
                  {checkAccessRole("ddebtDataManagementAddConditionlist") ? (
                    <Button
                      type="primary"
                      ghost
                      onClick={(e) => {
                        push(`${baseUrl}/addcondition/basic`);
                      }}
                    >
                      <Space>
                        <PlusOutlined />
                        {`Add`}
                      </Space>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Divider />
              <Row style={{ marginBottom: "6px" }}>
                <Col
                  span={24}
                  style={{
                    textAlign: "left",
                    color: "gray",
                    fontSize: "100%",
                  }}
                >
                  Status
                </Col>
              </Row>
              <Row style={{ textAlign: "left" }}>
                <Col span={24}>
                  <RightCircleFilled width="8" style={{ color: "#000000" }} /> <Button type="link">All</Button>
                </Col>
              </Row>
              <Divider />
            </Card>
          </Col>
          <Col span={18}>
            <Card>
              <Row>
                <Col span={11}>
                  <Typography></Typography>
                </Col>
                <Col span={3}></Col>

                <Col span={10}>
                  <Input
                    ref={searchInput}
                    placeholder={`Search List Name`}
                    onChange={(e: any) => setSearch(e.target.value ? [e.target.value] : [])}
                  />
                </Col>
              </Row>
            </Card>

            <Card>
              <Table
                size="small"
                columns={columns}
                dataSource={getResualData}
                scroll={{
                  x: 500,
                }}
                onChange={changePagination}
                rowKey={"key"}
                pagination={{ total: countRuleBase, current: pagination.current, pageSize: pagination.pageSize }}
              />
              <Row style={{ textAlign: "left" }}>
                <Col span={24} style={{ marginTop: "10px", textAlign: "right" }}>
                  {DisabledApply()}
                  <Button onClick={buttonSimulateManual}>Simulate Manual</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
      <Modal
        open={openModalSimulate}
        onCancel={modalCancelSimulate}
        width={"80%"}
        title={
          <>
            <Row style={{ textAlign: "left" }}>Simulate ({dateStamp(getUpdateDateSimulate)})</Row>
          </>
        }
        footer={
          <>
            <Button type="primary" danger onClick={buttonSimulate}>
              Simulate
            </Button>
            {"   "}
            <Button type="primary" onClick={buttonAssign}>
              Assign
            </Button>
          </>
        }
      >
        <Spin tip="Loading..." spinning={LoadingModalsimulate}>
          <DatamanagementSimulateOa dataUser={getDataGetUser} />
        </Spin>
      </Modal>
      <Modal
        title={
          <>
            <ExclamationCircleOutlined /> In progressicon
          </>
        }
        open={showModelSimulateinprogress}
        onCancel={() => {
          setShowModelSimulateinprogress(false);
        }}
        cancelText="cancel"
        footer={
          <>
            <Button
              type="primary"
              danger
              onClick={() => {
                setShowModelSimulateinprogress(false);
              }}
            >
              Close
            </Button>
          </>
        }
      >
        อยู่ในระหว่างการ Simulate
      </Modal>
      <Modal
        title={
          <>
            <ExclamationCircleOutlined /> In progress
          </>
        }
        open={modalStatusApply}
        onCancel={() => {
          setModalStatusApply(false);
        }}
        cancelText="cancel"
        footer={
          <>
            <Button
              type="primary"
              danger
              onClick={() => {
                setModalStatusApply(false);
              }}
            >
              Close
            </Button>
          </>
        }
      >
        อยู่ในระหว่างการประมวลผลข้อมูล
      </Modal>
      <Modal
        title={
          <>
            <ExclamationCircleOutlined /> In progress
          </>
        }
        open={modalStatusAssign}
        onCancel={() => {
          setModalStatusAssign(false);
        }}
        cancelText="cancel"
        footer={
          <>
            <Button
              type="primary"
              danger
              onClick={() => {
                setModalStatusAssign(false);
              }}
            >
              Close
            </Button>
          </>
        }
      >
        อยู่ในระหว่างการ Assign งาน
      </Modal>
      <Modal
        title={
          <>
            <ExclamationCircleOutlined /> In progress
          </>
        }
        open={openModelSimulateinprogress}
        onCancel={() => {
          setOpenModelSimulateinprogress(false);
        }}
        cancelText="cancel"
        footer={
          <>
            <Button
              type="primary"
              danger
              onClick={() => {
                setOpenModelSimulateinprogress(false);
              }}
            >
              Close
            </Button>
          </>
        }
      >
        อยู่ในระหว่างการ Simulate
      </Modal>
    </>
  );
};
