import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Input, Table, Select, Col, Button, Form, Spin, message } from "antd";
import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import type { SorterResult } from "antd/lib/table/interface";
import type { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { LeadManagementAdd } from "./LeadManagementAdd";
import { LeadManagementService } from "../services/LeadManagementService";
import NumberFormat from "react-number-format";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { TPagination } from "../../leadmanagement/components/OverviewLeadManagement";
import { DateStamp, config, datePickerTh } from "../../../utils/index";

const appConfig = loadAppConfig();
const DatePicker = generatePicker(config);
const { RangePicker } = DatePicker;

interface IProject {}
interface Props {
  accessRole: any;
  dataResult: any;
}

interface TDataLead {
  id: string;
  cif: string;
  account_no: string;
  list_name_real: string;
  account_level: string;
  overdue_amt: string;
  noactiondate: number;
  oa_code: string;
  action_code_id: string;
  cust_name: string;
  cust_last_name: string;
  oa_name: string;
  name: string;
  assign_time: string;
  closepayamt?: string;
  account_status: string;
  channel: string;
  assign_time_after_assign: string;
}

export const OverviewLeadManagement: React.FC<Props> = ({ accessRole, dataResult }): React.ReactElement => {
  const { Option } = Select;
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [form] = Form.useForm();
  // const refCollector = useRef<any>(null);
  const [accountdata, setAccountData] = useState<IProject[]>([]);
  const [dataLead, setDataLead] = useState<TDataLead[]>([]);
  const [collectorSelect, setCollectorSelect] = useState<any[]>(Array);
  const [actionCodeSelect, setActionCodeSelect] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalLead, setTotalLead] = useState<number>(0);
  const [filTerCollector, setFilTerCollector] = useState("");
  const [filTerActionCode, setFilTerActionCode] = useState("");
  const [filTerCif, setFilTerCif] = useState("");
  const [filTerAccountNo, setFilTerAccountNo] = useState("");
  const [filTerCustomerName, setFilTerCustomerName] = useState("");
  const [filterstartDate, setFilterstartDate] = useState<string>("");
  const [filterendDate, setFilterendDate] = useState<string>("");
  const [loadingAssign, setLoadingAssign] = useState<boolean>(false);
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetchData({ current: 1, pageSize: 10 });
  }, []);

  const fetchData = async (pagination: TablePaginationConfig) => {
    let dataSent: any = {
      skip: (Number(pagination.current) - 1) * Number(pagination.pageSize),
      take: Number(pagination.pageSize),
    };
    if (filTerCollector) dataSent["collector"] = filTerCollector;
    if (filterstartDate) dataSent["startDate"] = filterstartDate;
    if (filterendDate) dataSent["endDate"] = filterendDate;
    if (filTerActionCode) dataSent["actionCode"] = filTerActionCode;
    if (filTerCif) dataSent["cif"] = filTerCif;
    if (filTerAccountNo) dataSent["accountNo"] = filTerAccountNo.toUpperCase();
    if (filTerCustomerName) dataSent["customerName"] = filTerCustomerName;

    const resDataLead = await LeadManagementService(apiBaseUrlDebt).getDataForSearch(dataSent);
    console.log(resDataLead);

    const resCountLead = await LeadManagementService(apiBaseUrlDebt).getLeadCount(dataSent);
    const resultActionCode = await LeadManagementService(apiBaseUrlDebt).getActionCodeSelect();
    const resultCollector = await LeadManagementService(apiBaseUrlDebt).getOACollector();

    dataSent["skip"] = 0;
    dataSent["take"] = resCountLead.result[0].cnt > 10000 ? 10000 : resCountLead.result[0].cnt;

    setActionCodeSelect(resultActionCode);
    setTotalLead(resCountLead.result[0].cnt);
    setDataLead(resDataLead);
    setAccountData(dataSent);
    setCollectorSelect(resultCollector);
    setLoading(false);
  };

  const onSearch = async () => {
    // if (filTerCollector === "" || filTerCollector === undefined) {
    //   refCollector.current.focus();
    //   message.warning("กรุณาเลือก Collector");
    //   return false;
    // }
    changePage({ current: 1, pageSize: 10 });
  };

  const onclickCollectorAssignData = async (condition: any) => {
    setLoadingAssign(true);
    const resultAssign = await LeadManagementService(apiBaseUrlDebt).assignOa(condition);
    if (resultAssign.count > 0) {
      message.success("Success");
    }
    changePage({ current: 1, pageSize: 10 });
    setLoadingAssign(false);
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const changePage = (pagination: TablePaginationConfig) => {
    setLoading(true);
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
    fetchData({ current: current, pageSize: pageSize });
  };

  const handleCollectorSelect = (value: any) => {
    if (value === undefined) {
      value = "";
    }
    setFilTerCollector(value);
  };
  const handleActionCodeSelect = (value: any) => {
    setFilTerActionCode(value);
  };
  const handlesetFilTerCif = (value: any) => {
    setFilTerCif(value);
  };
  const handlesetAccountNo = (value: any) => {
    setFilTerAccountNo(value);
  };
  const handlesetCustomerName = (value: any) => {
    setFilTerCustomerName(value);
  };

  const columns: ColumnsType<TDataLead> = [
    {
      title: "CIF",
      dataIndex: "cif",
      width: "10%",
    },
    {
      title: "วันที่ได้รับงาน",
      dataIndex: "assign_time_after_assign",
      // width: "5%",
      render: (text: string, record: any, index: number) => {
        if (text) {
          return DateStamp(text);
        }
      },
    },
    {
      title: "Account No.",
      dataIndex: "account_no",
      // width: "5%",
    },
    {
      title: "Account Level",
      dataIndex: "account_level",
      // width: "5%",
    },
    {
      title: "ชื่อลูกหนี้",
      dataIndex: "name",
      // width: "15%",
      render: (value: string, record: TDataLead) => record.cust_name + " " + record.cust_last_name,
    },
    {
      title: "Action Code",
      dataIndex: "action_code_id",
      // width: "10%",
      render: (text: string, record: TDataLead, index: number) => (text ? text : "-"),
    },
    {
      title: "OS",
      dataIndex: "overdue_amt",
      align: "right" as const,
      // width: "10%",
      render: (text: string, record: TDataLead, index: number) => (
        <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
      ),
    },
    {
      title: "Collector",
      dataIndex: "name",
      // width: "8%",
    },
  ];

  return (
    <Spin tip="กรุณารอสักครู่..." spinning={loading}>
      <Spin tip="กำลังดำเนินการ Assign..." spinning={loadingAssign}>
        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
        >
          <Row style={{ width: "100%", marginTop: "2rem" }}>
            <Col span={checkAccessRole("ddebtLeadManagementAssignto") ? 18 : 24} style={{ paddingRight: "10px" }}>
              <Card style={{ width: "100%", textAlign: "left" }} title={"กำหนดเงื่อนไขในการค้นหาข้อมูล"}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Form form={form} layout="vertical" onFinish={onSearch}>
                    <Row>
                      <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                        <Form.Item label="วันที่ได้รับงาน" name="listassign">
                          <RangePicker
                            locale={datePickerTh}
                            format={"DD-MM-BBBB"}
                            style={{ width: "100%" }}
                            onChange={(e: any) => {
                              if (e === null) {
                                setFilterstartDate("");
                                setFilterendDate("");
                              } else {
                                setFilterstartDate(dayjs(e[0]).startOf("date").format("YYYY-MM-DD"));
                                setFilterendDate(dayjs(e[1]).endOf("date").format("YYYY-MM-DD"));
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <Form.Item label="Collector" name="collector">
                          <Select
                            placeholder="Please Select"
                            onChange={handleCollectorSelect}
                            allowClear
                            showSearch
                            // ref={refCollector}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                          >
                            {collectorSelect?.map((collector: any, index: number) => {
                              return (
                                <Option value={collector.id} key={collector.id}>
                                  {collector.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <Form.Item label="Action Code" name="actioncode">
                          <Select
                            placeholder="Please Select"
                            onChange={handleActionCodeSelect}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                          >
                            {actionCodeSelect.map((action: any) => (
                              <Option value={action.actionCode} key={action.actionCode}>
                                {action.actionName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <Form.Item label="CIF" name="cif">
                          <Input placeholder="Text" onChange={(e: any) => handlesetFilTerCif(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <Form.Item label="Account No." name="accountno">
                          <Input placeholder="Text" onChange={(e: any) => handlesetAccountNo(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <Form.Item label="ชื่อลูกหนี้" name="customername">
                          <Input placeholder="Text" onChange={(e: any) => handlesetCustomerName(e.target.value)} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            Search
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card>
              <Card style={{ marginTop: "15px", width: "100%" }}>
                <Table
                  size="small"
                  columns={columns as any}
                  dataSource={dataLead}
                  rowKey={"id"}
                  scroll={{ x: "calc(600px + 50%)" }}
                  pagination={{ total: totalLead, current: pagination.current, pageSize: pagination.pageSize }}
                  onChange={changePage}
                />
              </Card>
            </Col>
            {checkAccessRole("ddebtLeadManagementAssignto") ? (
              <Col span={6}>
                <LeadManagementAdd
                  accountData={accountdata}
                  filterCollector={filTerCollector}
                  onclickCollectorAssignData={onclickCollectorAssignData}
                  collectorSelect={collectorSelect}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Row>
      </Spin>
    </Spin>
  );
};
