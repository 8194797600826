import React, { useEffect, useState } from "react";
import { MainLayout } from "./MainLayout";
import { useId24 } from "../drivers/id24/Id24-provider";
import { loadAppConfig } from "../config/app-config";
import { UnknownPage } from "./UnknownPage";
import { Button, Result, Spin } from "antd";
import { TimeSetService } from "../screens/timesetting/services/timeset-service";
import dayjs from "dayjs";
import { ShutdownPage } from "./ShutdownPage";
import { UserRole } from "../common-auth";

export interface TUserProfile {
  id: number;
  type: string;
  username: string;
  name: string;
  oaCode: string;
  capabilities: {
    value: number;
  };
  level: string;
  team: string;
  token: string;
  active: true;
  parentId: null;
  parentNode: string;
  phone: string;
  email: string;
  oaOrganize: string;
  subOrganizationProfile: string;
  extension: string;
  updateDate: string;
  updateBy: string;
}

interface TTime {
  id: string;
  type: string;
  startTime: string;
  endTime: string;
  updateDate: string;
  updateBy: string;
}

export const AuthorizationController: React.FC = (): React.ReactElement => {
  const { id24Axios, tokenAccess } = useId24();
  const appConfig = loadAppConfig();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const [dataUser, setdataUser] = useState<TUserProfile>();
  const [loading, setLoading] = useState<boolean>(true);
  const checkroleaccess = tokenAccess?.userAccess?.length;
  const { authenticated, login } = useId24();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [roles, setRoles] = useState<any>([]);

  useEffect(() => {
    featchData();
  }, [authenticated]);

  const featchData = async () => {
    const challenge = "challenge";
    const codeVerifier = localStorage.getItem(challenge);
    if (!authenticated || !codeVerifier) {
      return login(window.location.href);
    } else {
      const expride = new Date().getTime();
      localStorage.setItem("exprideTime", expride.toString());
      // const resUserDetail: any = await MainService(apiBaseUrlDebt).getDetailUser();
      const resUserDetail = await apiBaseUrlDebt.get("/debtor/userDetails");

      const resTime = await TimeSetService(apiBaseUrlUser).getTimeSetting();
      const datacon = [] as string[];
      if (tokenAccess)
        tokenAccess.userAccess.map((groupId) => {
          groupId.roles.forEach(function (value, i) {
            datacon.push(value);
          });
        });
      const uniqueNames = datacon.filter((val: any, id: any, array: any) => {
        return array.indexOf(val) == id;
      });
      setRoles(uniqueNames);
      setdataUser(resUserDetail?.data?.result);
      setStartTime(resTime[0]?.startTime);
      setEndTime(resTime[0]?.endTime);
    }
    setLoading(false);
  };

  const checkAccessRole = (role: string) => {
    var result = roles.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  const isActive = () => {
    if (checkAccessRole(UserRole.shutDownPage)) {
      return false;
    }
    return (
      dayjs(new Date()).format("HH:mm:00") < dayjs(startTime).format("HH:mm:00") ||
      dayjs(new Date()).format("HH:mm:00") > dayjs(endTime).format("HH:mm:00")
    );
  };
  document.addEventListener("mousemove", isActive);

  return loading ? (
    <Spin />
  ) : !dataUser || checkroleaccess === 0 || (!dataUser.oaOrganize && !dataUser.subOrganizationProfile) ? (
    <UnknownPage />
  ) : isActive() ? (
    <ShutdownPage />
  ) : (
    <MainLayout dataUser={dataUser} />
  );
};
