// import { serviceBackendUser } from "../../../drivers/service/axiosBackend";

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export interface IOaProfile {
  createOaOrganize: (data: any) => Promise<string>;
  getOaOrganize: () => Promise<string>;
  deleteOaOrganize: (key: string, oaId: string) => Promise<string>;
}

export const OaOrganizeService = (serviceBackendUser: any): IOaProfile => ({
  getOaOrganize: async () => {
    const result = await serviceBackendUser.post("/oa/organize/details", {});
    return result?.data || {};
  },
  createOaOrganize: async (data: any) => {
    const result = await serviceBackendUser.post("/oa/organize", data);
    return result?.data || {};
  },
  deleteOaOrganize: async (key: string, oaId: string) => {
    const result = await serviceBackendUser.post("/oa/organize/delete", { key: key, oaId: oaId });
    return result?.data || {};
  },
});
