import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Space, Col, Typography, Modal, Button, Select, Input, AutoComplete, Form, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import { OaUserService } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { IUser } from "../../oaProfile/common";
const appConfig = loadAppConfig();

const { Option } = Select;

interface Props {
  roles: string[];
  proFile: IUser;
  onSearch: (type: string, text: string) => void;
  oaOrganize: any;
  subOrganize: any;
  fetchData: () => void;
  syncData: () => void;
}

export const FilterOaUser: React.FC<Props> = ({ roles, proFile, onSearch, oaOrganize, subOrganize, fetchData, syncData }) => {
  const { id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const [form] = Form.useForm();
  const [fillType, setFillType] = useState("");
  const [fillText, setFillText] = useState<any>("");

  const [statusModalAddUser, setStatusModalAddUser] = useState<boolean>(false);

  const validatePhone = (number: string) => {
    if (!number) {
      return true;
    }
    return /^[0-9]{1,10}$/.test(number);
  };

  const onFinish = async (values: any) => {
    const data = {
      active: true,
      id: values.id,
      name: values.name,
      email: values.email,
      phone: values.phone,
      setorganize: values.setorganize,
      setroles: values.setroles,
      username: values.name,
    };
    Modal.confirm({
      title: "ยืนยัน",
      icon: <CheckCircleOutlined />,
      content: "ยืนยันการแก้ไขข้อมูลติดต่อ",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        await OaUserService(apiBaseUrlUser)
          .createUser(data)
          .then((resultCreateUser: any) => {
            if (resultCreateUser?.status === 400 && resultCreateUser?.message === `Username or Email duplicated`) {
              message.error(`มีชื่อผู้ใช้งาน หรืออีเมลล์นี้แล้วในระบบ`);
              return;
            } else if (resultCreateUser?.status === 400) {
              message.error(`${resultCreateUser.message}`);
              return;
            } else {
              message.success(`ทำการสร้าง User สำเร็จ`);
              form.resetFields();
              setStatusModalAddUser(false);
              fetchData();
            }
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onCancel = () => {
    form.resetFields();
    setStatusModalAddUser(false);
  };
  const checkAccessRole = (role: string) => {
    var result = roles.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            {`OA USER`}
          </Typography.Title>
        </Col>
        <Col span={16}>
          <div style={{ textAlign: "right" }}>
            <Space size={"small"}>
              <Select
                allowClear
                placeholder="Filter"
                style={{ width: 120 }}
                bordered={false}
                onChange={(event: any) => {
                  setFillType(event);
                }}
              >
                <Option value="id">ID</Option>
                <Option value="name">Name</Option>
                <Option value="email">Email</Option>
                <Option value="phone">Phone Number</Option>
              </Select>
              <AutoComplete>
                <Input.Search
                  size="middle"
                  placeholder="input search text"
                  onSelect={(event: any) => {
                    setFillText(event.target.value);
                  }}
                  onSearch={() => {
                    onSearch(fillType, fillText);
                  }}
                />
              </AutoComplete>
              {checkAccessRole("ddebtSettingAddUserOa") ? (
                <Button type="primary" onClick={() => setStatusModalAddUser(true)}>
                  {`+ Add User`}
                </Button>
              ) : (
                <></>
              )}
              {checkAccessRole("ddebtSettingSyncUserOa") ? (
                <Button type="primary" onClick={syncData}>
                  {`Sync`}
                </Button>
              ) : (
                <></>
              )}
              <Modal
                title="Add OA"
                open={statusModalAddUser}
                onCancel={() => {
                  setStatusModalAddUser(false), form.resetFields();
                }}
                footer={[]}
                width={1200}
              >
                <Form
                  form={form}
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 17 }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  fields={[
                    {
                      name: ["id"],
                      value: uuidv4(),
                    },
                  ]}
                >
                  <Form.Item hidden={true} label="ID" name="id">
                    <Input placeholder="id" style={{ width: "90%" }} />
                  </Form.Item>
                  <Form.Item
                    label="Username"
                    name="name"
                    rules={[
                      { required: true, message: `Please input Username` },
                      {
                        pattern: new RegExp(/^[a-zA-Z0-9]+$/i),
                        message: "field does not accept text Thai or special fonts",
                      },
                    ]}
                  >
                    <Input id="name" placeholder="Username" style={{ width: "90%" }} />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please input Email or is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input id="email" placeholder="Email" style={{ width: "90%" }} />
                  </Form.Item>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[
                      {
                        validator: async (_, storeValue) => {
                          if (validatePhone(storeValue)) {
                            return Promise.resolve(storeValue);
                          }
                          return Promise.reject(new Error("Please Input Phone Number"));
                        },
                      },
                    ]}
                  >
                    <Input id="phone" maxLength={10} placeholder="Phone Number" style={{ width: "90%" }} />
                  </Form.Item>
                  <Col>
                    <Form.Item
                      label="Set organize"
                      name="setorganize"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Organize",
                        },
                      ]}
                    >
                      <Select placeholder={`Select Organize`} style={{ width: 200 }}>
                        {oaOrganize.map((x: any) => (
                          <Option key={x.key}>{x.title}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Set roles"
                      name="setroles"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Roles",
                        },
                      ]}
                    >
                      <Select placeholder={`Select Roles`} style={{ width: 200 }}>
                        {subOrganize.map((x: any) => {
                          return <Option key={x.id}>{x.name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
                      Save
                    </Button>
                    <Button key="cancel" onClick={onCancel} style={{ width: "80px" }}>
                      Cancel
                    </Button>
                  </Space>
                </Form>
              </Modal>
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};
